import React, {Component} from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import moment from "moment";
import "../../assets/css/booking.css"
import Message from "semantic-ui-react/dist/commonjs/collections/Message";

class BookingDetails extends Component {
    render() {
        return (
            <>
                {this.props.booking === null ?
                    <p>No booking details</p>
                    :
                    <div className={"booking-details"}>
                        {this.props.booking.id !== undefined &&
                        <div><span>BOOKING REF</span>{this.props.booking.id + "-" + this.props.booking.status}</div>
                        }
                        <div>
                            <span>ROOM</span>{this.props.booking.room.text}
                            {(parseInt(this.props.booking.surcharge) > 0) &&
                            <i className="small-grey-text">&nbsp;&nbsp;&nbsp;(room surcharge: £ {this.props.booking.surcharge})</i>
                            }
                        </div>
                        <div><span>FROM</span><Icon name="calendar alternate outline"/>
                            {moment(this.props.booking.start).format("Do MMMM YYYY @ HH:mm")}</div>
                        <div><span>TO</span><Icon name="calendar alternate outline"/>
                            {moment(this.props.booking.end).format("Do MMMM YYYY @ HH:mm")}</div>
                        <div>
                            <span>DURATION</span><Icon name="clock"/>{this.props.booking.duration} hours
                            {(parseInt(this.props.booking.duration) > 20 && this.props.booking.id === undefined) &&
                            <Message color={"brown"} size={"small"}><Icon name={"comment alternate outline"} size={"large"} color={"brown"}/>This is a long booking. If you only
                                want to book for a number of hours, please cancel out then select the WEEK or DAY view on the calendar and select the timeslot you need</Message>
                            }
                        </div>

                        <div>
                            <span>COST</span><Icon name="pound sign"/>{this.props.booking.cost}
                            {parseFloat(this.props.booking.cost) > 0 &&
                            <i className="small-grey-text">&nbsp;&nbsp;{"(" + this.props.booking.duration + " hours x £" + parseFloat(this.props.booking.event_type.hourly).toFixed(2) + " per hour)" + (this.props.booking.surcharge > 0 ? " plus £" + parseFloat(this.props.booking.surcharge).toFixed(2) + " room surcharge" : "")}</i>
                            }
                        </div>

                        {this.props.asDisplay &&
                        <>
                            <div><span>EVENT TYPE</span>{this.props.booking.event_type.text}</div>


                            {this.props.booking.status !== undefined &&
                            <div><span>STATUS</span><span style={{textTransform: "capitalize", color: "black"}}>{this.props.booking.status}</span></div>
                            }

                            {this.props.loggedInUser.permissions.filter(p => p.permission === "booking_admin").length > 0 &&
                            <>
                                <div><span>DETAILS</span>{this.props.booking.details}</div>
                                <div><span>ADDED BY</span>{this.props.booking.added_by}</div>
                            </>
                            }

                            {this.props.booking.status_by !== undefined &&
                            <p className="small-grey-text">
                                Last actioned: {moment(this.props.booking.status_dt).format("Do MMMM YYYY HH:mm")}
                                {this.props.loggedInUser.permissions.filter(p => p.permission === "booking_admin").length > 0 &&
                                <span> by {this.props.booking.updated_by}</span>
                                }
                            </p>
                            }
                        </>
                        }
                    </div>
                }
            </>
        );
    }

}

export default BookingDetails