import React from 'react';
import Header from "semantic-ui-react/dist/commonjs/elements/Header"

const HomePageHead = () => (
    <div style={{position: "absolute", top:"25px", left:"35%", padding:"10px", borderRadius: "5px"}}>
    <Header as="h2" className={"home-title"}>
        One Community
        <br/>
        One Voice
    </Header>
    </div>
)

export default HomePageHead