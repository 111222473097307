import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

class FaqTopic extends Component {
    render() {
        return (
            <>
                {this.props.isAdmin && !this.props.preview ?
                    <div className="small-grey-text" style={{marginBottom: "5px"}}>
                        Topic:&nbsp;
                        <Input
                            name="topic"
                            defaultValue={this.props.faqItem.topic}
                            style={{width: "90%"}}
                            onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                            placeholder="topic"
                        />
                    </div>
                    :
                    <>
                        {this.props.previousTopic !== this.props.faqItem.topic &&
                        <Header style={{marginBottom: "10px"}}>{this.props.faqItem.topic}</Header>
                        }
                    </>

                }
            </>
        )
    }

}

export default FaqTopic