import React from "react";
import Roads from "./road";
import california from "../../assets/img/roads/california.jpeg"

const CaliforniaWay = () => (
    <Roads
        img={california}
        roadName="California Way"
        namedAfter="USS California (BB-44)"
        text={"Califoria Way was named after a US Navy battleship lost on Dec. 7 1941 during the Japanese attack on Pearl Harbor which effectively brought the US into WWII. " +
        "105 men were lost on the USS California. This road name was a road in the former housing areas of the base."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default CaliforniaWay