import React, {Component} from 'react'
import {Card, Icon, Image, Popup} from 'semantic-ui-react'

class CommitteeCard extends Component {
    render() {
        return (
            <Card><Image src={this.props.member.avatarImage} alt={this.props.member.memberName + " avatar"}/>

                <Card.Content>
                    <Card.Header>
                        {this.props.member.memberName}
                        {this.props.member.blurb !== null &&
                        <Popup
                            trigger={
                                <Icon name="address card outline" color='purple' size='large' style={{float: "right", cursor: "pointer"}}/>
                            }
                            wide
                            on='click'
                        >
                            <Popup.Header>About me</Popup.Header>
                            <Popup.Content>{this.props.member.blurb}</Popup.Content>
                        </Popup>
                        }
                    </Card.Header>
                    <Card.Description style={{color: "brown"}}>{this.props.member.memberTitle}</Card.Description>
                    <Card.Meta><span className='date' style={{color: "green"}}>Elected: {this.props.member.elected}</span>
                        {this.props.member.founder &&
                        <div className="small-grey-text">(founder member)</div>
                        }
                    </Card.Meta>
                </Card.Content>
            </Card>
        )
    }
}

export default CommitteeCard