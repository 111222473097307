import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Voting from "../components/voting";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import bobcatV1 from "../assets/img/signs/bobcat_v1.png";
import bobcatV2 from "../assets/img/signs/bobcat_v2.png";
import bobcatV3 from "../assets/img/signs/bobcat_v3.png";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import List from "semantic-ui-react/dist/commonjs/elements/List";

const labelColour = "blue";

class BobcatSignVote extends Component {
    render() {
        return (
            <Container style={{padding: "0 10px"}}>
                <Header as="h1">
                    <Icon name="comments" color="green" size="large"/>
                    Bobcat Park Sign Options
                </Header>
                <p>As part of the Time Capsule burial ceremony there is a plan to "revamp" the existing sign that was left over from the original MOD base.</p>
                <p>Very rough mock-up of Bobcat Park sign.</p>
                <List style={{marginLeft: "20px"}}>
                    <List.Item><Label content={"A"} color={labelColour}/> As it is</List.Item>
                    <List.Item><Label content={"B"} color={labelColour}/> Recommended change. Completely new center plate, secured within frame. *</List.Item>
                    <List.Item><Label content={"C"} color={labelColour}/> Optional change, preserves original sign *</List.Item>
                    <List.Item><Label content={"D"} color={labelColour}/> None of the shown options</List.Item>
                </List>
                <p className={"small-grey-text"}>*Either option can appear on one or both sides.</p>
                <p>
                    By leaving the "B1201" portion in place, yes, the original is preserved however, the lettering &amp; seal are vinyl pieces. The buildings were renumbered
                    and purposed when US Navy took over from US Air Force, so the sign is approximately 25 years old now. It will continue to degrade & letters peel off.
                </p>
                <p>
                    Additionally, building 1201 was not a historically significant structure on the installation. If option 3 is used, the Bobcat plate could go underneath or
                    potentially, above. It would have to be riveted into place to avoid vandalism/theft.
                </p>


                {true ?
                    <>
                        <Header as="h2">
                            <Icon name="thumbs up" color="green" size="huge"/>
                            The votes are in and are being counted
                        </Header>
                        <p>The vote is now closed and the totals are being counted. The results will be published here when available.</p>
                    </>
                    :
                    <>
                        <Header as="h2">
                            <Icon name="hand point right" color="red" size="huge"/>
                            We are looking for your opinions on these options
                        </Header>
                        <Card.Group>
                            <Card style={{marginRight: "30px"}}>
                                <Image src={bobcatV1}
                                       size={"medium"}
                                       label={{content: "A", attached: 'top', color: labelColour, size: "big", horizontal: true}}
                                />
                                <Card.Content>
                                    <Voting
                                        votingType="bobcat_sign_v1"
                                        buttons={{up: "Like", down: "Don't Like"}}
                                        loggedInUser={this.props.loggedInUser}
                                        allowedToVote={true}
                                        showNumbers={false}
                                    />
                                </Card.Content>
                            </Card>

                            <Card style={{marginRight: "30px"}}>
                                <Image src={bobcatV2}
                                       size={"medium"}
                                       label={{content: "B", attached: 'top', color: labelColour, size: "big", horizontal: true}}
                                />
                                <Card.Content>
                                    <Voting
                                        votingType="bobcat_sign_v2"
                                        buttons={{up: "Like", down: "Don't Like"}}
                                        loggedInUser={this.props.loggedInUser}
                                        allowedToVote={true}
                                        showNumbers={false}
                                    />
                                </Card.Content>
                            </Card>
                            <Card style={{marginRight: "30px"}}>
                                <Image src={bobcatV3}
                                       size={"medium"}
                                       label={{content: "C", attached: 'top', color: labelColour, size: "big", horizontal: true}}
                                />
                                <Card.Content>
                                    <Voting
                                        votingType="bobcat_sign_v3"
                                        buttons={{up: "Like", down: "Don't Like"}}
                                        loggedInUser={this.props.loggedInUser}
                                        allowedToVote={true}
                                        showNumbers={false}
                                    />
                                </Card.Content>
                            </Card>
                            <Card>
                                <Image src={"https://react.semantic-ui.com/images/wireframe/image.png"}
                                       size={"medium"}
                                       label={{content: "D", attached: 'top', color: labelColour, size: "big", horizontal: true}}
                                />
                                <p style={{textAlign: "center", padding: "5px"}}>None of the above</p>
                                <Card.Content>
                                    <Voting
                                        votingType="bobcat_sign_v4"
                                        buttons={{up: "Like", down: "Don't Like"}}
                                        loggedInUser={this.props.loggedInUser}
                                        allowedToVote={true}
                                        showNumbers={false}
                                    />
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </>
                }
            </Container>
        )
    }
}

export default BobcatSignVote