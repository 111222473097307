import React, {Component} from "react";
import Wysiwyg from "../../components/wysiwyg";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Confirm from "semantic-ui-react/dist/commonjs/addons/Confirm";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";

import Voting from "../../components/voting";
import standardFormData from "../../components/standard_form_data";
import ToDoAdminBar from "./to_do_admin_bar";
import Chat from "../../components/chat";
import ToDoStateLabel from "./to_do_state_label";

const API_DELETE_TODO = "https://www.pinetreesra.co.uk/api/todo/deleteToDo"
const API_UPDATE_TODO = "https://www.pinetreesra.co.uk/api/todo/updateToDo"

class ToDoSegment extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.ownerUser = this.ownerUser.bind(this);
        this.delete = this.delete.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
    }

    state = {
        confirmOpen: false,
        successMessage: false
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.deepLinkIndex !== undefined && this.props.deepLinkIndex > 0) {
            window.history.replaceState({}, document.title, document.location.href.split("?")[0]);
            setTimeout(this.scrollTo, 400);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    scrollTo() {
        let el = document.getElementById("id" + this.props.deepLinkIndex);
        if (el !== null) {
            el.scrollIntoView(true);
        }
    }

    ownerUser() {
        return this.props.todoItem.user_id === this.props.loggedInUser.id;
    }

    delete() {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("delete_id", this.props.todoItem.id);
        fetch(API_DELETE_TODO, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            this.props.fetch();
            if (this._isMounted) {
                this.setState({confirmOpen: false});
            }
        }));
    }

    saveField = (e, inputData) => {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("field", inputData.name);
        formData.append("value", inputData.value);
        formData.append("toLookAtId", this.props.todoItem.id);
        fetch(API_UPDATE_TODO, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            this.props.todoItem[inputData.name] = inputData.value;
            this.setState({successMessage: true});
            setTimeout(function () {
                this.setState({successMessage: false});
            }.bind(this), 2000);
        }));
    }

    openConfirm = () => this.setState({confirmOpen: true})
    closeConfirm = () => this.setState({confirmOpen: false})

    cleanStateKey(itemState) {
        return itemState.replace(" ", "_")
    }

    render() {
        return (
            <div>
                {(this.props.previousRecord === null || this.props.previousRecord.sub_committee !== this.props.todoItem.sub_committee) &&
                <Header as={"h4"} style={{marginTop: "20px"}}>{this.props.todoItem.sub_committee}</Header>
                }
                <Segment
                    style={{margin: "0 0 10px 20px", border: (this.props.deepLinkIndex === this.props.todoItem.id ? "5px solid green" : ""), background: "#eeeeee"}}
                    id={"id" + this.props.todoItem.id}
                    raised={this.props.deepLinkIndex === this.props.todoItem.id}
                >

                    <Segment style={{background: "#ffffff", padding: "10px", borderRadius: "5px 5px 0 0", border: "1px solid"}} raised>
                        {this.props.isAdmin && this.props.asAdmin &&
                        <Form.Field style={{marginBottom: "5px"}}>
                            <label className="small-grey-text" style={{width: "100px", display: "inline-block"}}>Sub Committee&nbsp;&nbsp;</label>
                            <Input
                                name="sub_committee"
                                defaultValue={this.props.todoItem.sub_committee}
                                style={{width: "80%"}}
                                onChange={(e, {name, value}) => this.saveField(e, {name, value})}
                                placeholder="sub committee"
                            />
                        </Form.Field>
                        }
                        {this.props.isAdmin && this.props.asAdmin ?
                            <Form.Field style={{marginBottom: "5px"}}>
                                <label className="small-grey-text" style={{width: "100px", display: "inline-block"}}>Heading&nbsp;&nbsp;</label>
                                <Input
                                    name="todo_heading"
                                    defaultValue={this.props.todoItem.todo_heading}
                                    style={{width: "80%", fontSize: "2em"}}
                                    onChange={(e, {name, value}) => this.saveField(e, {name, value})}
                                    placeholder="topic heading"
                                />
                            </Form.Field>
                            :
                            <Header>{this.props.todoItem.todo_heading}</Header>
                        }
                        <ToDoStateLabel itemState={this.cleanStateKey(this.props.todoItem.state)}/>
                        <Wysiwyg
                            editorContent={this.props.todoItem.details}
                            field="details"
                            saveField={this.saveField}
                            readOnly={!this.props.isAdmin}
                            preview={!this.props.asAdmin}
                            style={{clear: "both"}}
                        />

                    </Segment>

                    <div style={{verticalAlign: "middle", height: "33px", marginTop: "10px"}}>
                        <Voting
                            votingType="to_look_at"
                            loggedInUser={this.props.loggedInUser}
                            votingItem={this.props.todoItem}
                            allowedToVote={!this.ownerUser()}
                        />
                        {(this.props.todoItem.votes.votesUp === 0 && this.props.todoItem.votes.votesDown === 0 && this.ownerUser()) &&
                        <div style={{display: "inline-block", float: "right", marginLeft: "20px"}}>
                            <Button size="mini" color="red" content="delete" icon={{name: "minus circle"}} onClick={this.openConfirm}/>
                            <Confirm
                                open={this.state.confirmOpen}
                                onCancel={this.closeConfirm}
                                onConfirm={this.delete}
                            />
                        </div>
                        }

                        <div className="small-grey-text" style={{float: "right", verticalAlign: "middle", lineHeight: "33px"}}>
                            <b>Added by:</b> {this.props.todoItem.fname} {this.props.todoItem.lname} on {this.props.todoItem.dt}
                        </div>
                    </div>

                    {this.props.asAdmin &&
                    <ToDoAdminBar isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} todoItem={this.props.todoItem}
                                  showHidePreview={this.showHidePreview} successMessage={this.state.successMessage}/>
                    }

                    <Divider/>
                    <Chat chatType="first_chat" chatItemId={this.props.todoItem.id} loggedInUser={this.props.loggedInUser}/>
                </Segment>

            </div>
        )
    }
}

export default ToDoSegment