import React from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import pdf from "../../assets/img/roads/Spring-2017-Q-FINAL.pdf"

const Additional = () => (
    <Container>
        <p>
            There are a number of areas mentioned in Tammy's account that have yet to be built or are not referred to by the names she mentions.
        </p>
        <p>These are:</p>
        <ul>
            <li><b>Bobcat Park:</b> A large green open space in the middle of the site, will be named Bobcat Park after the London Central High School's mascot for 56 years
                since the school's inception in 1951.
            </li>
            <li><b>Madison Taylor Lewis Hall: </b> The "Pine Trees" site is expected to have a new community building and it is suggested it will be named after another
                of the school's coaches. Madison Taylor Lewis Hall will (might) been eponymously named after the school's popular American football and athletics coach who
                taught there from 1958 to 1987. Lewis also attended UCLA where he played basketball and was a world-rated discus thrower (he narrowly missed
                qualifying for the 1948 Olympics) and went on to teach at schools in Los Angeles and San Diego before joining the dependent schools system and being
                sent to Libya in 1956 and then to Burtonwood (near Manchester) in England shortly thereafter.<br/><br/>
                Lewis was known fondly by students as "Big Coach" or "Papa Bear" thanks to his 6'4" stature. After his teaching career, Lewis and his wife, Anne,
                moved to Grants Pass, Oregon. They were both tragically killed in an automobile accident in February 1996 when their car slid off an icy highway
                and down an embankment some 25 miles from Kalmuth, Oregon.<br/><br/>
            </li>
        </ul>

        <p>The original base site has now been demolished bar the former bunker owned by Wycombe Abbey School. Tammy quotes... "In addition to homes on the main site,
            there will be light industrial units, a small elementary school, a community shop, and other community buildings.". These are currently still being built!
        </p>
        <p>There is another mention of this in the AOSHS Quarterly news letter <a href={pdf + "#page=8"} target="_blank" rel="noopener noreferrer">Spring 2017</a></p>
    </Container>
)

export default Additional