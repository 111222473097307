import React, {Component} from "react";

class FaqDate extends Component {
    render() {
        return (
            <span className="small-grey-text" style={{marginRight: "20px"}}>
                Added: {this.props.date}
            </span>
        )
    }
}

export default FaqDate