import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import FaqDate from "./faq_dt";

const activeStateOptions = [
    {key: "pending", value: "pending", text: "Pending"},
    {key: "active", value: "active", text: "Active"},
    {key: "deleted", value: "deleted", text: "Deleted"},
]

class FaqAdminBar extends Component {
    render() {
        return (
            <>
                {this.props.isAdmin &&
                <Segment style={{background: "#f0f0f0", marginTop: "10px"}}>
                    <FaqDate date={this.props.faqItem.dt}/>
                    <span className={"small-grey-text"} style={{marginRight: "20px"}}>
                            AddedBy: {this.props.faqItem.fname + " " + this.props.faqItem.lname}
                    </span>
                    <span className="small-grey-text">
                            State: &nbsp;
                        <Dropdown
                            name="active_state"
                            placeholder="select active state"
                            options={activeStateOptions}
                            onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                            defaultValue={this.props.faqItem.active_state}
                        />
                        </span>

                    <Button color={this.props.preview ? "yellow" : "green"} size="tiny" onClick={this.props.showHidePreview}
                            style={{marginLeft: "20px"}}>{this.props.preview ? "edit" : "preview"}</Button>

                    {this.props.successMessage &&
                    <Message success size="mini" compact style={{float: "right", marginTop: "-8px"}}><Icon name="checkmark" color="green" size="small"/>Saved</Message>
                    }
                </Segment>
                }
            </>
        )
    }
}

export default FaqAdminBar