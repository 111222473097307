import React from "react";

const GDPR = () => (
    <div style={{width: '350px', margin: 'auto', marginTop: "20px"}}>
        <p className="small-grey-text">
            <b>Note:</b> Data entered on this page will not be used for any purpose other than to respond to your enquiry.
        </p>
    </div>
)

export default GDPR