import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button"
import Container from "semantic-ui-react/dist/commonjs/elements/Container"
import Header from "semantic-ui-react/dist/commonjs/elements/Header"
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon"
import Message from "semantic-ui-react/dist/commonjs/collections/Message"

import standardFormData from "../components/standard_form_data";
import FaqSegment from "../layouts/faq/faq_segment";
import FaqFilter from "../layouts/faq/faq_filter";

const API_FETCH_FAQ = "https://www.pinetreesra.co.uk/api/faq/fetchFaq"
const API_ADD_FAQ = "https://www.pinetreesra.co.uk/api/faq/addFaq"


class FaqItems extends Component {
    constructor(props) {
        super(props);
        this.updateFilter = this.updateFilter.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.filtered = this.filtered.bind(this);
        this.fetchFaq = this.fetchFaq.bind(this);
        this.fetchFaq();
    }

    fetchFaq() {
        let url = API_FETCH_FAQ;
        if (this.props.isAdmin) {
            url = url + "/admin"
        }
        fetch(url, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({error: data.error, faqList: data.faqList});
        }));
    }

    addNew() {
        fetch(API_ADD_FAQ, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({faqList: []});
            this.fetchFaq();
        }));
    }

    state = {
        faqList: [],
        error: null,
        filter: "",
        search: ""
    }

    updateFilter(filterTopic) {
        this.setState({filter: filterTopic, previousTopic: ""});
    }

    updateSearch(filterSearch) {
        this.setState({search: filterSearch, previousTopic: ""});
    }

    filtered() {
        return this.state.faqList.filter(function (faqObj) {
            let found = true;

            if (this.state.search.length > 0) {
                let words = this.state.search.split(" ");
                for (let word of words) {
                    word = word.toLowerCase();
                    found = found && (
                        (faqObj !== undefined && faqObj.question !== null && faqObj.question !== undefined && faqObj.question.toLowerCase().includes(word)) ||
                        (faqObj.answer !== undefined && faqObj.answer !== null && faqObj.answer.toLowerCase().includes(word))
                    );
                }
            }

            if (this.state.filter.length > 0) {
                found = found && faqObj.topic === this.state.filter;
            }

            return found;
        }.bind(this));
    }

    render() {
        return (
            <Container style={{padding: "0 10px"}}>
                <Header as="h1">Frequently Asked Questions</Header>
                <Message icon={{name: "warning sign", size: "large", color: "green"}} color="yellow"
                         content="This page is for members only. Please do not share information found here outside of the estate."/>

                {this.state.error !== undefined && this.state.error !== null &&
                <Message error>
                    <Icon name="lock" color="red" size="large"/>
                    Sorry but you are not authorised to view this page
                </Message>
                }

                {this.state.faqList !== undefined && this.state.faqList.length > 0 &&
                <div>
                    {this.state.faqList.length > 0 &&
                    <FaqFilter faqList={this.state.faqList} filter={this.state.filter} updateFilter={this.updateFilter} updateSearch={this.updateSearch}/>
                    }

                    {this.state.faqList.length > 0 &&
                    this.filtered().map((faqObj, i) =>
                        <FaqSegment
                            key={faqObj.id}
                            faqItem={faqObj}
                            loggedInUser={this.props.loggedInUser}
                            isAdmin={this.props.isAdmin}
                            previousTopic={i > 0 ? this.filtered()[i - 1].topic : ""}
                        />
                    )}
                </div>
                }

                {this.props.isAdmin &&
                <div style={{marginTop: "20px"}}>
                    <Button primary content={"add new"} icon={{name: "plus"}} onClick={(e) => this.addNew(e)}/>
                </div>
                }

            </Container>
        )
    }

}

export default FaqItems