import React from "react";
import Roads from "./road";
import wernette from "../../assets/img/roads/wernette.png"

const WernetteWay = () => (
    <Roads
        img={wernette}
        roadName="Wernette Way"
        namedAfter="Jack and Peg Wernette (teachers)"
        text={"Husband and wife team Jack and Peg Wernette met in 1960 when LCHS was located at Bushy Park. Jack taught English and Drama from 1958 to 1993; Peg (née Hebert) " +
        "taught Mathematics and French from 1960 to 1993. Jack Wernette's drama courses and theatre productions—from \"Arsenic and Old Lace\" to \"Inherit the Wind\", " +
        "as well as a gamut of musicals and Shakespearian plays—were the stuff of legend. Several his students went on to professional acting, directing, and producing. " +
        "The Wernettes now spend most of their time in Texas, but return to England several months each year to visit London theatres as well as attend concerts and " +
        "visit art galleries."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default WernetteWay