import React, {Component} from "react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";

const activeStateOptions = [
    {key: "pending", value: "Pending", text: "Pending"},
    {key: "in_progress", value: "In Progress", text: "In Progress"},
    {key: "wont_action", value: "Wont Action", text: "Wont action"},
    {key: "complete", value: "Complete", text: "Complete"}
]

class ToDoAdminBar extends Component {
    render() {
        return (
            <div>
                {this.props.isAdmin &&

                <Segment style={{background: "#f0f0f0", marginTop: "10px"}}>
                    <span className="small-grey-text" style={{marginRight: "30px"}}>
                        <b>id: </b> &nbsp;
                        {this.props.todoItem.id}
                    </span>
                    <span className="small-grey-text">
                        <b>state:</b> &nbsp;
                        <Dropdown
                            name="state"
                            placeholder="select active state"
                            options={activeStateOptions}
                            onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                            defaultValue={this.props.todoItem.state}
                        />
                    </span>

                    {this.props.successMessage &&
                    <Message success size="mini" compact style={{float: "right", marginTop: "-8px"}}><Icon name="checkmark" color="green" size="small"/>Saved</Message>
                    }
                </Segment>
                }
            </div>
        )
    }
}

export default ToDoAdminBar