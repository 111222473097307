import React, {Component} from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import AdminActiveState from "./admin_active_state";
import standardFormData from "../../components/standard_form_data";
import AdminMemberTypeState from "./admin_member_type";

const API_UPDATE_USERS = "https://www.pinetreesra.co.uk/api/admin/updateUser"

class AdminMemberRow extends Component {
    state = {
        successMessage: ""
    }

    saveField = (e, inputData) => {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("field", inputData.name);
        formData.append("value", inputData.value);
        formData.append("rowId", this.props.userObj.id);
        fetch(API_UPDATE_USERS, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            this.props.userObj[inputData.name] = inputData.value;
            this.setState({successMessage: true});
            setTimeout(function () {
                this.setState({successMessage: false});
            }.bind(this), 2000);
        }));
    }

    render() {
        return (
            <Table.Row>
                <Table.Cell className="small-grey-text">{(this.props.i + 1)}</Table.Cell>
                <Table.Cell>{this.props.userObj.id}</Table.Cell>
                <Table.Cell>{this.props.userObj.dt}</Table.Cell>
                <Table.Cell>{this.props.userObj.fname}</Table.Cell>
                <Table.Cell>{this.props.userObj.lname}</Table.Cell>
                <Table.Cell>{this.props.userObj.email}</Table.Cell>
                <Table.Cell>{this.props.userObj.houseNumber}</Table.Cell>
                <Table.Cell>{this.props.userObj.street}</Table.Cell>
                <Table.Cell>{this.props.userObj.postcode}</Table.Cell>
                <Table.Cell>{this.props.userObj.complete}</Table.Cell>
                <Table.Cell>
                    <AdminMemberTypeState userObj={this.props.userObj} saveField={this.saveField} successMessage={this.state.successMessage}/>
                </Table.Cell>
                <Table.Cell>
                    <AdminActiveState userObj={this.props.userObj} saveField={this.saveField} successMessage={this.state.successMessage}/>
                    {this.state.successMessage &&
                    <Message success size="mini" compact style={{float: "right", marginTop: "-8px"}}><Icon name="checkmark" color="green" size="small"/>Saved</Message>
                    }
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default AdminMemberRow