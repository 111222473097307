import React, {Component} from "react";
import Login from "../../common/googlelogin";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import GoogleLoginLogo from "../../components/google_login_logo";
import FormErrors from "./form_errors";

class SocialMediaButtons extends Component {
    render() {
        return (
            <div className="social-media">
                <Login asSignup={this.props.asSignup} handleLogin={this.props.handleLogin}
                       render={renderProps => (
                           <>
                               {this.props.errors.length > 0 ?
                                   <FormErrors errors={this.props.errors}/>
                                   :
                                   <Button circular onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn">
                                       <GoogleLoginLogo/>&nbsp; {this.props.buttonText} using google
                                   </Button>
                               }
                           </>
                       )}
                />
            </div>
        );
    }
}

export default SocialMediaButtons