import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

class SignupIntro extends Component
{
    render() {
        return (
            <div className="content">
                <h3>New here?</h3>
                <p>
                    The members section is for PineTrees residents only. It contains news about the estate and other useful information. It is free to join and only
                    requires completion of a simple sign-up form
                </p>
                <Button className="btn" primary id="sign-up-btn" onClick={() => {
                    document.querySelector('.members-page').classList.add("sign-up-mode");
                }} content="Sign-up"/>
            </div>
        );
    }
}
export default SignupIntro