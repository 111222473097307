import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";

class AdminMemberRepresentation extends Component {
    render() {
        return (
            <Segment>
                <Header>Representation</Header>
                <Table celled>
                    <Table.Header>
                        <Table.Row key="header">
                            <Table.HeaderCell>Street</Table.HeaderCell>
                            <Table.HeaderCell>HouseNumber</Table.HeaderCell>
                            <Table.HeaderCell>Active</Table.HeaderCell>
                            <Table.HeaderCell>Registrations</Table.HeaderCell>
                            <Table.HeaderCell>Complete</Table.HeaderCell>
                            <Table.HeaderCell>Incomplete</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.representation.map((obj, i) =>
                            <Table.Row key={i}>
                                <Table.Cell>{obj.street}</Table.Cell>
                                <Table.Cell>{obj.houseNumber}</Table.Cell>
                                <Table.Cell>{obj.active}</Table.Cell>
                                <Table.Cell>{obj.number}</Table.Cell>
                                <Table.Cell>{obj.complete}</Table.Cell>
                                <Table.Cell>{obj.incomplete}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Segment>
        );
    }
}

export default AdminMemberRepresentation