import React from "react";
import Roads from "./road";
import pinetrees from "../../assets/img/roads/pinetrees.jpg"

const PineTrees = () => (
    <Roads
        img={pinetrees}
        roadName="Pine Trees HQ"
        namedAfter="Code name of the HQ of Eigth Air Force Bomber Command"
        text={"High Wycome known as Daws Hill was the Headquarters of Eighth Air Force Bomber Command (Pinetree). Situated on land and in a house formerly occupied by Wycombe Abbey School and restored to them by the US on 9 May 1946."}
        refSource="http://www.americanairmuseum.com/place/173"
    />
)
export default PineTrees