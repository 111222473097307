import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import standardFormData from "../../components/standard_form_data";
import AdminMemberCounts from "./admin_member_counts";
import AdminMemberRepresentation from "./admin_member_representation";
import AdminMemberRow from "./admin_member_row";
import AdminMemberRoadCounts from "./admin_member_road_counts";

const API_FETCH_USERS = "https://www.pinetreesra.co.uk/api/admin/fetchUsers"


class Admin extends Component {
    constructor(props) {
        super(props);
        this.fetchUsers = this.fetchUsers.bind(this);
        this.fetchUsers();
    }

    fetchUsers() {
        fetch(API_FETCH_USERS, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({error: data.error, userList: data.userList, counts: data.counts, representation: data.representation, roadCounts: data.roadVotes});
        }));
    }

    state = {
        userList: [],
        counts: [],
        representation: [],
        roadCounts: [],
        error: null,
        search: "",
        successMessage: ""
    }

    render() {
        return (
            <Container style={{padding: "0 10px"}}>
                <Header as="h2">
                    Admin
                </Header>

                {this.state.error !== undefined && this.state.error !== null &&
                <Message error>
                    <Icon name="lock" color="red" size="large"/>
                    Sorry but you are not authorised to view this page
                </Message>
                }

                {this.state.userList !== undefined && this.state.userList.length > 0 &&
                <div>
                    <Input placeholder="search" icon={{name: "search"}} onChange={(e) => this.setState({search: e.target.value})}/>
                    <Table celled className="members_admin">
                        <Table.Header>
                            <Table.Row key="header">
                                <Table.HeaderCell>line</Table.HeaderCell>
                                <Table.HeaderCell>id</Table.HeaderCell>
                                <Table.HeaderCell>dt</Table.HeaderCell>
                                <Table.HeaderCell>fname</Table.HeaderCell>
                                <Table.HeaderCell>lname</Table.HeaderCell>
                                <Table.HeaderCell>email</Table.HeaderCell>
                                <Table.HeaderCell>houseNumber</Table.HeaderCell>
                                <Table.HeaderCell>street</Table.HeaderCell>
                                <Table.HeaderCell>postcode</Table.HeaderCell>
                                <Table.HeaderCell>complete</Table.HeaderCell>
                                <Table.HeaderCell>member_type</Table.HeaderCell>
                                <Table.HeaderCell>active_state</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.userList.length > 0 &&
                            this.state.userList.filter(function (userObj) {
                                if (this.state.search.length === 0) {
                                    return true;
                                }
                                let words = this.state.search.split(" ");
                                let found = true;
                                for (let word of words) {
                                    found = found && (
                                        (userObj !== undefined && userObj.fname !== null && userObj.fname !== undefined && userObj.fname.includes(word)) ||
                                        (userObj.lname !== undefined && userObj.lname !== null && userObj.lname.includes(word)) ||
                                        (userObj.street !== undefined && userObj.street !== null && userObj.street.includes(word)) ||
                                        (userObj.houseNumber !== undefined && userObj.houseNumber !== null && userObj.houseNumber.includes(word)) ||
                                        (userObj.member_type !== undefined && userObj.member_type !== null && userObj.member_type.includes(word)) ||
                                        (userObj.active_state !== undefined && userObj.active_state !== null && userObj.active_state.includes(word))
                                    );
                                }
                                return found;
                            }.bind(this)).map((userObj, i) =>
                                <AdminMemberRow userObj={userObj} key={userObj.id} i={i} loggedInUser={this.props.loggedInUser}/>
                            )}
                        </Table.Body>
                    </Table>
                </div>
                }

                <Segment style={{background: "#dddddd"}}>
                    <Header>Stats</Header>
                    <AdminMemberCounts counts={this.state.counts}/>
                    <AdminMemberRepresentation representation={this.state.representation}/>
                    <AdminMemberRoadCounts roadCounts={this.state.roadCounts}/>
                </Segment>
            </Container>
        )
    }
}

export default Admin