import React from "react";
import Roads from "./road";

import ira_eaker from "../../assets/img/roads/eaker.jpg"

const EakerStreet = () => (
    <Roads
        img={ira_eaker}
        roadName="Eaker Street"
        namedAfter="Ira C Eaker"
        text={"Promoted to brigadier general in January 1942, he was assigned to organize the VIII Bomber Command (which became the Eighth Air Force) in England and " +
        "to understudy the British system of bomber operations. Then, in December 1942, he assumed command of the Eighth Air Force. In a speech he gave to the " +
        "British that won him favorable publicity, he said, \"We won't do much talking until we've done more fighting. " +
        "After we've gone, we hope you'll be glad we came.\""}
        refSource="https://en.wikipedia.org/wiki/Ira_C._Eaker"

    />
)
export default EakerStreet