import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Wysiwyg from "../../components/wysiwyg";
import NewsType from "./news_type";
import NewsTitle from "./news_title";
import NewsDate from "./news_dt";
import NewsAdminBar from "./news_admin_bar";
import standardFormData from "../../components/standard_form_data";

const API_UPDATE_NEWS = "https://www.pinetreesra.co.uk/api/news/updateNews"

class NewsSegment extends Component {
    constructor(props) {
        super(props);
        this.showHidePreview = this.showHidePreview.bind(this);
    }

    saveField = (e, inputData) => {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("field", inputData.name);
        formData.append("value", inputData.value);
        formData.append("newsId", this.props.newsItem.id);
        fetch(API_UPDATE_NEWS, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            this.props.newsItem[inputData.name] = inputData.value;
            this.setState({successMessage: true});
            setTimeout(function () {
                this.setState({successMessage: false});
            }.bind(this), 2000);
        }));
    }

    state = {
        successMessage: false,
        preview: (this.props.newsItem.active_state !== "pending")
    }

    showHidePreview() {
        this.setState({preview: !this.state.preview});
    }

    render() {
        return (
            <Segment className={this.props.isFirst ? "latestNews" : ""}>
                {this.props.isFirst &&
                <div className="latestNewsFlash">LATEST NEWS</div>
                }
                <Header style={{marginTop: "10px"}}>
                    <NewsType isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} newsItem={this.props.newsItem}
                              newsTypeOptions={this.props.newsTypeOptions}/>
                    <NewsTitle isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} newsItem={this.props.newsItem}/>
                </Header>

                <Wysiwyg
                    editorContent={this.props.newsItem.news_content}
                    field="news_content"
                    saveField={this.saveField}
                    readOnly={!this.props.isAdmin}
                    preview={this.state.preview}
                    showHidePreview={this.showHidePreview}
                    style={{clear: "both"}}
                />

                <NewsDate date={this.props.newsItem.dt}/>
                <NewsAdminBar isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} newsItem={this.props.newsItem}
                              showHidePreview={this.showHidePreview} successMessage={this.state.successMessage}/>
            </Segment>
        )
    }
}

export default NewsSegment