import React, {Component} from 'react';
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Item from "semantic-ui-react/dist/commonjs/views/Item";

export default class Roads extends Component {
    render() {
        return (
            <Container>
                <Item.Group>
                    <Item>
                        <Item.Image size='small' src={this.props.img} alt={this.props.namedAfter}/>
                        <Item.Content>
                            <Item.Header as='h4'>{this.props.roadName}</Item.Header>
                            <Item.Meta style={{color: "black"}}><span
                                style={{color: "brown", fontWeight: "bold", fontSize: "80%"}}>Named after:&nbsp;&nbsp;</span>{this.props.namedAfter}</Item.Meta>
                            <Item.Description>
                                {this.props.text}
                            </Item.Description>
                            <Item.Extra style={{color: "#666666"}}><i>Source: <a href={this.props.refSource} target="_blank"
                                                                                 rel="noopener noreferrer">{this.props.refSource}</a></i></Item.Extra>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Container>
        )
    }


}