import React, {Component} from "react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import DatePicker from "react-datepicker";
import Wysiwyg from "../../components/wysiwyg";
import moment from "moment";
import ajaxSave from "../../components/ajax_save";

const eventColourOptions = [
    {key: "None", value: "", text: "None"},
    {key: "Red", value: "red", text: "Red"},
    {key: "Orange", value: "orange", text: "Orange"},
    {key: "Yellow", value: "yellow", text: "Yellow"},
    {key: "Olive", value: "olive", text: "Olive"},
    {key: "Green", value: "green", text: "Green"},
    {key: "Teal", value: "teal", text: "Teal"},
    {key: "Blue", value: "blue", text: "Blue"},
    {key: "Violet", value: "violet", text: "Violet"},
    {key: "Purple", value: "purple", text: "Purple"},
    {key: "Pink", value: "pink", text: "Pink"},
    {key: "Brown", value: "brown", text: "Brown"},
    {key: "Grey", value: "grey", text: "Grey"},
    {key: "Black", value: "black", text: "Black"},
]

class EventAdvertEdit extends Component {
    state = {
        selectedDate: new Date(this.props.event.event_dt)
    }

    saveField = (e, inputData) => {
        ajaxSave(this.props.loggedInUser, "events", this.props.event.id, inputData.name, inputData.value).then((data) => {
            this.props.event[inputData.name] = inputData.value;
            this.props.setSuccess(true);
        }).catch((error) => {
            console.log(error)
        });
    }

    updateDate(date) {
        this.setState({selectedDate: date});
        date = moment(date).format("YYYY-MM-DD HH:mm:ss");
        let inputData = {name: "event_dt", value: date};
        this.saveField(null, inputData)
    }

    render() {
        return (
            <>
                <div className="small-grey-text">Event background colour</div>
                <Dropdown
                    name="event_colour"
                    placeholder="select colour"
                    options={eventColourOptions}
                    onChange={(e, {name, value}) => this.saveField(e, {name, value})}
                    defaultValue={this.props.event.event_colour}
                />

                <div className="small-grey-text">Event title</div>
                <Input
                    style={{width: "100%"}}
                    name="event_title"
                    defaultValue={this.props.event.event_title}
                    placeholder="event title"
                    onChange={(e, f) => this.saveField(e, f)}
                />

                <div className="small-grey-text">Event date</div>
                <DatePicker
                    selected={this.state.selectedDate}
                    onChange={(date) => this.updateDate(date)}
                    dateFormat="dd/MM/yyyy @ HH:mm"
                    showTimeSelect
                    customInput={<Input icon='calendar alternate outline' iconPosition='left'/>}
                    popperPlacement="bottom"
                />


                <div className="small-grey-text">Event location</div>
                <Input
                    style={{width: "100%"}}
                    name="event_location"
                    defaultValue={this.props.event.event_location}
                    placeholder="event location"
                    onChange={(e, f) => this.saveField(e, f)}
                />

                <div className="small-grey-text">Event details</div>
                <Wysiwyg
                    editorContent={this.props.event.event_details}
                    field="event_details"
                    saveField={this.saveField}
                    readOnly={false}
                    preview={false}
                    style={{clear: "both"}}
                />
            </>
        );
    }
}

export default EventAdvertEdit