import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import NewsItems from "../layouts/news/news";

class News extends Component {
    render() {
        return (
            <Container>
                <Header as="h1">News</Header>
                <NewsItems loggedInUser={this.props.loggedInUser} isAdmin={this.props.isAdmin}/>
            </Container>
        )
    }
}

export default News