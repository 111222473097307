import React from "react";
import Roads from "./road";
import kilty from "../../assets/img/roads/kilty.png"

const KiltyPlace = () => (
    <Roads
        img={kilty}
        roadName="Kilty Place"
        namedAfter="Dr William J Kilty (Principal at the School)"
        text={"William Kilty was principal at the school from 1975 to 1987 but was involved with the school from 1971 as a coordinator. During his time at the school " +
        "he had the opportunity to meet both Sir Arthur 'Bomber' Harris, Air Chief Marshall of the RAF during WWII, and USAAF WWII counterpart General James Doolittle " +
        "who served on the former base. After retiring, Kilty remained in London with his wife and today they continue to split their time between the UK and Turkey. " +
        "Kilty passwed away in London in February 2020."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default KiltyPlace