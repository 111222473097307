import React, {Component} from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon"
import Image from "semantic-ui-react/dist/commonjs/elements/Image"

import neighbourhoodWatchLogo from "../../assets/img/neighbourhood_watch_logo.png";

class NewsIcon extends Component {
    render() {
        return (
            <div style={{width: "50px", display: "inline-block", verticalAlign: "top"}}>
                {this.props.newsType === "neighbourhood_watch" ?
                    <Image size="mini" src={neighbourhoodWatchLogo} alt="Neighbourhood Watch logo" floated="left"/>
                    :
                    <Icon name="sticky note" color="blue" style={{float: "left"}}/>
                }
            </div>
        )
    }
}

export default NewsIcon