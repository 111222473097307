import {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import standardFormData from "../../../../components/standard_form_data";
import RoomRow from "./roomRow";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {TableBody} from "semantic-ui-react";
import FormErrors from "../../../login/form_errors";

const API_BOOKING_ROOM_FETCH = "https://www.pinetreesra.co.uk/api/bookings/fetchRooms"

class RoomAdmin extends Component {
    constructor(props) {
        super(props);
        this.logErrors = this.logErrors.bind(this);
        this.fetchRooms = this.fetchRooms.bind(this);
        this.fetchRooms();
    }

    state = {
        rooms: [],
        errors: []
    }

    logErrors(errorsArr) {
        this.setState({errors: errorsArr});
    }

    fetchRooms() {
        fetch(API_BOOKING_ROOM_FETCH, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            if (data.ok) {
                this.setState({rooms: data.rooms})
            } else {
                this.setState({errors: data.errors});
            }
        })).catch(error => {
            this.setState({errors: ["Issue with the API"]});
        });
    }

    render() {
        return (
            <Container style={{padding: "10px"}}>
                <Header content={"Rooms"}/>
                <FormErrors errors={this.state.errors} />
                <Table>
                    <Table.Header>
                        <Table.Row key={"header_rooms"}>
                            <Table.HeaderCell>Room text</Table.HeaderCell>
                            <Table.HeaderCell>Room value</Table.HeaderCell>
                            <Table.HeaderCell>Room surcharge</Table.HeaderCell>
                            <Table.HeaderCell>Room dimensions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <TableBody>
                        {this.state.rooms.map((room) => <RoomRow key={"room_" + room.id} room={room} errors={this.state.errors} loggedInUser={this.props.loggedInUser} logErrors={this.logErrors}/>)}
                    </TableBody>
                </Table>

            </Container>
        );
    }
}

export default RoomAdmin