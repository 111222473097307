import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import NewLogin from "../pages/new_login";

class ResidentsOnly extends Component {
    render() {
        return (
            <div>
                <Container text style={{textAlign: "center"}}>
                    <Header as="h1">
                        Pine Trees Residents Association
                    </Header>
                    <Header as="h2">
                        <Icon name="eye" color="orange" size="large"/>
                        For Residents Eyes Only
                    </Header>
                </Container>
                <Container style={{textAlign: "center"}}>
                    <p>The information on this page is of a sensitive nature and as such is protected behind a "Members" section.</p>
                    <p>It is free to sign up</p>
                    <NewLogin handleLogin={this.props.handleLogin} store={this.props.store}/>
                </Container>
            </div>
        )
    }
}

export default ResidentsOnly