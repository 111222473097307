import React from "react";
import Roads from "./road";
import leigh from "../../assets/img/roads/leigh.jpg"

const LeighPlace = () => (
    <Roads
        img={leigh}
        roadName="Leigh Place"
        namedAfter="EDNA JOHNSTON LEIGH"
        text={"A teacher born in 1916 in McCune, Kansas, who married an RAF pilot she met when he was training in the US during WWII. Leigh became famous after her death " +
        "in 1991 when, during her 23-year tenure working at the school, she researched Homer’s classic Greek poems, \"The Iliad\" and \"The Odyssey\", and had developed " +
        "the theory that the acclaimed works were actually poetic guides to astronomy in the ancient world. Subsequently the books, Homer's Secret Iliad (1999) and " +
        "Homer's Secret Odyssey (2011), based on her research, were published by her daughter and son-in-law to wide acclaim."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"
    />
)
export default LeighPlace