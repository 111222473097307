import React from "react";
import RoadsIntro from "../layouts/roads/roadsintro";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

const History = () => (
    <div>
        <Header as='h1'>
            Pine Trees Estate
        </Header>
        <Header as='h2' textAlign="center" style={{marginBottom: "20px"}}>
            <Icon name="history" color="brown" size="large"/>
            History
        </Header>
        <RoadsIntro/>
    </div>

)

export default History