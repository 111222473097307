import React, {Component} from "react";

class POC extends Component {
    render() {
        return (
            <div style={{
                position: "absolute",
                top: "200px",
                left: "0",
                transform: "rotate(-20deg)",
                color: "red",
                fontWeight: "bold",
                fontSize: "200%",
                background: "pink",
                padding: "20px",
                borderRadius: "10px",
                lineHeight: "45px",
                textAlign: "center",
                zIndex: "-1"
            }}>
                Proof of concept<br/>Only visible to the committee
            </div>
        );
    }
}

export default POC