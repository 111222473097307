import React, {Component} from 'react';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';


class Wysiwyg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: this.setInitial(),
            field: this.props.field,
            readOnly: this.props.readOnly,
            toolbarOnFocus: this.props.toolbarOnFocus,
            toolbarHidden: this.props.toolbarHidden
        };
    }

    setInitial() {
        try {
            return EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.editorContent)));
        } catch (exception) {
            return EditorState.createEmpty();
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });

        this.props.saveField(null, {name: this.state.field, value: JSON.stringify(convertToRaw(editorState.getCurrentContent()))});
    };

    render() {
        return (
            <section style={{marginBottom: "0"}} className={this.props.className || ""}>
                {this.state.readOnly || this.props.preview ?
                    <div style={this.props.style || {}} className="wysiwyg-content"
                         dangerouslySetInnerHTML={{__html: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}}/>
                    :
                    <Editor
                        toolbarOnFocus={this.state.toolbarOnFocus}
                        toolbarHidden={this.state.toolbarHidden}
                        editorState={this.state.editorState}
                        wrapperClassName="wysiwyg-content"
                        toolbarClassName="wysiwyg-toolbar"
                        editorClassName="wysiwyg-editor"
                        onEditorStateChange={this.onEditorStateChange}
                    />
                }
            </section>
        )
    }
}

export default Wysiwyg
