import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import RoomAdmin from "./rooms/room_admin";
import EventTypesAdmin from "./events/event_types_admin";
import BookingActions from "./bookings/booking_actions";

class AdminMenu extends Component {
    render() {
        return (
            <>
                <Header as={"h1"} content={"PTRA Community Centre Admin"}/>
                <BookingActions loggedInUser={this.props.loggedInUser}/>
                <RoomAdmin loggedInUser={this.props.loggedInUser}/>
                <EventTypesAdmin loggedInUser={this.props.loggedInUser}/>
            </>
        );
    }
}

export default AdminMenu
