import React, {Component} from "react";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

class NewsTitle extends Component {
    render() {
        return (
            <span>
            {this.props.isAdmin && !this.props.preview ?
                <Input
                    name="news_title"
                    defaultValue={this.props.newsItem.news_title}
                    style={{width: "90%"}}
                    onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                />
                :
                this.props.newsItem.news_title
            }
            </span>
        )
    }
}

export default NewsTitle