import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
// import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Message} from 'semantic-ui-react';
// import Voting from "../components/voting";
// import Header from "semantic-ui-react/dist/commonjs/elements/Header";
// import VoteProsCons from "../components/vote_pros_cons";
// import voteImage from "../assets/img/vote.png";
// import slowDown from "../assets/img/slow_down.jpeg";
// import restrictedPriority from "../assets/img/priority-restrictions.jpg";

class PtraVote extends Component {
    render() {
        return (<Container style={{padding: "0 10px"}}><Message success content={"Voting has now closed. The votes are being counted and will be published shortly"} /></Container> );

        // return (<Container style={{padding: "0 10px"}}>
        //     <Image src={slowDown} alt={"Speeding"} floated={"left"} size={"small"}
        //            style={{transform: "rotate(-15deg)", marginLeft: "20px"}}/>
        //
        //     <Image src={voteImage} alt={"Votes being called"} floated={"right"} size={"small"} className="image-zoom"
        //            style={{transform: "rotate(20deg)", marginRight: "20px"}}/>
        //     <Header as="h1">PTRA Voting</Header>
        //     <Header as="h3" style={{textAlign:"center"}} color={"red"}>deadline Tuesday 7th Feb</Header>
        //     <div style={{clear: "both", padding: "0 30px"}}>
        //         <Header as="h2">Speed reduction</Header>
        //         <p>
        //             For a while residents have raised the issue of some cars being driven too fast on the roads of
        //             Pine Trees, making it unsafe for our children, pets and other road users. Clearly the highest
        //             priority for everyone who lives here is that the ones they love stay safe, so we felt it was an
        //             important topic to explore. Volunteers have carried out research and we have chosen the
        //             following few options for you the residents to prioritise through on-line voting. When you vote
        //             you must keep in mind that different options come with different requirements, be it the need
        //             for time from volunteers or different levels of funding.
        //         </p>
        //
        //         <Header content={"Background:"}/>
        //         <p>
        //             There are a handful of exceptions but we believe that most of the residents take care when
        //             driving on our streets, however, in addition to neighbours there are delivery drivers and
        //             visitors on our roads who may have different motivations and less respect for our home. Any
        //             solutions that go forward for implementation will need to keep this in mind.
        //         </p>
        //
        //         <Header content={"Voting:"}/>
        //         <p>
        //             Each single household can vote up or down on each suggestion
        //         </p>
        //         <p>
        //             The options for your consideration and voting are:
        //         </p>
        //     </div>
        //
        //     <p style={{color: "brown", marginTop: "40px", fontWeight: "bold"}}>
        //         The first two are about formalising a 20mph limit for the Police to enforce:
        //     </p>
        //
        //     <Segment style={{marginLeft: "20px"}}>
        //         <Header as="h2" color={"green"}>1. Explore 20 mph enforceable limit for all unadopted roads</Header>
        //         <p>
        //             The majority of the roads at Pine Trees will not be adapted by the council with Kennedy Road
        //             being the exception (worth noting that it could take some time before it is). It was believed
        //             that these unadopted roads would as a result not have speeds enforced by the Police, however we
        //             have found some evidence that it may be possible to have our unadopted roads limited to 20 mph
        //             and have the Police enforce this limit.
        //         </p>
        //         <VoteProsCons
        //             pros={"Relatively low cost, enables Police enforcement which removes any tension between residents."}
        //             cons={"May not be possible. Funding would be required."}
        //         />
        //         <Voting
        //             votingType="speeding_1"
        //             buttons={{up: "Good idea", down: "Don't like"}}
        //             loggedInUser={this.props.loggedInUser}
        //             allowedToVote={true}
        //         />
        //     </Segment>
        //
        //     <Segment style={{marginLeft: "20px"}}>
        //         <Header as="h2" color={"green"}>2. Reduce the speed limit for Kennedy Road to 20mph</Header>
        //         <p>
        //             Once Kennedy Road is adopted it would have a legal maximum speed limit, of 30mph unless the
        //             council decided to lower it. As Kennedy Road goes past the school and runs along the park where
        //             children play it is possible that we could work with the council to have this reduced to 20 mph
        //             and encourage the Police to monitor and enforce this limit.
        //         </p>
        //         <VoteProsCons
        //             pros={"No cost, enables Police enforcement which removes any tension between residents, would help protect the children both around the school and the park."}
        //             cons={"Would only be for Kennedy, would require lobbying of the council, the Police may not monitor often/effectively."}
        //         />
        //         <Voting
        //             votingType="speeding_2"
        //             buttons={{up: "Good idea", down: "Don't like"}}
        //             loggedInUser={this.props.loggedInUser}
        //             allowedToVote={true}
        //         />
        //     </Segment>
        //
        //     <p style={{color: "brown", marginTop: "40px", fontWeight: "bold"}}>
        //         The next five are about using the subtle influence of signage to remind drivers to take care:
        //     </p>
        //
        //
        //     <Segment style={{marginLeft: "20px"}}>
        //         <Header as="h2" color={"blue"}>3. Purchase stickers for bins that remind everyone the importance of
        //             driving carefully<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(exact wording to be defined)</Header>
        //         <VoteProsCons
        //             pros={"Relatively low cost, if used by everyone would show that the community care about careful driving around our home, so on bin collection day it could make quite a statement."}
        //             cons={"Would only be visible on bin collection day. Would require funding."}
        //         />
        //         <Voting
        //             votingType="speeding_3"
        //             buttons={{up: "Good idea", down: "Don't like"}}
        //             loggedInUser={this.props.loggedInUser}
        //             allowedToVote={true}
        //         />
        //     </Segment>
        //
        //     <Segment style={{marginLeft: "20px"}}>
        //         <Header as="h2" color={"blue"}>4. Put up metal signage on lamp posts to remind everyone the
        //             importance of driving carefully<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(exact wording to be
        //             defined)</Header>
        //         <VoteProsCons
        //             pros={"Creates a permanent reminder that we care about driving carefully around our home. Less expensive that electronic signage."}
        //             cons={"Would require funding. Once Kennedy Road is adopted any signage we’ve erected on Kennedy may be removed."}
        //         />
        //         <Voting
        //             votingType="speeding_4"
        //             buttons={{up: "Good idea", down: "Don't like"}}
        //             loggedInUser={this.props.loggedInUser}
        //             allowedToVote={true}
        //         />
        //     </Segment>
        //
        //
        //     <Segment style={{marginLeft: "20px"}}>
        //         <Header as="h2" color={"blue"}>5. Purchase a few of the electronic signs and attach to lamp posts
        //             with solar chargers</Header>
        //         <VoteProsCons
        //             pros={"Once in place don't require much looking after. More likely to get noticed than a traditional sign."}
        //             cons={"Cost estimate £1500 to £2000 each, only cover defined areas - i.e. would not be moved to other problem areas. May not get enough sun to work all year round. Once Kennedy Road is adopted any electronic signage we've erected may be removed."}
        //         />
        //         <Voting
        //             votingType="speeding_5"
        //             buttons={{up: "Good idea", down: "Don't like"}}
        //             loggedInUser={this.props.loggedInUser}
        //             allowedToVote={true}
        //         />
        //     </Segment>
        //
        //     <Segment style={{marginLeft: "20px"}}>
        //         <Header as="h2" color={"blue"}>6. Purchase a mobile electronic sign that could be placed where
        //             it was felt there was a problem</Header>
        //         <VoteProsCons
        //             pros={"Can target the problem areas. More likely to get noticed than a traditional sign."}
        //             cons={"Cost estimate £1500 to £2000 each. Requires volunteers to manage each time is deployed or repositioned. We’d need to find a way to provide power (possibly solar / battery) when deployed and a place to store when not. There is also the risk it could be stolen."}
        //         />
        //         <Voting
        //             votingType="speeding_6"
        //             buttons={{up: "Good idea", down: "Don't like"}}
        //             loggedInUser={this.props.loggedInUser}
        //             allowedToVote={true}
        //         />
        //     </Segment>
        //
        //     <Segment style={{marginLeft: "20px"}}>
        //         <Header as="h2" color={"blue"}>7. Run a speed monitoring exercise by residents where offenders
        //             details would be provided to the police</Header>
        //         <VoteProsCons
        //             pros={"Raises awareness, speeders get written to, low cost."}
        //             cons={"This would be limited to adopted roads (ie. only Kennedy Avenue) unless Option 1 has been implemented (above). Would require major commitment from volunteers. Can create animosity within the community."}
        //         />
        //         <Voting
        //             votingType="speeding_7"
        //             buttons={{up: "Good idea", down: "Don't like"}}
        //             loggedInUser={this.props.loggedInUser}
        //             allowedToVote={true}
        //         />
        //     </Segment>
        //
        //     <p style={{color: "brown", marginTop: "40px", fontWeight: "bold"}}>
        //         The last two are for completeness:
        //     </p>
        //
        //     <Segment style={{marginLeft: "20px"}}>
        //         <Header as="h2" color={"purple"}>8. Ask Broadlands to install speed bumps at key locations</Header>
        //         <VoteProsCons
        //             pros={"May slow down some cars."}
        //             cons={"Won't slow down SUVs, vans, or lorries. Will not be cheap to install and additionally can cause damage to the road around them so will cost more to maintain. Could be problematic for those cars with low suspension. Lowers the experience of living here."}
        //         />
        //         <Voting
        //             votingType="speeding_8"
        //             buttons={{up: "Good idea", down: "Don't like"}}
        //             loggedInUser={this.props.loggedInUser}
        //             allowedToVote={true}
        //         />
        //     </Segment>
        //
        //     <Segment style={{marginLeft: "20px"}}>
        //         <Header as="h2" color={"purple"}>9. Investigate the possibility of adding restricted-priority half-road zones...</Header>
        //         <Image src={restrictedPriority} floated={"right"} className={"image-zoom"} style={{transform: "rotate(20deg)", borderRadius:"20px"}} size={"small"} />
        //         <VoteProsCons
        //             pros={"Will probably slow down most cars."}
        //             cons={"Will not be cheap to install and will cost to maintain. Adds inconvenience for residents. May be awkward to locate so that it doesn't affect the main road roundabout."}
        //         />
        //         <Voting
        //             votingType="speeding_9"
        //             buttons={{up: "Good idea", down: "Don't like"}}
        //             loggedInUser={this.props.loggedInUser}
        //             allowedToVote={true}
        //         />
        //     </Segment>
        //
        //     <Segment style={{marginLeft: "20px"}}>
        //         <Header as="h2" color={"purple"}>10. Do nothing</Header>
        //         <Voting
        //             votingType="speeding_10"
        //             buttons={{up: "Good idea", down: "Don't like"}}
        //             loggedInUser={this.props.loggedInUser}
        //             allowedToVote={true}
        //         />
        //     </Segment>
        //
        //     <p style={{color: "green", marginTop: "40px", fontWeight: "bold"}}>
        //         We invite your votes to help guide the PTRA which way to pursue. Please keep in mind the pros and
        //         cons and any other impacts, when making your choice. Also, remember the PTRA is a volunteer group
        //         and as such have limited powers and resources, so the option with the most votes may still not be possible to action.
        //     </p>
        //
        // </Container>);
    }
}

export default PtraVote