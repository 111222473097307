import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import MyInput from "../../components/myinput";
import axios from "axios";

const API_PATH = 'https://www.pinetreesra.co.uk/api/login/update';

export default class MissingDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            fname: '',
            lname: '',
            houseNumber: '',
            street: '',
            postcode: '',
            token: '',
            formValid: false
        }

        if (props.store.loggedInUser !== undefined) {
            this.state = {
                id: props.store.loggedInUser.id,
                fname: props.store.loggedInUser.fname,
                lname: props.store.loggedInUser.lname,
                houseNumber: props.store.loggedInUser.houseNumber,
                street: props.store.loggedInUser.street,
                postcode: props.store.loggedInUser.postcode,
                token: props.store.loggedInUser.token,
                formValid: (props.store.loggedInUser.fname !== undefined && props.store.loggedInUser.fname.length > 0 && props.store.loggedInUser.lname !== undefined && props.store.loggedInUser.lname.length > 0)
            }
        }

        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: {'content-type': 'application/json'},
            data: this.state
        })
            .then(result => {
                this.props.handleLogin(result.data);
            })
            .catch(error => this.setState({error: error.message}));
    }

    handleUpdate(event) {
        this.setState({
            [event.target.name]: event.target.value,
            formValid: (this.state.fname !== undefined && this.state.lname !== undefined && this.state.houseNumber && this.state.street !== undefined && this.state.postcode && this.state.fname.length > 0 && this.state.lname.length > 0 && this.state.houseNumber.length > 0 && this.state.street.length > 0 && this.state.postcode.length > 0)
        });
    }

    render() {
        return (
            <div>
                <p>It looks like we are missing some details from your record with us. Please spare a little time to fill in the information below</p>

                <Form style={{width: '400px', margin: 'auto', textAlign: "left", borderRadius: "5px", background: "#dcefd9", padding: "10px"}}>
                    {this.props.store.loggedInUser.fname === "" &&
                    <MyInput fieldName="fname" fieldValue={this.state.fname} handleUpdate={this.handleUpdate} error={this.state.fname.length === 0}/>
                    }

                    {this.props.store.loggedInUser.lname === "" &&
                    <MyInput fieldName="lname" fieldValue={this.state.lname} handleUpdate={this.handleUpdate} error={this.state.lname.length === 0}/>
                    }

                    {this.props.store.loggedInUser.houseNumber === "" &&
                    <MyInput fieldName="houseNumber" fieldValue={this.state.houseNumber} handleUpdate={this.handleUpdate} error={this.state.houseNumber.length === 0}/>
                    }

                    {this.props.store.loggedInUser.street === "" &&
                    <div className="field">
                        <label>street</label>
                        <select name="street" onChange={this.handleUpdate}>
                            <option value="">please select</option>
                            <option value="Arizona Way">Arizona Way</option>
                            <option value="Beech Street">Beech Street</option>
                            <option value="California Way">California Way</option>
                            <option value="DeYoung Way">DeYoung Way</option>
                            <option value="Doolittle Avenue">Doolittle Avenue</option>
                            <option value="Eaker Street">Eaker Street</option>
                            <option value="Eisenhower Lower Close">Eisenhower Lower Close</option>
                            <option value="Kelly Road">Kelly Road</option>
                            <option value="Kennedy Avenue">Kennedy Avenue</option>
                            <option value="Kilty Place">Kilty Place</option>
                            <option value="Leigh Place">Leigh Place</option>
                            <option value="Mansfield Close">Mansfield Close</option>
                            <option value="Robertson Place">Robertson Place</option>
                            <option value="Threlkeld Close">Threlkeld Close</option>
                            <option value="Trinity Circle">Trinity Circle</option>
                            <option value="Utah Way">Utah Way</option>
                            <option value="Wernette Way">Wernette Way</option>
                        </select>
                    </div>
                    }

                    {this.props.store.loggedInUser.postcode === "" &&
                    <MyInput fieldName="postcode" fieldValue={this.state.postcode} handleUpdate={this.handleUpdate} error={this.state.postcode.length === 0}/>
                    }

                    {/*{*/}
                    {/*    Object.keys(this.props.store.loggedInUser).map((key, i) => (*/}
                    {/*        <MyInput fieldName={key} fieldValue={this.props.store.loggedInUser[key]}/>*/}
                    {/*    ))*/}
                    {/*}*/}
                    <Button type='submit' primary disabled={!this.state.fname || !this.state.lname || !this.state.houseNumber || !this.state.street || !this.state.postcode}
                            onClick={e => {
                                this.handleSubmit(e)
                            }}>Save</Button>
                </Form>
            </div>
        )
    }

}