import React, {Component} from "react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";

const memberTypeOptions = [
    {key: "admin", value: "admin", text: "admin"},
    {key: "committee", value: "committee", text: "committee"},
    {key: "member", value: "member", text: "member"},
]

class AdminMemberTypeState extends Component {
    render() {
        return (
            <Dropdown
                name={"member_type"}
                placeholder="select member type"
                options={memberTypeOptions}
                onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                defaultValue={this.props.userObj.member_type}
            />
        )
    }
}

export default AdminMemberTypeState