import React, {Component} from "react";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

class UsefulSubCategory extends Component {
    render() {
        return (
            <div>
                {this.props.isAdmin && !this.props.preview &&
                <div style={{margin: "14px 0"}}>
                    <Input
                        name="sub_category"
                        defaultValue={this.props.usefulItem.sub_category}
                        style={{width: "90%"}}
                        onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                        placeholder="sub category"
                    />
                </div>
                }
            </div>
        )
    }
}

export default UsefulSubCategory