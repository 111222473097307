import React, {Component} from "react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import BookingDetails from "./booking_details";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Confirm from "semantic-ui-react/dist/commonjs/addons/Confirm";


class ShowBooking extends Component {
    state = {
        confirm: false,
        open: false
    }

    confirm(action, result) {
        this.setState({confirm: result, open: false});
        if (result && action === "delete") {
            this.props.deleteEvent(this.props.showEvent.id);
        }

        if (result && action === "approve") {
            this.props.approveEvent(this.props.showEvent.id);
        }

        if (result && action === "mark_paid") {
            this.props.markEventPaid(this.props.showEvent.id);
        }
    }

    render() {
        return (
            <>
                <Modal
                    onClose={() => this.props.setShowBookingOpen(false)}
                    onOpen={() => this.props.setShowBookingOpen(true)}
                    open={this.props.open}
                    closeIcon
                >
                    {this.props.showEvent !== null &&
                    <>
                        <Modal.Header>Booking</Modal.Header>
                        <Modal.Content>
                            <BookingDetails asDisplay={true} booking={this.props.showEvent} loggedInUser={this.props.loggedInUser}/>
                        </Modal.Content>

                        {(this.props.loggedInUser.id === this.props.showEvent.user_id && this.props.showEvent.status === "pending") &&
                        <Modal.Actions>
                            <Button content="Delete"
                                    labelPosition="right"
                                    icon="delete calendar"
                                    onClick={() => this.setState({open: true})}
                                    negative/>
                            <Confirm
                                open={this.state.open}
                                onCancel={() => this.confirm("delete", false)}
                                onConfirm={() => this.confirm("delete", true)}
                            />
                        </Modal.Actions>
                        }

                        {(this.props.loggedInUser.id !== this.props.showEvent.user_id && this.props.loggedInUser.permissions.filter(p => p.permission === "booking_approve").length > 0 && this.props.showEvent.status === "pending") &&
                        <Modal.Actions>
                            <Button content="Approve"
                                    labelPosition="right"
                                    icon="checkmark"
                                    onClick={() => this.setState({open: true})}
                                    positive/>
                            <Confirm
                                open={this.state.open}
                                onCancel={() => this.confirm("approve", false)}
                                onConfirm={() => this.confirm("approve", true)}
                            />
                        </Modal.Actions>
                        }

                        {(this.props.loggedInUser.id !== this.props.showEvent.user_id && this.props.loggedInUser.permissions.filter(p => p.permission === "booking_admin").length > 0 && this.props.showEvent.status === "request_approved") &&
                        <Modal.Actions>
                            <Button content="Mark as paid"
                                    labelPosition="right"
                                    icon="checkmark"
                                    onClick={() => this.setState({open: true})}
                                    color="blue"/>
                            <Confirm
                                open={this.state.open}
                                onCancel={() => this.confirm("mark_paid", false)}
                                onConfirm={() => this.confirm("mark_paid", true)}
                            />
                        </Modal.Actions>
                        }
                    </>
                    }
                </Modal>
            </>
        );
    }
}

export default ShowBooking