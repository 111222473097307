import React, {Component} from "react";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import CommitteeCard from "../components/committeecard";
import louiseLord from "../assets/img/avatar/louise_lord.jpeg";
import kausikPal from "../assets/img/avatar/kausik_pal.jpg";
import chrisBracchi from "../assets/img/avatar/chris_bracchi.jpg";
import phillipaAllan from "../assets/img/avatar/phillipa_allan.jpg"

const committee = [
    {
        memberTitle: "Acting Chairman",
        memberName: "Chris Bracchi",
        elected: "March 2021",
        avatarImage: chrisBracchi,
        blurb: null,
        position: "bottom left",
        founder: true
    },
    {
        memberTitle: "Secretary",
        memberName: "Louise Lord",
        elected: "March 2021",
        avatarImage: louiseLord,
        blurb: "Hi! I’m Louise (Lou) and moved to the estate during the summer of 2020 with my husband and two sons and we have lived in High Wycombe for 10 years. " +
            "We love all the estate has to offer and have enjoyed exploring the woods and getting to know our neighbours and look forward to getting to know more of you. " +
            "We are trying our hand at gardening seeing as we are spending so much time in the garden so any tips welcome!\n" +
            "\n" +
            "I work within Higher Education including events and I’m always happy to get involved and help where I can. I have studied various areas including Psychology, " +
            "which I find fascinating as well as Leadership and Management. Before children I was often found at the stables horse riding and teaching lessons and hopefully " +
            "one day I can return! I have a sweet tooth and love a slice (or two!) of cake…paired with a coffee during the day or a gin and tonic once the kids are in bed!\n" +
            "\n" +
            "I’m excited to be part of the PTRA to help contribute positively to our growing community so it can be an even more amazing place to live. Hopefully " +
            "I can help in a range of areas from addressing the latest concern to social events that bring us together. Do say hi if you see me out and about, " +
            "I’m always up for a chat.",
        position: "bottom right",
        founder: true
    },
    {
        memberTitle: "Member (Neighbourhood Watch)",
        memberName: "Kausik Pal",
        elected: "March 2021",
        avatarImage: kausikPal,
        blurb: null,
        position: "bottom right",
        founder: true
    },
    {
        memberTitle: "Treasurer",
        memberName: "Alina",
        elected: "March 2021",
        avatarImage: "https://semantic-ui.com/images/avatar/large/helen.jpg",
        blurb: null,
        position: "bottom left",
        founder: true
    },
    {
        memberTitle: "Member",
        memberName: "Simon Potts",
        elected: "June 2021",
        avatarImage: "https://semantic-ui.com/images/avatar/large/matt.jpg",
        blurb: null,
        position: "bottom left",
        founder: false
    },
    {
        memberTitle: "Member",
        memberName: "Phillipa",
        elected: "June 2021",
        avatarImage: phillipaAllan,
        blurb: null,
        position: "bottom right",
        founder: false
    },
    {
        memberTitle: "Member",
        memberName: "Adam Galea",
        elected: "July 2022",
        avatarImage: "https://react.semantic-ui.com/images/avatar/large/patrick.png",
        blurb: null,
        position: "bottom left",
        founder: false
    },
]

class About extends Component {
    render() {
        return (
            <Container className={"full"}>
                <Header as='h1'>Pine Trees Residents Association</Header>
                <Header as='h2' textAlign="center">
                    <Icon name="hand point right" color="teal" size="large"/>
                    About
                </Header>
                <p>Formed in March 2021, the Residents Association is an informal association with an elected committee and meets monthly on the first Thursday of each month.</p>
                <p>The committee is now made up of {committee.length} members who take on responsibilities for tasks assigned during agenda topics in the meetings.</p>
                <p>The Residents Association is run on an informal basis and as such if you would like to get involved please register your interest with the committee</p>

                <Card.Group>
                    {committee.map((member, i) =>
                        <CommitteeCard member={member} key={i}/>
                    )}
                </Card.Group>
            </Container>
        )
    }
}

export default About