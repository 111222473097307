import {Component} from "react";
import Step from "semantic-ui-react/dist/commonjs/elements/Step";

class BookingProcessSteps extends Component {
    render() {
        return (
            <Step.Group ordered widths={4}>
                <Step completed={this.props.roomFilter !== null} style={this.props.highlightRoomFilter ? {transform: "scale(1)", animation: "pulse 2s infinite"} : {}}>
                    <Step.Content>
                        <Step.Title>Select the room</Step.Title>
                        <Step.Description>
                            Choose the room<br/>by selecting the filter
                            {(this.props.roomFilter !== null && parseInt(this.props.roomFilter.room_surcharge) > 0)  &&
                            <div className="small-grey-text">£ {this.props.roomFilter.room_surcharge} surcharge</div>
                            }
                        </Step.Description>
                    </Step.Content>
                </Step>

                <Step completed={this.props.pendingEvent !== null && this.props.pendingEvent.start !== undefined} disabled={this.props.roomFilter === null}>
                    <Step.Content>
                        <Step.Title>Date/Time</Step.Title>
                        <Step.Description>Click and drag<br/>the day and time<br/>you require</Step.Description>
                    </Step.Content>
                </Step>

                <Step completed={this.props.rowAdded !== null} disabled={this.props.pendingEvent === null}>
                    <Step.Content>
                        <Step.Title>Enter the booking details</Step.Title>
                        <Step.Description>Select the event type and confirm</Step.Description>
                    </Step.Content>
                </Step>
                <Step disabled>
                    <Step.Content>
                        <Step.Title>Pending booking</Step.Title>
                        <Step.Description>Your booking request<br/>will now be processed and<br/>we will be in touch</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
        );
    }
}

export default BookingProcessSteps