import React, {Component} from "react";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

class UsefulWebsite extends Component {
    render() {
        return (
            <div>
                {this.props.isAdmin && !this.props.preview ?
                    <div style={{margin: "14px 0"}}>
                        <Input
                            name="web_link"
                            defaultValue={this.props.usefulItem.web_link}
                            style={{width: "90%"}}
                            onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                            placeholder="Link to website (eg. https://www.pinetreesra.co.uk)"
                        />
                    </div>
                    :
                    <div>
                        {this.props.usefulItem.web_link !== null && this.props.usefulItem.web_link.length > 0 &&
                        <div style={{margin: "14px 0"}}>
                            <a href={this.props.usefulItem.web_link} rel="noopener noreferrer" target="_blank">{this.props.usefulItem.web_link}</a>
                        </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default UsefulWebsite