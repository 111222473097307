import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

class LoginIntro extends Component {
    render() {
        return (
            <div className="content">
                <h3>One of us already?</h3>
                <p>
                    Login to see news and other useful information
                </p>
                <Button className="btn" primary id="sign-in-btn" onClick={() => {
                    document.querySelector('.members-page').classList.remove("sign-up-mode");
                }} content="Login"/>
            </div>
        );
    }
}

export default LoginIntro