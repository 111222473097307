import React, {Component} from "react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import NewsIcon from "./news_icon";

class NewsType extends Component {
    render() {
        return (
            <span>
                {this.props.isAdmin && !this.props.preview ?
                    <div className="small-grey-text" style={{marginBottom: "5px"}}>
                        Type:&nbsp;
                        <Dropdown
                            name="news_type"
                            placeholder="select icon"
                            options={this.props.newsTypeOptions}
                            onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                            defaultValue={this.props.newsItem.news_type}
                        />
                    </div>
                    :
                    <NewsIcon newsType={this.props.newsItem.news_type}/>
                }
            </span>
        )
    }
}

export default NewsType