import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container"
import Header from "semantic-ui-react/dist/commonjs/elements/Header"

import DropzoneComponent from "../components/dropzone";
import POC from "../components/poc";

class Photos extends Component {
    render() {
        return (
            <Container className={"full"}>
                <POC />
                <Header as="h1">Photo Competition</Header>
                <p>
                    Welcome budding photographers!
                </p>
                <DropzoneComponent loggedInUser={this.props.loggedInUser} additionalPath=""/>
            </Container>
        )
    }
}

export default Photos