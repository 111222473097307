import React, {Component} from "react";
import Accordion from "semantic-ui-react/dist/commonjs/modules/Accordion";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import PineTrees from "./pinetrees";
import KennedyAvenue from "./kennedy";
import ArizonaWay from "./arizona";
import CaliforniaWay from "./california";
import UtahWay from "./utah";
import LeighPlace from "./leigh";
import DeyoungWay from "./deyoung";
import EakerStreet from "./eaker";
import DoolittleAvenue from "./doolittle";
import RobertsonPlace from "./robertson";
import MansfieldTrinity from "./mansfieldtrinity";
import KellyRoad from "./kelly";
import ThrelkeldClose from "./threlkeld";
import KiltyPlace from "./kilty";
import WernetteWay from "./wernette";
import EisenhowerClose from "./eisenhower";
import BeechStreet from "./beech";
import Additional from "./additional";
import estate from "../../assets/img/roads/estate.jpeg"
import tammy from "../../assets/img/roads/tammy.png"

export default class Roadsintro extends Component {
    state = {activeIndex: 0}

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    render() {
        const {activeIndex} = this.state

        return (
            <Container fluid style={{padding: "0 10px"}}>
                <Accordion styled fluid>
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={this.handleClick}
                        style={{color: "#555555"}}
                    >
                        <Icon name='dropdown'/>
                        Site history and how roads got their names
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <Image size={"small"} src={estate} alt="Pine Trees Estate" floated="left"/>
                        <p>
                            Following World War II, the property of RAF Daws Hill in High Wycombe, located some 32 miles just west of London, was left vacant and for several years
                            its
                            future development unknown. It wasn't until around 2013 or so that the property was finally sold and the township agreed upon its development into an
                            upscale
                            residential (with some commercial) area.
                        </p>
                        <p>
                            On January 8, 2014, Tamara (Tammy) Palmer and Sean Kelly, alumni of US London Central High School, learned of the site's new owner and his plans.
                            She wrote to the High Wycombe City Council expressing her interest on behalf of the LCHS alumni, staff, and faculty to preserve, if possible, the
                            history of
                            the
                            school's 36-year occupancy with some sort of monument—perhaps a park statue, a bench with a plaque—anything that would secure our legacy. With the help
                            of
                            Sean Kelly,
                            who has remained in England and whose mother was one of our longtime and treasured faculty members, Martha Gail Kelly (and who also is living in
                            England),
                            the two remained in constant contact with the City and developer—Tammy being the brains and Sean doing the onsite legwork.
                        </p>
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={this.handleClick}
                        style={{color: "#555555"}}
                    >
                        <Icon name='dropdown'/>
                        Tammy's initial idea
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        <Image size={"small"} src={tammy} alt="Tammara Palmer" floated="left"/>
                        <p>
                            KEY HISTORICAL AND EDUCATOR NAMES TO BE HONOURED AT RESIDENTIAL DEVELOPMENT ON FORMER USAF/US NAVY BASE IN ENGLAND
                        </p>
                        <p>
                            RAF Daws Hill in High Wycombe was a former United States Air Force and later a United States Navy base until its closure as part of a Cold War drawdown
                            in
                            2007. The
                            base was also home to the London Central High School (LCHS) that operated there from 1971 until the base closed. The school was established for the
                            education of both
                            military dependents and American civilian students living overseas.
                        </p>
                        <p>
                            To commemorate the relationship between the US Military and the town of High Wycombe, key historical United States and British military names—together
                            with
                            select names
                            of the teaching faculty associated with the LCHS school—have been incorporated into the street naming for a new British residential housing development
                            now
                            emerging on
                            the site.
                        </p>
                        <p>
                            Taylor Wimpey, the developer of the former High Wycombe Air Station USAF/RAF Daws Hill site located 32 miles to the west of London and bordering the M40
                            motorway,
                            submitted and has had approved 17 street names, one building name and one park name—all of which are designed to reflect the history of the area both as
                            a
                            key WWII and Cold War base and reflect the presence of London Central High School. These will complement the Penn Window in High Wycombe Parish Church
                            in
                            the center of
                            the town sponsored by the pupils of LCHS.
                        </p>

                        <p>
                            Taylor Wimpey had already completed the first tranche of approximately 50 of the 444 homes that will cover much of the 60-acre base site, which was
                            established as
                            the Headquarters for the US Army Air Force 8th Air Force Bomber Command in April 1942, and went on to become an important Cold War base in the 1950s
                            right
                            through
                            until the mid-1990s. The base was handed over to US Navy in the mid-1990s as a support base for the US Navy Headquarters on Grosvenor Square in London
                            but
                            was closed
                            in 2007 with the general drawdown of the US military presence in the UK.
                        </p>
                        <p>
                            During WWII, the base occupied the entire hillside to the south of the town including a famous girls school—Wycombe Abbey. A secret underground bunker,
                            codenamed "Pinetree", was where USAAF WWII bombing missions over Europe were planned. The bunker remains in place today, though it is privately owned
                            after
                            the land
                            on which it was built was returned to Wycombe Abbey school which had owned it prior to WWII. The bunker has been recognized as an important historical
                            structure
                            and is protected by being designated as a listed building. In latter years, the base, which also had two housing areas, was solely accessed from Daws
                            Hill
                            Lane.
                        </p>
                        <p>
                            Additional new names are related to the educators who taught at the school and were suggested following an effort to commemorate the site led by former
                            student
                            Tamara Palmer, who attended London Central High School in 1971 when it first opened at the High Wycombe location. The school had previously operated at
                            the
                            aforementioned AF Station Bushy Park (1951-1962), then Bushey Hall in Watford (1962-1971) before the final relocation to High Wycombe.
                        </p>
                        <p>
                            A proposed set of names was shortlisted and submitted by Taylor Wimpey to High Wycombe's planning team for approval.
                        </p>
                        <p>
                            Though she was only at LCHS for one year, Tamara Palmer, who lives in Atlanta, Georgia, had lasting memories of her school and managed to contact High
                            Wycombe Councillor Lesley Clarke OBE who put her in touch with the relevant developer and town officials.
                        </p>
                        <p>
                            <b>Comments Palmer:</b><br/>

                            "I'm delighted to see that the development is recognizing the legacy of the site through its road-naming program. The base had a distinguished military
                            history
                            stretching back to WWII, but additionally, thousands of American youngsters attended school and lived on the campus for over 35 years in what was a
                            unique educational institution with some remarkable educators. We only wish we could honor many more of them to create a place that has a significant
                            heritage.
                            Not only was the road-naming process about recognizing the educators, but also our way of leaving our legacy forever tied to the community of High
                            Wycombe
                            and England. It is our way of saying 'Thank You' to them and to the little piece of England that was to us a remarkable experience. We cherished being
                            there
                            and have fond memories of what will now be the future homes for so many people."
                        </p>
                        <p>
                            <b>Comments Cllr Clarke OBE:</b><br/>
                            "It has been an honour to have been involved with assisting our American friends and Allies with maintaining their links with the Daws Hill base. I am
                            particularly
                            pleased we have been able to acknowledge those people who were influential during their time on the base. We do so need to ensure we observe the very
                            special
                            historical links this base serves to everyone."
                        </p>
                    </Accordion.Content>


                    <Accordion.Title
                        active={activeIndex === 2}
                        index={2}
                        onClick={this.handleClick}
                        style={{color: "#555555"}}
                    >
                        <Icon name='dropdown'/>
                        Road names
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                        <p>The new names of streets for the "Pine Trees" development being delivered by Taylor Wimpey include:</p>

                        <ol>
                            <li>KENNEDY AVENUE</li>
                            <li>ARIZONA WAY</li>
                            <li>CALIFORNIA WAY</li>
                            <li>UTAH WAY</li>
                            <li>LEIGH PLACE</li>
                            <li>DEYOUNG WAY</li>
                            <li>EAKER STREET</li>
                            <li>DOOLITTLE AVENUE</li>
                            <li>ROBERTSON PLACE</li>
                            <li>MANSFIELD CLOSE</li>
                            <li>TRINITY CIRCLE</li>
                            <li>KELLY ROAD</li>
                            <li>THRELKELD CLOSE</li>
                            <li>KILTY PLACE</li>
                            <li>WERNETTE WAY</li>
                            <li>EISENHOWER LOWER CLOSE</li>
                            <li>BEECH STREET</li>
                            <li>BOBCAT PARK</li>
                        </ol>
                    </Accordion.Content>

                    <Accordion.Title
                        active={activeIndex === 3}
                        index={3}
                        onClick={this.handleClick}
                        style={{color: "#555555"}}
                    >
                        <Icon name='dropdown'/>
                        Estate
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 3}>
                        <br/>
                        <PineTrees/>
                    </Accordion.Content>

                    <Accordion.Title
                        active={activeIndex === 4}
                        index={4}
                        onClick={this.handleClick}
                        style={{color: "#555555"}}
                    >
                        <Icon name='dropdown'/>
                        Origin of road names
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 4}>
                        <KennedyAvenue/>
                        <ArizonaWay/>
                        <CaliforniaWay/>
                        <UtahWay/>
                        <LeighPlace/>
                        <DeyoungWay/>
                        <EakerStreet/>
                        <DoolittleAvenue/>
                        <RobertsonPlace/>
                        <MansfieldTrinity/>
                        <KellyRoad/>
                        <ThrelkeldClose/>
                        <KiltyPlace/>
                        <WernetteWay/>
                        <EisenhowerClose/>
                        <BeechStreet/>
                        <Additional/>
                    </Accordion.Content>
                </Accordion>
            </Container>
        )
    }
}