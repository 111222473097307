import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import ContactForm from "../layouts/contact/contactform";
import GDPR from "../layouts/contact/gdpr";

class Contact extends Component {
    render() {
        return (
            <div>
                <Container text style={{textAlign: "center"}}>
                    <Header as="h1">
                        Pine Trees Residents Association
                    </Header>
                    <Header as="h2">
                        <Icon name="user" color="blue" size="large"/>
                        Contact Us
                    </Header>
                </Container>
                <ContactForm topic={this.props.location.search}/>
                <GDPR/>
            </div>
        )
    }
}

export default Contact