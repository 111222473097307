import React, {Component} from "react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Confirm from "semantic-ui-react/dist/commonjs/addons/Confirm";

const statusOptions = [
    {key: "pending", value: "pending", text: "Pending"},
    {key: "user_deleted", value: "user_deleted", text: "User Deleted"},
    {key: "request_approved", value: "request_approved", text: "Request Approved"},
    {key: "request_paid", value: "request_paid", text: "Request Paid"},
]

class BookingStatus extends Component {
    state = {
        confirmOpen: false,
        oldValue: this.props.booking.status,
        event: null,
        target: null
    }

    open = (e, t) => this.setState({confirmOpen: true, event: e, target: t});
    close = () => this.setState({confirmOpen: false, event: null, target: null});
    confirm = () => {
        this.props.update(this.state.event, this.state.target);
        this.close();
    }

    render() {
        return (
            <>
                <Dropdown
                    name={"status"}
                    options={statusOptions}
                    onChange={this.open}
                    defaultValue={this.state.oldValue}
                />
                <Confirm
                    open={this.state.confirmOpen}
                    onCancel={this.close}
                    onConfirm={this.confirm}
                />
            </>
        )
    }
}

export default BookingStatus