import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";

import ToDoAddForm from "../layouts/todo/to_do_add_form";
import ToDoList from "../layouts/todo/to_do_list";
import standardFormData from "../components/standard_form_data";
import ToDoFilter from "../layouts/todo/to_do_filter";
import POC from "../components/poc";

const API_FETCH_TODO = "https://www.pinetreesra.co.uk/api/todo/fetchToDo";

const activeStateOptions = [
    {key: "pending", value: "Pending", text: "Pending", filterChecked: true},
    {key: "in_progress", value: "In Progress", text: "In Progress", filterChecked: true},
    {key: "wont_action", value: "Wont Action", text: "Wont action", filterChecked: false},
    {key: "complete", value: "Complete", text: "Complete", filterChecked: false}
]

class ToDo extends Component {

    constructor(props) {
        super(props);
        this.fetchToDo = this.fetchToDo.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.fetchToDo();
    }

    state = {
        todoList: [],
        filter: activeStateOptions,
        filterAll: false
    }

    fetchToDo() {
        if (!this.props.loggedIn) {
            return;
        }

        if (this.state.todoList.length > 0) {
            this.setState({todoList: []});
        }

        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("filter", JSON.stringify(this.state.filter));

        fetch(API_FETCH_TODO, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            this.setState({error: data.error, todoList: data.todoList});
        }));
    }

    toggleAll(e, v) {
        let newValue = (v.checked === true);
        let frozenOptions = this.state.filter;
        Object.freeze(frozenOptions);
        let newOptions = frozenOptions;
        newOptions.map((optionObj, i) =>
            newOptions[i].filterChecked = newValue
        );
        this.setState({filter: newOptions, filterAll: newValue}, () => {
            this.fetchToDo();
        });
    }

    filterChange(e, v, index) {
        let newOptions = this.state.filter;
        newOptions[index].filterChecked = v.checked;
        this.setState({filter: newOptions, filterAll: false}, () => {
            this.fetchToDo();
        });
    }

    render() {
        return (
            <Container>
                <POC />
                <Container className={"full"}>
                    <Header as="h1">PTRA Committee ToDo List</Header>
                    <p>
                        This page is to allow you to list any topics you would like the Committee to tackle.
                    </p>

                    <p>
                        Just a few points to consider...
                    </p>
                    <ul>
                        <li>The committee is made up of volunteers with limited resources and powers so please be real with your expectations.</li>
                        <li>When adding topics please check in the current list to ensure that you are not duplicating the request.</li>
                        <li>Topics older than 10 days will be reviewed at the next available committee meeting and prioritised according to the work in hand and the number of "up"
                            votes it has received.
                        </li>
                        <li>The committee will publish the decision whether to proceed which can be seen on this page.</li>
                        <li>As soon as the topic is completed we will inform the proposer by email as to the outcome.</li>
                    </ul>
                </Container>
                <ToDoAddForm loggedInUser={this.props.loggedInUser} fetch={this.fetchToDo}/>
                <ToDoFilter filter={this.state.filter} filterChange={this.filterChange} toggleAll={this.toggleAll} filterAll={this.state.filterAll}/>
                <ToDoList loggedInUser={this.props.loggedInUser} todoList={this.state.todoList} fetch={this.fetchToDo} isAdmin={this.props.isAdmin}/>
            </Container>
        )
    }
}

export default ToDo