import React from "react";
import Dropzone from 'react-dropzone';
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import List from "semantic-ui-react/dist/commonjs/elements/List";
import standardFormData from "./standard_form_data";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";

const API_PATH_UPLOAD = "https://www.pinetreesra.co.uk/api/upload";
const API_PATH_DELETE = "https://www.pinetreesra.co.uk/api/upload/delete";
const API_PATH_FETCH = "https://www.pinetreesra.co.uk/api/upload/listFiles";
const BASE_URL = "https://www.pinetreesra.co.uk/uploaded";

class DropzoneComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.fileInputCallback = this.fileInputCallback.bind(this);
        this.fileDelete = this.fileDelete.bind(this);
        this.fetchFiles = this.fetchFiles.bind(this);
        this.fetchFiles();
    }

    state = {
        files: []
    }

    formData() {
        const formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token)
        formData.append("additionalPath", this.props.additionalPath || "");
        return formData;
    }

    fetchFiles() {
        fetch(API_PATH_FETCH, {
            method: 'POST',
            body: this.formData(),
        }).then(response => response.json().then(data => {
            this.setState({files: data});
        }));
    }

    fileInputCallback(acceptedFiles) {
        acceptedFiles.forEach(file => {
            const formData = this.formData();
            formData.append("file", file);

            fetch(API_PATH_UPLOAD, {
                method: 'POST',
                body: formData,
            }).then(response => response.json().then(data => {
                if (data.ok) {
                    this.fetchFiles();
                }
            }));
        });
    }

    fileDelete(fileName) {
        const formData = this.formData();
        formData.append("file_name", fileName);

        fetch(API_PATH_DELETE, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            if (data.ok) {
                this.fetchFiles();
            }
        }));
    }

    render() {
        return (
            <div>
                <Dropzone onDrop={this.fileInputCallback}>
                    {({getRootProps, getInputProps}) => (
                        <div className="ui center aligned tertiary blue inverted segment" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Icon name="cloud upload" size="big"/>
                            <div style={{cursor: "pointer"}}>Drag and drop or CLICK to upload files</div>
                        </div>
                    )}
                </Dropzone>
                <List divided verticalAlign="middle">
                    {this.state.files.length > 0 &&
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>File</Table.HeaderCell>
                                <Table.HeaderCell>File name</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.files !== undefined && 
                            this.state.files.map((file, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell>
                                        {file.icon.name !== undefined && file.icon.name.length > 0 ?
                                            <Icon name={file.icon.name} size={"big"} color={file.icon.color}/>
                                            :
                                            <Image size="medium" bordered rounded src={BASE_URL + file.file_name}/>
                                        }
                                    </Table.Cell>
                                    <Table.Cell>
                                        <a href={BASE_URL + file.file_name}>{/[^/]*$/.exec(file.file_name)[0]}</a>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon link color="red" name="remove" onClick={e => this.fileDelete(file.file_name)}/>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                    }
                </List>
            </div>
        )
    }
}

export default DropzoneComponent