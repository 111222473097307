import React from "react";
import Roads from "./road";
import threlkeld from "../../assets/img/roads/threlkeld.png"

const ThrelkeldClose = () => (
    <Roads
        img={threlkeld}
        roadName="Threlkeld Close"
        namedAfter="Wallace and Rosemary Threlkeld (née Hynes), teachers at the school"
        text={"Wallace and Rosemary met and married in 1957 in London while teaching at Bushy Park. Rose Threlkeld, as she was known, taught English and a very popular " +
        "Shakespeare course in the school for 32 years. She passed away in 1986 in England and her ashes lie under a willow tree behind the Shakespeare Memorial " +
        "Theater in Stratford-Upon-Avon. Wallace Threlkeld, who conducted Band classes and taught Music and Guitar at LCHS for 29 years, passed away " +
        "in June 2004 in Minneapolis, Minnesota."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default ThrelkeldClose