import React, {Component} from "react";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

class UsefulCategory extends Component {
    render() {
        return (
            <div>
                {this.props.isAdmin && !this.props.preview &&
                <div style={{margin: "14px 0"}}>
                    <Input
                        name="category"
                        defaultValue={this.props.usefulItem.category}
                        style={{width: "90%"}}
                        onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                        placeholder="category"
                    />
                </div>
                }
            </div>
        )
    }

}

export default UsefulCategory