import React from "react";
import HeadImage from "../layouts/homepage/headimage";
import HomePageHead from "../layouts/homepage/homepagehead";
import Intro from "../layouts/homepage/intro";
import Logo from "../layouts/homepage/logo";

const Home = () => (
    <div style={{position: "relative"}}>
        <div style={{background: "white", position: "absolute", top:"25px", left:"35%", padding:"10px", borderRadius: "5px", opacity: "0.5", width:"400px", height:"140px"}}>&nbsp;</div>
        <Logo/>
        <HomePageHead/>
        <HeadImage/>
        <Intro/>
    </div>
)

export default Home