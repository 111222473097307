import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import moment from "moment";
import Wysiwyg from "../../components/wysiwyg";

class EventAdvertDisplay extends Component {
    render() {
        return (
            <>
                <Header content={this.props.event.event_title}/>
                <p>{moment(this.props.event.event_dt).format("DD-MM-YYYY @ HH:mm")}</p>

                {this.props.event.event_location !== null && this.props.event.event_location.length > 0 &&
                <p>LOCATION: {this.props.event.event_location}</p>
                }

                <Wysiwyg
                    editorContent={this.props.event.event_details}
                    field="event_details"
                    readOnly={true}
                    preview={true}
                    style={{clear: "both"}}
                />
            </>
        );
    }
}

export default EventAdvertDisplay