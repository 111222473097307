import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Wysiwyg from "../../components/wysiwyg";
import standardFormData from "../../components/standard_form_data";

const API_ADD_TODO = "https://www.pinetreesra.co.uk/api/todo/addToDo"

const minHeaderLength = 5;
const minDetailsLength = (132 + 5);

class ToDoAddForm extends Component {
    state = {
        todoHeading: "",
        details: "",
        headingError: false,
        detailsError: false
    }

    addNew = () => {
        this.setState({headingError: (this.state.todoHeading.length < minHeaderLength), detailsError: (this.state.details.length < minDetailsLength)}, () => {
            if (!this.state.detailsError && !this.state.headingError) {
                let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
                formData.append("todo_heading", this.state.todoHeading);
                formData.append("details", this.state.details);
                fetch(API_ADD_TODO, {
                    method: 'POST',
                    body: formData,
                }).then(response => response.json().then(data => {
                    if (data.ok) {
                        document.location.href = document.location.href.split("?")[0] + "?deepLink=" + data.newId;
                    }
                }));
            }
        });
    }

    updateHeading(e, obj) {
        this.setState({todoHeading: obj.value, headingError: (obj.value.length < minHeaderLength)});
    }

    render() {
        return (
            <Segment color="green" style={{margin: "0 10px", background: "#e9f5e8"}}>
                <Header content="Add new topic"/>
                <Form style={{background: "white", borderRadius: "5px", padding: "10px"}}>
                    <Form.Field required>
                        <label>Heading</label>
                        <Input placeholder="Topic heading" onChange={this.updateHeading.bind(this)} className={this.state.headingError ? "input-error" : ""}/>
                    </Form.Field>
                    <Form.Field required>
                        <label>Details</label>
                        <Wysiwyg
                            className={this.state.detailsError ? "input-error" : ""}
                            editorContent={this.state.details}
                            toolbarHidden={true}
                            saveField={(e, obj) => {
                                this.setState({details: obj.value, detailsError: (obj.value.length < minDetailsLength)})
                            }}
                            field="details"
                            readOnly={false}
                            reset={this.state.reset}
                            style={{clear: "both"}}
                        />
                    </Form.Field>
                    <Button type='submit' onClick={this.addNew.bind(this)} disabled={this.state.detailsError || this.state.headingError} primary>Submit</Button>
                </Form>
            </Segment>
        )
    }
}

export default ToDoAddForm