import React, {Component} from "react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Wysiwyg from "../../components/wysiwyg";
import standardFormData from "../../components/standard_form_data";
import UsefulCategory from "./useful_category";
import UsefulTitle from "./useful_title";
import UsefulDate from "./useful_dt";
import UsefulAdminBar from "./useful_admin_bar";
import UsefulContact from "./useful_contact";
import UsefulWebsite from "./useful_website";
import UsefulSubCategory from "./useful_subcategory";

const API_UPDATE_USEFUL = "https://www.pinetreesra.co.uk/api/useful/updateUseful"


class UsefulSegment extends Component {
    constructor(props) {
        super(props);
        this.showHidePreview = this.showHidePreview.bind(this);
    }

    saveField = (e, inputData) => {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("field", inputData.name);
        formData.append("value", inputData.value);
        formData.append("usefulId", this.props.usefulItem.id);
        fetch(API_UPDATE_USEFUL, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            this.props.usefulItem[inputData.name] = inputData.value;
            this.setState({successMessage: true});
            setTimeout(function () {
                this.setState({successMessage: false});
            }.bind(this), 2000);
        }));
    }

    state = {
        successMessage: false,
        preview: (this.props.usefulItem.active_state !== "pending")
    }

    showHidePreview() {
        this.setState({preview: !this.state.preview});
    }

    render() {
        return (
            <div>
                <UsefulCategory isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} usefulItem={this.props.usefulItem}
                                previousCategory={this.props.previousCategory} safeLink={this.props.safeLink}/>
                <UsefulSubCategory isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} usefulItem={this.props.usefulItem}
                                   previousSubCategory={this.props.previousSubCategory} safeLink={this.props.safeLink}/>
                <Segment style={{margin: "0 0 20px 20px"}} className={this.props.highlightIndex === this.props.usefulItem.id ? "deepLink" : ""}
                         id={"id" + this.props.usefulItem.id}>
                    <UsefulTitle isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} usefulItem={this.props.usefulItem}/>
                    <UsefulContact isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} usefulItem={this.props.usefulItem}/>
                    <UsefulWebsite isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} usefulItem={this.props.usefulItem}/>
                    <Wysiwyg
                        editorContent={this.props.usefulItem.description}
                        field="description"
                        saveField={this.saveField}
                        readOnly={!this.props.isAdmin}
                        preview={this.state.preview}
                        showHidePreview={this.showHidePreview}
                        style={{clear: "both"}}
                    />
                    <UsefulDate date={this.props.usefulItem.dt} addedBy={this.props.usefulItem.fname + " " + this.props.usefulItem.lname}/>
                    <UsefulAdminBar isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} usefulItem={this.props.usefulItem}
                                    showHidePreview={this.showHidePreview} successMessage={this.state.successMessage}/>
                </Segment>
            </div>
        )
    }
}

export default UsefulSegment