import React, {Component} from "react";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

class UsefulContact extends Component {
    render() {
        return (
            <div>
                {this.props.isAdmin && !this.props.preview ?
                    <div style={{margin: "14px 0"}}>
                        <Input
                            name="contact_number"
                            defaultValue={this.props.usefulItem.contact_number}
                            style={{width: "90%"}}
                            onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                            placeholder="Contact number"
                        />
                    </div>
                    :
                    <div>
                        {this.props.usefulItem.contact_number !== null && this.props.usefulItem.contact_number.length > 0 &&
                        <div style={{margin: "14px 0"}}>
                            {this.props.usefulItem.contact_number}
                        </div>
                        }
                    </div>
                }
            </div>
        )
    }

}

export default UsefulContact