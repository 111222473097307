import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import standardFormData from "../../../../components/standard_form_data";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {TableBody} from "semantic-ui-react";
import FormErrors from "../../../login/form_errors";
import EventTypeRow from "./eventTypeRow";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

const API_BOOKING_EVENT_TYPE_FETCH = "https://www.pinetreesra.co.uk/api/bookings/fetchEventTypes"
const API_BOOKING_EVENT_TYPE_ADD = "https://www.pinetreesra.co.uk/api/bookings/addEventType"

class EventTypesAdmin extends Component {
    constructor(props) {
        super(props);
        this.logErrors = this.logErrors.bind(this);
        this.fetchEventTypes = this.fetchEventTypes.bind(this);
        this.fetchEventTypes();
    }

    state = {
        eventTypes: [],
        errors: []
    }

    logErrors(errorsArr) {
        this.setState({errors: errorsArr});
    }

    fetchEventTypes() {
        fetch(API_BOOKING_EVENT_TYPE_FETCH, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            if (data.ok) {
                this.setState({eventTypes: data.eventTypes})
            } else {
                this.setState({errors: data.errors});
            }
        })).catch(error => {
            this.setState({errors: ["Issue with the API"]});
        });
    }

    addNew() {
        fetch(API_BOOKING_EVENT_TYPE_ADD, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            if (data.ok) {
                this.fetchEventTypes();
            } else {
                this.setState({errors: data.errors});
            }
        })).catch(error => {
            this.setState({errors: ["Issue with the API"]});
        });
    }

    render() {
        return (
            <Container style={{padding: "10px"}}>
                <Header content={"Event Types"}/>
                <FormErrors errors={this.state.errors}/>
                <Table>
                    <Table.Header>
                        <Table.Row key={"header_events"}>
                            <Table.HeaderCell>Type text</Table.HeaderCell>
                            <Table.HeaderCell>Type value</Table.HeaderCell>
                            <Table.HeaderCell>Type hourly</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <TableBody>
                        {this.state.eventTypes.map((eventType) =>
                            <EventTypeRow key={"event_" + eventType.id} eventType={eventType} errors={this.state.errors} loggedInUser={this.props.loggedInUser} logErrors={this.logErrors}/>)}
                    </TableBody>
                </Table>
                <Button primary content={"add new"} icon={{name: "plus"}} onClick={(e) => this.addNew(e)}/>
            </Container>
        );
    }
}

export default EventTypesAdmin