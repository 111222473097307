import React from "react";
import Roads from "./road";
import mary_deyoung from "../../assets/img/roads/deyoung.png"

const DeyoungWay = () => (
    <Roads
        img={mary_deyoung}
        roadName="DeYoung Way"
        namedAfter="Mary Pauline (Polly) DeYoung"
        text={"Mary taught at the school from 1971 to 2005. DeYoung came from Macon, Georgia, and returned to live in the United States after her retirement in 2005. " +
        "She returned to High Wycombe in 2007 to be the VIP speaker at the closing ceremony for the base. DeYoung, who taught Mathematics and Physical Education, " +
        "was also a cheerleading coach and was involved in choreographing a number of the school plays. She additionally worked with students who participated " +
        "in the popular Model United Nations programme."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default DeyoungWay