import React, {Component} from "react";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import MembersSectionHeader from "../layouts/members/members_section_header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import FormErrors from "../layouts/login/form_errors";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Dimmer, Loader} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import axios from "axios";
import "../assets/css/login.css"
import {NavLink} from "react-router-dom";

const API_VERIFY_RESET_TOKEN = "https://www.pinetreesra.co.uk/api/login/verifyResetToken";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.isFormValid = this.isFormValid.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
    }

    state = {
        token: this.verifyToken(),
        password: '',
        errors: [],
        reset: false,
        loading: false
    }

    isFormValid() {
        let validationErrors = [];
        if (this.state.password.length < 5) {
            validationErrors.push("Password must be 5 characters or longer")
        }

        this.setState({errors: validationErrors});
        return validationErrors.length === 0;
    }

    clearErrors() {
        this.setState({errors: []});
    }

    handleFormSubmit = e => {
        e.preventDefault();

        if (this.isFormValid()) {
            this.setState({loading: true});
            axios({
                method: 'post',
                url: API_VERIFY_RESET_TOKEN,
                headers: {'content-type': 'application/json'},
                data: this.state
            })
                .then(result => {
                    console.log(result.data)
                    if (result.data.ok) {
                        this.setState({reset: true, loading: false});
                    } else {
                        this.setState({errors: [result.data.errors]})
                    }
                })
                .catch(error => {
                    this.setState({errors: [error.message]});
                });
        }
    };

    verifyToken() {
        let urlSearch = this.props.location.search;
        let token = "";
        if (urlSearch.includes("?token=")) {
            token = urlSearch.replace("?token=", "");
        }
        return token;
    }

    render() {
        return (
            <div>
                <MembersSectionHeader/>
                {!this.state.token.length === 0 &&
                <Message color="red" content="Invalid token. Either expired or used. Please try again" style={{margin: "0 20px", textAlign: "center"}}/>
                }

                {this.state.reset &&
                <div style={{margin: "0 20px", textAlign: "center"}}>
                    <Message color="green" content="Success. You should now be able to login with your new password"/>
                    <Button as={NavLink} to="/members" color="green">
                        <Icon name="signup" size="large"/>
                        Login
                    </Button>
                </div>
                }

                {(this.state.token.length > 0 && !this.state.reset) &&
                <div className="forgot-password">
                    <div className="forms-container">
                        <form action="#" className="sign-in-form" id="forgot_password">
                            <h2 className="title">Reset Password</h2><br/>
                            <div className="input-field">
                                <Icon name="envelope" size="large" color="green"/>
                                <input placeholder='new password' name="password" onChange={(e) => {
                                    this.setState({password: e.target.value});
                                }} data-lpignore="true" onFocus={this.clearErrors}/>
                            </div>
                            {this.state.errors.length > 0 ?
                                <FormErrors errors={this.state.errors}/>
                                :
                                <>
                                    {this.state.loading ?
                                        <Segment>
                                            <Dimmer active style={{height: "50px"}}>
                                                <Loader/>
                                            </Dimmer>
                                        </Segment>
                                        :
                                        <Button className="btn" type="submit" content="Submit" primary={(this.state.password.length > 0)}
                                                onClick={e => this.handleFormSubmit(e)}/>
                                    }
                                </>
                            }
                        </form>
                    </div>
                </div>
                }
            </div>
        );
    }

}

export default ResetPassword