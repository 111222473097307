import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import NewsSegment from "./news_segment";
import standardFormData from "../../components/standard_form_data";
import NewsFilter from "./news_filter";

const API_FETCH_NEWS = "https://www.pinetreesra.co.uk/api/news/fetchNews"
const API_ADD_NEWS = "https://www.pinetreesra.co.uk/api/news/addNews"

const newsTypeOptions = [
    {key: "generic", value: "generic", text: "General"},
    {key: "neighbourhood_watch", value: "neighbourhood_watch", text: "Neighbourhood Watch"},
    {key: "park", value: "park", text: "Park"},
    {key: "snagging", value: "snagging", text: "Snagging"},
]

class NewsItems extends Component {
    constructor(props) {
        super(props);
        this.fetchNews();
        this.fetchNews = this.fetchNews.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
    }

    fetchNews() {
        let url = API_FETCH_NEWS;
        if (this.props.isAdmin) {
            url = url + "/admin"
        }
        fetch(url, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({error: data.error, newsList: data.newsList});
        }));
    }

    addNew() {
        fetch(API_ADD_NEWS, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({newsList: []});
            this.fetchNews();
        }));
    }

    state = {
        newsList: [],
        error: null,
        filter: ""
    }

    updateFilter(newsType) {
        this.setState({filter: newsType});
    }

    render() {
        return (
            <Container style={{padding: "0 10px"}}>
                <Message icon={{name: "warning sign", size: "large", color: "green"}} color="yellow"
                         content="This page is for members only. Please do not share information found here outside of the estate."/>

                {this.state.error !== undefined && this.state.error !== null &&
                <Message error>
                    <Icon name="lock" color="red" size="large"/>
                    Sorry but you are not authorised to view this page
                </Message>
                }

                {this.props.isAdmin &&
                <div style={{marginTop: "20px"}}>
                    <Button primary content={"add new"} icon={{name: "plus"}} onClick={(e) => this.addNew(e)}/>
                </div>
                }

                <NewsFilter newsList={this.state.newsList} filter={this.state.filter} newsTypeOptions={newsTypeOptions} updateFilter={this.updateFilter}/>

                {this.state.newsList !== undefined && this.state.newsList.length > 0 &&
                <div>
                    {this.state.newsList.length > 0 &&
                    this.state.newsList.filter(function (newsObj) {
                        if (this.state.filter.length === 0) {
                            return true;
                        }
                        return newsObj.news_type === this.state.filter;
                    }.bind(this)).map((newsObj, i) =>
                        <NewsSegment newsItem={newsObj} key={newsObj.id} loggedInUser={this.props.loggedInUser} isAdmin={this.props.isAdmin} isFirst={i === 0}
                                     newsTypeOptions={newsTypeOptions}/>
                    )}
                </div>
                }
            </Container>
        )
    }
}

export default NewsItems