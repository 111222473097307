import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import standardFormData from "../../components/standard_form_data";
import {Link} from "react-router-dom";

const API_USEFUL_PENDING = "https://www.pinetreesra.co.uk/api/useful/pending"

class AdminPendingUseful extends Component {
    constructor(props) {
        super(props);
        this.fetchPending = this.fetchPending.bind(this);
        this.fetchPending();
    }

    state = {
        pending: []
    }

    fetchPending() {
        fetch(API_USEFUL_PENDING, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({pending: data.results});
        }));
    }

    render() {
        return (
            <>
                {(this.props.loggedIn && this.props.isAdmin) &&
                <Container style={{padding: "0 10px"}}>
                    <Header as="h2" icon={{name: "wrench", color: "orange"}} content={"Pending Useful Contacts for approval"}/>
                    {this.state.pending.length === 0 &&
                    <Message color="green">
                        <Icon name="checkmark" color="green"/>
                        Your work here is done
                    </Message>
                    }
                    <ol>
                        {this.state.pending.map((row, i) =>
                            <li><Link to={"/usefulinfo?id=" + row.id} key={row.id}>{row.useful_title}</Link></li>
                        )
                        }
                    < /ol>
                    <hr/>
                </Container>
                }
            </>
        );
    }

}

export default AdminPendingUseful