import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import axios from "axios";
import FormErrors from "./form_errors";
import SocialMediaButtons from "./social_media_buttons";

const API_PATH_SIGNUP = 'https://www.pinetreesra.co.uk/api/login/signup';

class SignupForm extends Component {
    constructor(props) {
        super(props);
        this.checkForm = this.checkForm.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
    }

    state = {
        givenName: '',
        familyName: '',
        email: '',
        password: '',
        houseNumber: '',
        street: '',
        postcode: '',
        errors: this.props.errors
    }

    selectStreet = (e) => {
        e.target.classList.remove("selected");
        if (e.target.value.length > 0) {
            e.target.classList.add("selected");
        }
        this.setState({street: e.target.value});
    }

    formatPostCode = (e) => {
        let pc = e.target.value;
        if (pc.length > 0) {
            pc = pc.toUpperCase();
            if (!pc.includes(" ")) {
                pc = pc.replace("HP11", "HP11 ");
            }
        }
        this.setState({postcode: pc});
        e.target.value = pc;
        this.checkForm();
    }

    isFormValid() {
        let validationErrors = [];
        if (this.state.givenName.length < 2) {
            validationErrors.push("First name is required");
        }
        if (this.state.familyName.length < 2) {
            validationErrors.push("Last name is required");
        }
        if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.state.email)) {
            validationErrors.push("Valid email is required");
        }
        if (this.state.password.length < 5) {
            validationErrors.push("Password must be 5 characters or longer")
        }
        if (this.state.houseNumber.length < 1) {
            validationErrors.push("House number is required");
        }
        if (this.state.street.length === 0) {
            validationErrors.push("Street must be selected");
        }
        if (this.state.postcode.length < 7) {
            validationErrors.push("Postcode doesn't appear to be valid");
        } else {
            if (!this.state.postcode.startsWith("HP11")) {
                validationErrors.push("Postcode doesn't appear to be in PineTrees");
            }
        }

        this.setState({errors: validationErrors});
        return validationErrors.length === 0;
    }

    clearErrors() {
        this.setState({errors: []});
    }

    handleFormSubmit = e => {
        e.preventDefault();

        if (this.isFormValid()) {
            axios({
                method: 'post',
                url: `${API_PATH_SIGNUP}`,
                headers: {'content-type': 'application/json'},
                data: this.state
            })
                .then(result => {
                    this.props.handleLogin(result.data);
                    if (result.data.error !== undefined) {
                        this.setState({errors: [result.data.error]});
                    }
                })
                .catch(error => this.setState({errors: [[error.message]]}));
        }
    }

    checkForm() {
        if (this.state.errors !== null && this.state.errors.length > 0) {
            this.isFormValid();
        }
    }

    render() {
        return (
            <form action="#" className="sign-up-form" id="sign-up-form">
                <h2 className="title">Sign-up</h2>
                <div className="input-field">
                    <Icon name="user" size="large" color="green"/>
                    <input type="text" placeholder="First name" data-lpignore="true" onChange={(e) => {
                        this.setState({givenName: e.target.value});
                    }} onBlur={this.checkForm} onFocus={this.clearErrors}/>
                </div>
                <div className="input-field">
                    <Icon name="user" size="large" color="green"/>
                    <input type="text" placeholder="Last name" data-lpignore="true" onChange={(e) => {
                        this.setState({familyName: e.target.value});
                    }} onBlur={this.checkForm} onFocus={this.clearErrors}/>
                </div>
                <div className="input-field address">
                    <Icon name="address card" size="large" color="green"/>
                    <input type="text" placeholder="House number" data-lpignore="true" onChange={(e) => {
                        this.setState({houseNumber: e.target.value});
                    }} onBlur={this.checkForm} onFocus={this.clearErrors}/><br/>
                    <select name="street" placeholder="Street" onChange={this.selectStreet} onBlur={this.checkForm} onFocus={this.clearErrors}>
                        <option value="">Street</option>
                        <option value="Arizona Way">Arizona Way</option>
                        <option value="Beech Street">Beech Street</option>
                        <option value="California Way">California Way</option>
                        <option value="DeYoung Way">DeYoung Way</option>
                        <option value="Doolittle Avenue">Doolittle Avenue</option>
                        <option value="Eaker Street">Eaker Street</option>
                        <option value="Eisenhower Lower Close">Eisenhower Lower Close</option>
                        <option value="Kelly Road">Kelly Road</option>
                        <option value="Kennedy Avenue">Kennedy Avenue</option>
                        <option value="Kilty Place">Kilty Place</option>
                        <option value="Leigh Place">Leigh Place</option>
                        <option value="Mansfield Close">Mansfield Close</option>
                        <option value="Robertson Place">Robertson Place</option>
                        <option value="Threlkeld Close">Threlkeld Close</option>
                        <option value="Trinity Circle">Trinity Circle</option>
                        <option value="Utah Way">Utah Way</option>
                        <option value="Wernette Way">Wernette Way</option>
                    </select><br/>
                    <input type="text" placeholder="Postcode" data-lpignore="true" onBlur={this.formatPostCode} onFocus={this.clearErrors}/>
                </div>
                <div className="input-field">
                    <Icon name="envelope" size="large" color="green"/>
                    <input type="email" placeholder="Email" data-lpignore="true" onChange={(e) => {
                        this.setState({email: e.target.value});
                    }} onBlur={this.checkForm} onFocus={this.clearErrors}/>
                </div>
                <div className="input-field">
                    <Icon name="lock" size="large" color="green"/>
                    <input type="password" placeholder="Password" data-lpignore="true" onChange={(e) => {
                        this.setState({password: e.target.value});
                    }} onBlur={this.checkForm} onFocus={this.clearErrors}/>
                </div>

                {this.state.errors.length > 0 ?
                    <FormErrors errors={this.state.errors}/>
                    :
                    <Button className="btn" type="submit" content="Sign-up"
                            primary={(this.state.errors.length === 0 && this.state.givenName.length > 1)}
                            onClick={e => this.handleFormSubmit(e)}/>
                }


                <p className="social-text">or</p>
                <SocialMediaButtons handleLogin={this.props.handleLogin} buttonText="Sign-up" asSignup={true} errors={this.props.errors}/>
            </form>
        )
    }

}

export default SignupForm