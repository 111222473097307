import React, {Component} from "react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import {NavLink} from "react-router-dom";
import Logout from "../common/googlelogout";

class MenuMobile extends Component {
    state = {
        activeItem: "Home"
    }

    handleItemClick = (e, {name}) => {
        this.setState({activeItem: name})
    }

    render() {
        return (
            <Menu style={{borderRadius: 0, display: 'none', margin: '0'}} inverted className={"nav-small"}>
                <Dropdown item icon={{color: "orange", name: "list"}} text={this.state.activeItem}>
                    <Dropdown.Menu style={{background: '#1b1c1d'}}>
                        {this.props.menuItems.left.map((item, i) => (
                            <NavLink key={"sm" + i} to={item.to}><Dropdown.Item name={item.name} onClick={this.handleItemClick}>{item.name}</Dropdown.Item></NavLink>
                        ))}

                        {this.props.loggedIn && this.props.menuItems.right.map((item, i) => (
                            (item.newFeature === undefined || (item.newFeature && this.props.showNewFeature)) && this.props.approved &&
                            <NavLink key={("smr" + i)} to={item.to}><Dropdown.Item name={item.name} onClick={this.handleItemClick}>{item.name}</Dropdown.Item></NavLink>
                        ))}

                        {this.props.loggedIn &&
                        <Dropdown.Item className="logout">
                            <Logout handleLogin={this.props.handleLogin} loggedInUser={this.props.loggedInUser}/>
                        </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </Menu>
        );
    }
}

export default MenuMobile