import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import DropzoneComponent from "../../components/dropzone";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

class AdminUpload extends Component {
    state = {
        show: false
    }

    render() {
        return (
            <>
                {(this.props.loggedIn && this.props.isAdmin) &&
                <Container style={{padding: "0 10px"}}>
                    <Header as="h2">
                        <Icon name={"file"} color={"pink"} style={{display: "inline-block"}} />
                        Miscellaneous files uploaded by you
                        <Button size={"tiny"} floated={"right"} content={"show/hide"}  onClick={() => this.setState({show: !this.state.show})} />
                    </Header>
                    {this.state.show &&
                    <DropzoneComponent loggedInUser={this.props.loggedInUser} additionalPath="/files"/>
                    }
                    <hr/>
                </Container>
                }
            </>
        );
    }
}

export default AdminUpload