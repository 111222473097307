import React, {Component} from 'react'
import {Button, Form, Icon, Message, Modal} from 'semantic-ui-react'
import SAUT from "../../components/saut";
import standardFormData from "../../components/standard_form_data";

const API_ADD_USEFUL = "https://www.pinetreesra.co.uk/api/useful/addUseful"

const initialState = {
    open: false,
    category: "",
    subCategory: "",
    title: "",
    phone: "",
    website: "",
    description: "",
    message: "",
    error: []
}

class AddUsefulForm extends Component {
    constructor(props) {
        super(props);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.fetchCategories = this.fetchCategories.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    state = initialState;

    handleUpdate(field, value) {
        this.setState({[field]: value})
    }

    fetchSubCategories() {
        return this.props.subCategories(this.state.category).map((row) => ({title: row }));
    }

    fetchCategories() {
        return this.props.categories.map((row)=>({title: row}));
    }

    formIsValid() {
        let formErrors = [];
        if (this.state.category.length < 2) {
            formErrors.push("The main category cannot be blank")
        }
        if (this.state.title.length < 2) {
            formErrors.push("The title cannot be blank")
        }
        if (this.state.phone < 2 && this.state.website < 2) {
            formErrors.push("The phone number or website must be completed")
        }
        if (this.state.website.length > 0 && !this.state.website.startsWith("https://")) {
            formErrors.push("The website link must start with https://")
        }

        this.setState({error: formErrors})
        return formErrors.length === 0;
    }

    submitForm(e) {
        e.preventDefault();

        if (this.formIsValid()) {
            let data = standardFormData(this.props.props.loggedInUser.id, this.props.props.loggedInUser.token);
            data.append("category", this.state.category);
            data.append("sub_category", this.state.subCategory);
            data.append("title", this.state.title);
            data.append("phone", this.state.phone);
            data.append("website", this.state.website);
            data.append("description", this.state.description);
            fetch(API_ADD_USEFUL, {
                method: 'POST',
                body: data,
            }).then(response => response.json().then(data => {
                if (data.ok) {
                    this.setState(initialState);
                    this.setState({message: "Thank you for submitting this information. It will be reviewed in the next few days"});
                    setTimeout(function () {
                        this.setState({message: ""});
                        if (this.props.props.isAdmin) {
                            window.location.reload();
                        }
                    }.bind(this), (this.props.props.isAdmin ? 2000 : 4000));
                } else {
                    this.setState({error: ["Sorry, there was an error. Please try again"]});
                    setTimeout(function () {
                        this.setState({error: []});
                    }.bind(this), 4000);
                }
            }));
        }
    }

    render() {
        return (
            <div>
                <Button primary onClick={() => this.setState({open: true})} style={{position: "relative", display: "inline-block"}}>
                    <Icon name="plus"/>
                    suggest new
                    <div style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-10px",
                        color: "white",
                        background: "deeppink",
                        borderRadius: "3px",
                        padding: "3px",
                        transform: "rotate(15deg)",
                        fontSize: "70%"
                    }}>NEW</div>
                </Button>

                {this.state.message.length > 0 &&
                <Message color="green" content={this.state.message} style={{marginBottom: "20px"}}/>
                }

                <Modal dimmer="blurring" open={this.state.open} onClose={() => this.setState({open: false})} closeIcon>
                    <Modal.Header>Suggest a new Useful contact</Modal.Header>
                    <Modal.Content>
                        <Message info>
                            <Icon name="pointing right" size="big" style={{float: "left"}}/>
                            This form will allow you to submit a useful number for listing on the Pine Trees Residents Association Useful contacts page. Any submitted details are
                            subject to review and approval by the committee.
                        </Message>

                        <Form>
                            <Form.Field>
                                <label>Main Category</label>
                                <SAUT results={this.fetchCategories()} field="category" handleUpdate={this.handleUpdate}/>
                            </Form.Field>

                            {this.state.category.length > 0 &&
                            <>
                                <Form.Field>
                                    <label>Sub Category</label>
                                    <SAUT results={this.fetchSubCategories()} field="subCategory" handleUpdate={this.handleUpdate}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Title</label>
                                    <input placeholder="title" onChange={(e) => this.handleUpdate("title", e.target.value)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Phone</label>
                                    <input placeholder="phone" onChange={(e) => this.handleUpdate("phone", e.target.value)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Website</label>
                                    <input placeholder="website" onChange={(e) => this.handleUpdate("website", e.target.value)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Description</label>
                                    <textarea placeholder="brief description" onChange={(e) => this.handleUpdate("description", e.target.value)}/>
                                </Form.Field>
                            </>
                            }
                        </Form>
                        {this.state.error.length > 0 &&
                        <Message color="red">
                            {this.state.error.map((err, i) =>
                                <div key={i}>{err}</div>
                            )}
                        </Message>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => this.setState({open: false})}>
                            Cancel
                        </Button>
                        <Button positive onClick={(e) => this.submitForm(e)}>
                            Submit
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }

}

export default AddUsefulForm
