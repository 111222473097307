import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";

class NewsFilter extends Component {
    groupBy = function () {
        return this.props.newsList.reduce(function (rv, x) {
            (rv[x["news_type"]] = rv[x["news_type"]] || []).push(x);
            return rv;
        }, {});
    };

    buttons() {
        let i = 0;
        let r = [];
        for (let key in this.groupBy()) {
            r[i] = key;
            i++;
        }
        return r;
    }

    isActive(obj) {
        return obj === this.props.filter;
    }

    render() {
        return (
            <Segment style={{margin: "20px 0", background: "#e9f5e8"}} color={"green"} className="small-grey-text">
                <span style={{marginRight: "20px"}}>Filter:</span>
                <Button primary={this.isActive("")} content="All" key={9999} onClick={() => this.props.updateFilter("")}/>
                {this.buttons().map((obj, k) =>
                    <Button primary={this.isActive(obj)} content={this.props.newsTypeOptions.find(({key}) => key === obj).text} key={k}
                            onClick={() => this.props.updateFilter(obj)}/>
                )}
            </Segment>
        );
    }
}

export default NewsFilter