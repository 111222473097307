import React, {Component} from 'react'
import {Button, Form, TextArea} from 'semantic-ui-react'
import {ReCaptcha} from "react-recaptcha-google";
import axios from "axios";

const API_PATH = 'https://www.pinetreesra.co.uk/api/api';
const init = {
    form: {
        fname: {
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        lname: {
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        email: {
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        topic: {
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        subject: {
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        message: {
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
    },
    formIsValid: false,
    formSubmitted: false
}

export default class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = init;
        if (this.props.topic !== undefined) {
            let chosenTopic = this.props.topic.replace("?topic=", "");
            this.state.form.topic = {
                value: chosenTopic,
                validation: {
                    required: true
                },
                valid: true,
                touched: true
            }
        }
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        this.setState({captchaToken: recaptchaToken});
    }

    handleFormSubmit = e => {
        e.preventDefault();
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: {'content-type': 'application/json'},
            data: this.state
        })
            .then(result => {
                this.setState({
                    mailSent: result.data.mailSent,
                    error: result.data.error,
                    formSubmitted: true
                })
            })
            .catch(error => this.setState({error: error.message}));
    };

    handleValidation = (event) => {
        let fieldName = event.target.name;
        let fieldValue = event.target.value;

        const updatedCategoryForm = {
            ...this.state.form
        };
        const updatedFormElement = {
            ...updatedCategoryForm[fieldName]
        };
        updatedFormElement.touched = true;
        updatedFormElement.value = fieldValue;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        if (!updatedFormElement.valid && updatedFormElement.validation) {
            updatedFormElement.elementValidation = "Invalid";
        } else {
            updatedFormElement.elementValidation = "";
        }
        updatedCategoryForm[fieldName] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedCategoryForm) {
            formIsValid = updatedCategoryForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({form: updatedCategoryForm, formIsValid: formIsValid});
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        return isValid;
    }

    errorsAsLi() {
        return this.state.error.map((er) => <li>{er}</li>);
    }

    render() {
        return (
            <Form style={{width: '600px', margin: 'auto'}}>
                <div>
                    {this.state.mailSent &&
                    <div style={{color: "green", border: "2px solid green", borderRadius: "5px", padding: "10px", background: "#e0ece0", marginBottom: "10px"}}>
                        Thank you for contacting us, we will respond as soon as possible.
                    </div>
                    }
                    {!this.state.mailSent && this.state.error &&
                    <div style={{color: "red", border: "2px solid red", borderRadius: "5px", padding: "10px", background: "#fff0f0", marginBottom: "10px"}}>
                        <ul>{this.errorsAsLi()}</ul>
                    </div>
                    }
                </div>
                {!this.state.formSubmitted &&
                <div>
                    <Form.Field>
                        <label>First Name</label>
                        <input placeholder='First Name' name='fname' onChange={e => this.handleValidation(e)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Last Name</label>
                        <input placeholder='Last Name' name='lname' onChange={e => this.handleValidation(e)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Your Email address</label>
                        <input placeholder='email address' name='email' onChange={e => this.handleValidation(e)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Topic</label>
                        <select name='topic' onChange={e => this.handleValidation(e)} defaultValue={this.state.form.topic.value}>
                            <option value="">please select</option>
                            <option value="general">General</option>
                            <option value="estate_snagging">Estate Snagging</option>
                            <option value="neighbourhood_watch">Neighbourhood Watch</option>
                            <option value="website">Website</option>
                        </select>
                    </Form.Field>
                    <Form.Field>
                        <label>Subject</label>
                        <input placeholder='subject' name='subject' onChange={e => this.handleValidation(e)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Message</label>
                        <TextArea placeholder='message' style={{height: '200px'}} name='message' onChange={e => this.handleValidation(e)}/>
                    </Form.Field>
                    <ReCaptcha
                        ref={(el) => {
                            this.captchaDemo = el;
                        }}
                        size="normal"
                        data-theme="dark"
                        render="explicit"
                        sitekey="6LeytZkaAAAAAJ6I2qkxCe2hbQqiw55qiEZ_JX8d"
                        onloadCallback={this.onLoadRecaptcha}
                        verifyCallback={this.verifyCallback}
                    />
                    <br/>
                    <Button type='submit' primary onClick={e => this.handleFormSubmit(e)} disabled={!this.state.formIsValid}>Submit</Button>
                </div>
                }
            </Form>
        )
    }

}