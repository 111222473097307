import React, {Component} from "react";
import Feed from "semantic-ui-react/dist/commonjs/views/Feed";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import standardFormData from "./standard_form_data";

const API_FETCH_CHAT = "https://www.pinetreesra.co.uk/api/chat/fetchChat"
const API_LIKE_CHAT = "https://www.pinetreesra.co.uk/api/chat/like"
const API_ADD_CHAT = "https://www.pinetreesra.co.uk/api/chat/addChat"

const colors = [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'teal',
    'blue',
    'violet',
    'purple',
    'pink',
    'brown',
    'grey',
    'black',
]


class Chat extends Component {
    constructor(props) {
        super(props);
        this.fetch = this.fetch.bind(this);
        this.like = this.like.bind(this);
        this.addNewPost = this.addNewPost.bind(this);
        this.fetch();
    }

    state = {
        chatType: this.props.chatType,
        chatItemId: this.props.chatItemId,
        chatList: [],
        likeError: false,
        newPost: ""
    }

    fetch() {
        if (this.state.chatList.length > 0) {
            this.setState({chatList: []});
        }

        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("chat_type", this.state.chatType);
        formData.append("chat_item_id", this.state.chatItemId);
        fetch(API_FETCH_CHAT, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            this.setState({error: data.error, chatList: data.chatList});
        }));
    }

    like(canLike, i) {
        if (canLike) {
            let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
            formData.append("chat_id", this.state.chatList[i].id);
            fetch(API_LIKE_CHAT, {
                method: 'POST',
                body: formData,
            }).then(response => response.json().then(data => {
                let newChatList = this.state.chatList;
                newChatList[i].likes = data.likes;
                newChatList[i].canLike = false;
                this.setState({chatList: newChatList});
            }));
        } else {
            let newChatList = this.state.chatList;
            newChatList[i].likeErrorMessage = "Sorry, you can't like your own post or you have already liked this one";
            this.setState({chatList: newChatList, likeError: true});
            setTimeout(function () {
                let newChatList = this.state.chatList;
                newChatList[i].likeErrorMessage = "";
                this.setState({chatList: newChatList, likeError: false});
            }.bind(this), 2000);
        }
    }

    addNewPost = () => {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("chat_details", this.state.newPost);
        formData.append("chat_type", this.state.chatType);
        formData.append("chat_item_id", this.state.chatItemId);
        fetch(API_ADD_CHAT, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            if (data.ok) {
                this.setState({newPost: ""})
                this.fetch();
            }
        }));
    }

    parseChat(chat) {
        try {
            let expression = /LINK:https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
            let matched = new RegExp(expression).exec(chat)[0].replace(")", "").replace("LINK:", "");
            return chat.replace(matched, "<a href='" + matched + "' target='_blank' rel='noreferrer nofollow'>" + matched + "</a>").replace("LINK:", "");
        } catch (e) {
            return chat;
        }
    }

    render() {
        return (
            <Feed>
                <Feed.Event key={this.state.chatList.length}>
                    <Feed.Label><Label content={this.props.loggedInUser.fname.substring(0, 1)} color={colors[this.props.loggedInUser.id % 13]} size="large"/></Feed.Label>
                    <Feed.Content>
                        <Input
                            action={{icon: "add", color: "green", onClick: this.addNewPost}}
                            placeholder="Add new chat comment"
                            fluid
                            defaultValue={this.state.newPost}
                            onChange={(e, data) => this.setState({newPost: data.value})}
                            style={{margin: "-8px 0 10px -14px"}}
                        />
                    </Feed.Content>
                </Feed.Event>

                {this.state.chatList.map((chatRow, i) =>
                    <Feed.Event key={chatRow.id}>
                        <Feed.Label><Label content={chatRow.fname.substring(0, 1)} color={colors[chatRow.user_id % 13]} size="large"/></Feed.Label>
                        <Feed.Content>
                            <Feed.Summary
                                date={chatRow.ago}
                                user={chatRow.fname + " " + chatRow.lname}
                                content='added this comment'
                                style={{marginLeft: "-15px"}}
                            />
                            <Feed.Extra>
                                {chatRow.chat_details.includes("LINK:") ?
                                    <span dangerouslySetInnerHTML={{__html: this.parseChat(chatRow.chat_details)}}/>
                                    :
                                    <span>{chatRow.chat_details}</span>
                                }
                            </Feed.Extra>
                            <Feed.Meta>
                                <Feed.Like onClick={() => this.like(chatRow.canLike, i)}>
                                    <Icon name='like'/>{chatRow.likes || 0} Likes
                                </Feed.Like>
                                {this.state.likeError && <span className="small-grey-text">&nbsp;&nbsp;{this.state.chatList[i].likeErrorMessage}</span>}
                            </Feed.Meta>
                        </Feed.Content>
                    </Feed.Event>
                )
                }

            </Feed>
        );
    }
}

export default Chat