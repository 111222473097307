import React from "react";
import Roads from "./road";
import dwight_eisenhower from "../../assets/img/roads/eisenhower.jpg"

const EisenhowerClose = () => (
    <Roads
        img={dwight_eisenhower}
        roadName="Eisenhower Close & Eisenhower Lower Close"
        namedAfter="Dwight D. Eisenhower"
        text="After the United States entered World War II, Eisenhower oversaw the invasions of North Africa and Sicily before supervising the invasions of France and Germany.
        After the war, he served as Army Chief of Staff (1945–1948). When General Dwight D. Eisenhower was named Supreme Allied Commander in December 1943,
        he proposed to use his existing team of subordinate commanders, including Lieutenant General Jimmy Doolittle, in key positions. Doolittle was
        named Eighth Air Force Commander, and Arnold concurred with the change. Eisenhower was a frequent visitor to the base during WWII from his headquarters at
        RAF Station Bushy Park, near Teddington."
        refSource="https://en.wikipedia.org/wiki/Dwight_D._Eisenhower"

    />
)
export default EisenhowerClose