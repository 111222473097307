import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import SiteCookieMessage from "../layouts/contact/sitecookies";
import Admin from "../layouts/members/admin"
import NewLogin from "./new_login";
import AdminPendingUseful from "../layouts/members/admin_pending_useful";
import MembersSectionHeader from "../layouts/members/members_section_header";
import AdminUpload from "../layouts/members/admin_upload";
import StandardLinks from "../layouts/members/standard_links";

class Members extends Component {
    render() {
        return (
            <div>
                <MembersSectionHeader/>

                {this.props.store.loggedIn && this.props.store.loggedInUser.complete === 1 &&
                <StandardLinks showNewFeature={this.props.showNewFeature}/>
                }

                {(this.props.store.loggedIn && this.props.store.isAdmin) &&
                <AdminPendingUseful loggedIn={this.props.store.loggedIn} loggedInUser={this.props.store.loggedInUser} isAdmin={this.props.store.isAdmin}/>
                }

                {(this.props.store.loggedIn && this.props.store.isAdmin) &&
                <AdminUpload loggedIn={this.props.store.loggedIn} loggedInUser={this.props.store.loggedInUser} isAdmin={this.props.store.isAdmin}/>
                }

                {this.props.store.loggedIn && this.props.store.loggedInUser.member_type === "admin" &&
                <Admin loggedInUser={this.props.store.loggedInUser} loggedIn={this.props.store.loggedIn}/>
                }

                {!this.props.store.loggedIn &&
                <NewLogin handleLogin={this.props.handleLogin} store={this.props.store}/>
                }

                {this.props.store.loggedIn && !this.props.approved && this.props.store.loggedInUser.complete !== 0 &&
                <Container className={"full"}>
                    <Message color="green" style={{width: "90%", margin: "30px auto"}}>
                        <Header>Pending</Header>
                        Thank you for registering to be a member. Your membership is pending approval. Please check back later
                    </Message>
                </Container>
                }

                <SiteCookieMessage/>
            </div>
        )
    }
}

export default Members