import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";

const activeStateOptions = [
    {key: "pending", value: "pending", text: "Pending"},
    {key: "active", value: "active", text: "Active"},
    {key: "deleted", value: "deleted", text: "Deleted"},
]

class UsefulAdminBar extends Component {
    render() {
        return (
            <div>
                {this.props.isAdmin &&

                <Segment style={{background: "#f0f0f0"}}>
                    <span className="small-grey-text">
                        <b>usefulDeep:</b> <a
                        href={"https://www.pinetreesra.co.uk/usefulDeep?id=" + this.props.usefulItem.id}>{"https://www.pinetreesra.co.uk/usefulDeep?id=" + this.props.usefulItem.id}</a>
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <span className="small-grey-text">
                        <b>state:</b> &nbsp;
                        <Dropdown
                            name="active_state"
                            placeholder="select active state"
                            options={activeStateOptions}
                            onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                            defaultValue={this.props.usefulItem.active_state}
                        />
                    </span>

                    <Button color={this.props.preview ? "yellow" : "green"} size="tiny" onClick={this.props.showHidePreview}
                            style={{marginLeft: "20px"}}>{this.props.preview ? "edit" : "preview"}</Button>

                    {this.props.successMessage &&
                    <Message success size="mini" compact style={{float: "right", marginTop: "-8px"}}><Icon name="checkmark" color="green" size="small"/>Saved</Message>
                    }
                </Segment>
                }
            </div>
        )
    }
}

export default UsefulAdminBar