import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import About from "../pages/about";
import History from "../pages/history";
import NeighbourhoodWatch from "../pages/neighbourhood_watch";
import Members from "../pages/members";
import Contact from "../pages/contact";
import News from "../pages/news";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import MissingDetails from "../layouts/members/missing_details";
import ResidentsOnly from "./residents_only";
import FaqItems from "../pages/faq";
import Home from "../pages/home";
import NotFound from "../pages/not_found";
import PtraVote from "../pages/ptra_vote";
import ToDo from "../pages/to_do";
import Photos from "../pages/photos";
import UsefulInfo from "../pages/useful_info";
import CommunityCentre from "../pages/community_centre";
import ForgotPassword from "../pages/forgot_password";
import ResetPassword from "../pages/reset_password";
import Bookings from "../pages/bookings";
import AdminMenu from "../layouts/bookings/admin/admin_menu";
import Events from "../pages/events";
import CommitteeVote from "../pages/committee_vote";
import BobcatSignVote from "../pages/bobcat_sign_vote";
import PtraUpdate202206 from "../pages/ptra_update_202206";
import Welcome from "../pages/welcome";
import Recruiting from "../pages/recruiting";

class Routing extends Component {

    missingDetails() {
        return (!this.props.approved && this.props.state.loggedIn);
    }

    render() {
        return (
            <>
                {this.missingDetails() ?
                    <Container text>
                        <h3>Missing details</h3>
                        <MissingDetails store={this.props.state} handleLogin={this.props.handleLogin}/>
                    </Container>
                    :

                    <Switch>
                        <Route exact path={["/home", "/"]} component={Home}/>
                        <Route exact path={"/welcome"} component={Welcome}/>
                        <Route exact path={"/recruiting"} component={Recruiting}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/history" component={History}/>
                        <Route exact path="/neighbourhoodWatch"
                               render={(props) => (<NeighbourhoodWatch {...props} loggedIn={this.props.state.loggedIn} loggedInUser={this.props.state.loggedInUser}/>)}/>
                        <Route exact path='/members'
                               render={(props) => (<Members {...props} handleLogin={this.props.handleLogin} store={this.props.state} approved={this.props.approved} showNewFeature={this.props.showNewFeature}/>)}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/forgotPassword" component={ForgotPassword}/>
                        <Route exact path="/resetPassword" component={ResetPassword}/>
                        <Route exact path="/update202206" component={PtraUpdate202206}/>

                        {!this.props.state.loggedIn &&
                        <Route exact path={["/faq", "/latestNews", "/news", "/ptraVote", "/usefulDeep", "/toDo", "/communityCentre", "/communityCentre/bookings", "/committeeVote", "/bobcatSignVote"]}
                               render={(props) => (
                                   <ResidentsOnly {...props}
                                                  loggedIn={this.props.state.loggedIn}
                                                  loggedInUser={this.props.state.loggedInUser}
                                                  isAdmin={this.props.state.isAdmin}
                                                  handleLogin={this.props.handleLogin}
                                                  store={this.props.state}
                                                  approved={this.props.approved}
                                   />
                               )}/>
                        }


                        {/* Logged on items */}
                        {this.props.approved &&
                        <Route exact path={["/news", "/latestNews"]}
                               render={(props) => (
                                   <News
                                       loggedIn={this.props.state.loggedIn}
                                       loggedInUser={this.props.state.loggedInUser}
                                       isAdmin={this.props.state.isAdmin}
                                   />
                               )}/>
                        }
                        {this.props.approved &&
                        <Route exact path={["/usefulInfo", "/usefulDeep"]} render={(props) => (
                            <UsefulInfo
                                loggedIn={this.props.state.loggedIn}
                                loggedInUser={this.props.state.loggedInUser}
                                isAdmin={this.props.state.isAdmin}
                                handleLogin={this.props.handleLogin}
                                store={this.props.state}
                                approved={this.props.approved}
                            />)}
                        />
                        }
                        {this.props.approved &&
                        <Route exact path="/faq" render={(props) => (
                            <FaqItems loggedIn={this.props.state.loggedIn} loggedInUser={this.props.state.loggedInUser} isAdmin={this.props.state.isAdmin}/>
                        )}/>
                        }
                        {this.props.approved &&
                        <Route exact path={["/communityCentre"]} render={(props) => (
                            <CommunityCentre loggedIn={this.props.state.loggedIn} loggedInUser={this.props.state.loggedInUser} isAdmin={this.props.state.isAdmin}/>
                        )}/>
                        }

                        {/* Logged on items - New features */}
                        {(this.props.approved && this.props.showNewFeature) &&
                        <Route exact path="/events" render={(props) => (
                            <Events loggedIn={this.props.state.loggedIn} loggedInUser={this.props.state.loggedInUser} isAdmin={this.props.state.isAdmin}/>
                        )}/>
                        }

                        {(this.props.approved && this.props.showNewFeature) &&
                        <Route exact path="/photos" render={(props) => (
                            <Photos {...props} loggedInUser={this.props.state.loggedInUser}/>
                        )}/>
                        }

                        {(this.props.approved && this.props.showNewFeature) &&
                        <Route exact path="/todo" render={(props) => (
                            <ToDo loggedIn={this.props.state.loggedIn} loggedInUser={this.props.state.loggedInUser} isAdmin={this.props.state.isAdmin}/>
                        )}/>
                        }

                        {(this.props.approved) &&
                        <Route exact path="/ptraVote" render={(props) => (
                            <PtraVote loggedIn={this.props.state.loggedIn} loggedInUser={this.props.state.loggedInUser} isAdmin={this.props.state.isAdmin}/>
                        )}/>
                        }

                        {(this.props.approved) &&
                        <Route exact path="/bobcatSignVote" render={(props) => (
                            <BobcatSignVote loggedIn={this.props.state.loggedIn} loggedInUser={this.props.state.loggedInUser} isAdmin={this.props.state.isAdmin}/>
                        )}/>
                        }

                        {(this.props.approved && this.props.showNewFeature) &&
                        <Route exact path={["/communityCentreBookings"]} render={(props) => (
                            <Bookings loggedIn={this.props.state.loggedIn} loggedInUser={this.props.state.loggedInUser} isAdmin={this.props.state.isAdmin}/>
                        )}/>
                        }

                        {(this.props.approved && this.props.showNewFeature) &&
                        <Route exact path={["/communityCentreAdmin"]} render={(props) => (
                            <AdminMenu loggedIn={this.props.state.loggedIn} loggedInUser={this.props.state.loggedInUser} isAdmin={this.props.state.isAdmin}/>
                        )}/>
                        }

                        {(this.props.approved && this.props.showNewFeature) &&
                        <Route exact path="/committeeVote" render={(props) => (
                            <CommitteeVote loggedIn={this.props.state.loggedIn} loggedInUser={this.props.state.loggedInUser} isAdmin={this.props.state.isAdmin}/>
                        )}/>
                        }

                        <Route
                            path={["/news", "/usefulInfo", "/faq", "/photos", "/todo", "/ptraVote", "/communityCentre", "/communityCentreBookings", "/communityCentreAdmin", "/events", "/committeeVote", "/bobcatSignVote"]}
                            component={Home}/>
                        <Route component={NotFound}/>
                    </Switch>
                }
            </>
        )
    }
}

export default Routing