export function queryString(param) {
    if (document.location.search !== undefined) {
        let qsParts = document.location.search.replace("?", "").split("&");
        let t = null;
        qsParts.forEach(function (item, i) {
            if (item.startsWith(param + "=")) {
                t = item.replace(param + "=", "");
            }
        });
        return t;
    } else {
        return null;
    }
}