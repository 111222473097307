import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import ajaxSave from "../../components/ajax_save";

const activeStateOptions = [
    {key: "pending", value: "pending", text: "Pending"},
    {key: "active", value: "active", text: "Active"},
    {key: "deleted", value: "deleted", text: "Deleted"},
]

class EventAdminBar extends Component {
    saveField = (e, inputData) => {
        ajaxSave(this.props.loggedInUser, "events", this.props.event.id, inputData.name, inputData.value).then((data) => {
            this.props.event[inputData.name] = inputData.value;
            this.props.setSuccess(true);
        }).catch((error) => {
            console.log(error)
        });
    }

    render() {
        return (
            <Segment style={{background: "#f0f0f0"}}>
                <Button
                    color={this.props.edit ? "green" : "yellow"}
                    size="tiny"
                    onClick={this.props.flipEdit}
                    content={this.props.edit ? "preview" : "edit"}
                />

                <span className="small-grey-text">
                        &nbsp;&nbsp;&nbsp;Link: &nbsp; https://www.pinetreesra.co.uk/events?id={this.props.event.id}
                    </span>

                <span className="small-grey-text">
                        &nbsp;&nbsp;&nbsp;Added: &nbsp; {this.props.event.created_dt} by <b>{this.props.event.added_by}</b>
                    </span>

                <span className="small-grey-text">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;State: &nbsp;
                    <Dropdown
                        name="state"
                        placeholder="select state"
                        options={activeStateOptions}
                        onChange={(e, {name, value}) => this.saveField(e, {name, value})}
                        defaultValue={this.props.event.state}
                    />
                    </span>


                {this.props.successMessage &&
                <Message success size="mini" compact style={{float: "right", marginTop: "-8px"}}><Icon name="checkmark" color="green" size="small"/>Saved</Message>
                }
            </Segment>
        );
    }
}

export default EventAdminBar