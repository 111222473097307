import React, {Component} from "react";
import "../assets/css/login.css"
import LoginForm from "../layouts/login/login_form";
import SignupForm from "../layouts/login/signup_form";
import LoginIntro from "../layouts/login/login_intro";
import SignupIntro from "../layouts/login/signup_intro";

class NewLogin extends Component {
    render() {
        return (
            <div className="members-page">
                <div className="panels-container">
                    <div className="panel left-panel">
                        <SignupIntro/>
                    </div>
                    <div className="panel right-panel">
                        <LoginIntro/>
                    </div>
                </div>
                <div className="forms-container">
                    <div className="signin-signup">
                        <LoginForm handleLogin={this.props.handleLogin} errors={this.props.store.error}/>
                        <SignupForm handleLogin={this.props.handleLogin} errors={this.props.store.error}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewLogin