import React, {Component} from 'react'
import {Message} from 'semantic-ui-react'
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";

const cookieName = "cookie_banner";

class SiteCookieMessage extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    state = {visible: this.props.cookies.get(cookieName) !== "hide"}

    handleDismiss = () => {
        this.setState({visible: false});
        this.props.cookies.set(cookieName, "hide");
    }

    render() {
        if (this.state.visible) {
            return (
                <Message
                    style={{textAlign: "left", position: "fixed", bottom: "0", left: "5%", margin: "auto", width: "90%", zIndex: "100"}}
                    onDismiss={this.handleDismiss}
                    header="Cookies"
                    icon={{name: "cog", size: "large", color: "brown"}}
                    content="We do not use 3rd party cookies for advertising or tracking. Site management cookies are used to maintain your identity or session on the Site so that
                                 you are not logged off unexpectedly, and any information you enter is retained from page
                                 to page. These cookies cannot be turned off individually, but you can disable all cookies in your browser if you don't want to login."
                />
            )
        }

        return (
            <></>
        )
    }
}

export default withCookies(SiteCookieMessage);