import React from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import {NavLink} from "react-router-dom";

const Intro = () => (
    <Container text>
        <Header as='h1'>Pine Trees Residents Association (PTRA)</Header>
        <p>
            The PTRA is a formal group of people that live in the neighbourhood and decide that they want to get together to deal with concerns that affect our local community.
            The PTRA will also assist with projects, events and support for the Community.
        </p>
        <p>
            The group can include tenants, shared owners, leaseholders and homeowners... anyone that lives on the estate !
        </p>
        <Message color="orange" style={{textAlign: "center", padding: "1em"}}>Working to make Pine Trees a great place for everyone to live</Message>

        <Header>
            <Icon name="users" size="large" color="orange"/>
            Why have a Residents Association?
        </Header>
        <ul>
            <li>To collect and represent the views of the residents on the Pine Trees development</li>
            <li>To be one combined voice when dealing with organisations (such as the Council, Taylor Wimpey etc.)</li>
            <li>To try and resolve concerns of the residents</li>
            <li>To arrange community events (when Covid allows)</li>
        </ul>

        <Header>
            <Icon name="user" size="large" color="green"/>
            Membership
        </Header>
        <p>
            We have an elected group of committee members who will act on suggestions or request from the residents.
        </p>
        <div style={{textAlign: "center", marginBottom: "30px"}}>
            <Button as={NavLink} to="/members" color="blue">
                <Icon name="signup" size="large"/>
                Signup
            </Button>
        </div>
        <Card.Group>
            <Card style={{minWidth: "45%"}}>
                <Card.Content>
                    <Card.Header>
                        <Icon name="comment alternate outline" color="yellow" size="large"/>
                        So why do I need to join?
                    </Card.Header>
                    <Card.Description>Full details of what membership will entail are still being worked out but is likely to include a "Members Only" section of the website where
                        we
                        will share information about our various topics and minutes of our meetings etc.</Card.Description>
                </Card.Content>
            </Card>
            <Card style={{minWidth: "45%"}}>
                <Card.Content>
                    <Card.Header>
                        <Icon name="comment alternate outline" color="yellow" size="large"/>
                        Will I have to do anything?
                    </Card.Header>
                    <Card.Description>There are different levels of involvement to suit everyone. You can just sign up for the information, you can volunteer as much or as little
                        time
                        as you have spare. Also we will be in need of a wide variety of skills when things get going.</Card.Description>
                </Card.Content>
            </Card>
        </Card.Group>
    </Container>
)

export default Intro
