import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";

class AdminMemberCounts extends Component {
    render() {
        return (
            <Segment>
                <Header>Counts</Header>
                <Table celled>
                    <Table.Header>
                        <Table.Row key="header">
                            <Table.HeaderCell>Member Type</Table.HeaderCell>
                            <Table.HeaderCell>Active State</Table.HeaderCell>
                            <Table.HeaderCell>Complete</Table.HeaderCell>
                            <Table.HeaderCell>Number</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.counts.map((obj, i) =>
                            <Table.Row key={i}>
                                <Table.Cell>{obj.member_type}</Table.Cell>
                                <Table.Cell>{obj.active_state}</Table.Cell>
                                <Table.Cell>{obj.complete}</Table.Cell>
                                <Table.Cell>{obj.number}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Segment>
        );
    }
}

export default AdminMemberCounts