import React from 'react'
import {Calendar, momentLocalizer, Views} from 'react-big-calendar'
import moment from "moment";
import PendingBooking from "./pending_booking";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import BookingRequestReceived from "./booking_request_received";
import standardFormData from "../../components/standard_form_data";
import CalendarKey from "./calendar_key";
import ShowBooking from "./show_booking";

import "react-big-calendar/lib/css/react-big-calendar.css"
import BookingProcessSteps from "./booking_process_steps";

const BOOKINGS_API_FETCH = "https://www.pinetreesra.co.uk/api/bookings/fetch";
const BOOKING_API_ADD = "https://www.pinetreesra.co.uk/api/bookings/add";
const BOOKING_API_DELETE = "https://www.pinetreesra.co.uk/api/bookings/deleteEvent"
const BOOKING_API_APPROVE = "https://www.pinetreesra.co.uk/api/bookings/approve"
const BOOKING_API_MARKPAID = "https://www.pinetreesra.co.uk/api/bookings/markPaid"

const propTypes = {}

class BookingsCalendar extends React.Component {
    constructor(...args) {
        super(...args)
        this.setPendingBookingOpen = this.setPendingBookingOpen.bind(this);
        this.setShowBookingOpen = this.setShowBookingOpen.bind(this);
        this.makeRequest = this.makeRequest.bind(this);
        this.clearMessage = this.clearMessage.bind(this);
        this.clearPending = this.clearPending.bind(this);
        this.fetchBookingData = this.fetchBookingData.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.approveEvent = this.approveEvent.bind(this);
        this.markEventPaid = this.markEventPaid.bind(this);
        this.fetchBookingData();
    }

    state = {
        rooms: [],
        eventTypes: [],
        events: [],
        pendingEvent: null,
        pendingBookingOpen: false,
        roomFilter: null,
        message: "",
        errors: [],
        dayLayoutAlgorithm: 'no-overlap',
        showEvent: null,
        showBookingOpen: false,
        rowAdded: null,
        highlightRoomFilter: false
    }

    fetchBookingData() {
        fetch(BOOKINGS_API_FETCH, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({
                errors: data.errors,
                rooms: data.rooms,
                eventTypes: data.eventTypes,
                events: this.convertDates(data.bookings)
            });
        }));
    }

    convertDates(arr) {
        arr.forEach((row) => {
            row.dt = new Date(row.dt)
            row.status_dt = new Date(row.status_dt);
            row.start = new Date(row.start);
            row.start = new Date(row.start);
            row.end = new Date(row.end);
        })
        return arr;
    }

    updateFilter(newRoom) {
        this.setState({roomFilter: newRoom, highlightRoomFilter: false});
    }

    setPendingBookingOpen(isOpen) {
        this.setState({pendingBookingOpen: isOpen})
    }

    setShowBookingOpen(isOpen) {
        this.setState({showBookingOpen: isOpen})
    }

    handleSelect = ({start, end}) => {
        if (this.state.roomFilter === null) {
            this.setState({highlightRoomFilter: true})
        } else {
            this.setState({
                pendingEvent: {
                    start: start,
                    end: end,
                    room: this.state.roomFilter,
                    event_type: "",
                    details: ""
                },
                pendingBookingOpen: true
            })
        }
    }

    eventStyleGetter(event, start, end, isSelected) {
        return {
            style: {
                backgroundColor: event.room.color,
                borderRadius: '0px',
                opacity: event.status === "pending" ? 0.3 : 0.8,
                color: 'black',
                border: '0px',
                display: 'block'
            }
        };
    }

    fetchEvents() {
        if (this.state.roomFilter !== null) {
            return this.state.events.filter((e) => e.room.value === this.state.roomFilter.value);
        }
        return this.state.events;
    }

    makeRequest() {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("room", JSON.stringify(this.state.pendingEvent.room));
        formData.append("start", this.state.pendingEvent.start);
        formData.append("end", this.state.pendingEvent.end);
        formData.append("event_type", JSON.stringify(this.state.pendingEvent.event_type));
        formData.append("details", this.state.pendingEvent.details);
        fetch(BOOKING_API_ADD, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            if (data.ok) {
                this.setState({rowAdded: data.bookingRow.bookings[0], roomFilter: null})
                this.fetchBookingData();
                this.setState({message: "Your booking request has been submitted. It will be reviewed and you will receive a response within the next 24 hours"});
            } else {
                this.setState({errors: data.errors});
                this.setState({message: "There was an issue with your request"});
            }
        }));
    }

    deleteEvent(id) {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("event_id", id);
        fetch(BOOKING_API_DELETE, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            if (data.ok) {
                this.setState({message: "Booking request removed", showBookingOpen: false})
                this.fetchBookingData();
            } else {
                this.setState({errors: data.errors});
            }
        }));
    }

    approveEvent(id) {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("event_id", id);
        fetch(BOOKING_API_APPROVE, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            if (data.ok) {
                this.setState({message: "Booking request approved", showBookingOpen: false})
                this.fetchBookingData();
            } else {
                this.setState({errors: data.errors});
            }
        }));
    }

    markEventPaid(id) {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("event_id", id);
        fetch(BOOKING_API_MARKPAID, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            if (data.ok) {
                this.setState({message: "Booking request has been marked as paid", showBookingOpen: false})
                this.fetchBookingData();
            } else {
                this.setState({errors: data.errors});
            }
        }).catch(error => {
            this.setState({errors: ["Issue with the API"]});
        }));
    }

    clearMessage() {
        this.setState({message: "", pendingEvent: null, rowAdded: null})
    }

    clearPending() {
        this.setState({pendingEvent: null, pendingBookingOpen: false})
    }

    render() {
        return (
            <>
                <BookingProcessSteps roomFilter={this.state.roomFilter} pendingEvent={this.state.pendingEvent} rowAdded={this.state.rowAdded} highlightRoomFilter={this.state.highlightRoomFilter}/>
                <Message color="green" style={this.state.highlightRoomFilter ? {transform: "scale(1)", animation: "pulse2 2s infinite"} : {}}>
                    <label>Filter</label>&nbsp;&nbsp;
                    <CalendarKey rooms={this.state.rooms} updateFilter={this.updateFilter} roomFilter={this.state.roomFilter}/>
                </Message>

                <Calendar
                    min={new Date(0, 0, 0, 8, 0, 0)}
                    max={new Date(0, 0, 0, 23, 59, 59)}
                    style={{height:"500px"}}
                    formats={{dateFormat: 'DD'}}
                    selectable={"ignoreEvents"}
                    step={60}
                    localizer={momentLocalizer(moment)}
                    events={this.fetchEvents()}
                    views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
                    defaultView={Views.WEEK}
                    onSelectEvent={event => this.setState({showEvent: event, showBookingOpen: true})}
                    onSelectSlot={this.handleSelect}
                    dayLayoutAlgorithm={this.state.dayLayoutAlgorithm}
                    eventPropGetter={(this.eventStyleGetter)}
                    popup={true}
                />
                <PendingBooking
                    rooms={this.state.rooms}
                    setPendingBookingOpen={this.setPendingBookingOpen}
                    open={this.state.pendingBookingOpen}
                    pendingEvent={this.state.pendingEvent}
                    eventTypes={this.state.eventTypes}
                    formSubmit={this.makeRequest}
                    clearPending={this.clearPending}
                    loggedInUser={this.props.loggedInUser}
                />
                <BookingRequestReceived message={this.state.message} clearMessage={this.clearMessage} rowAdded={this.state.rowAdded} loggedInUser={this.props.loggedInUser} errors={this.state.errors}/>
                <ShowBooking
                    rooms={this.state.rooms}
                    setShowBookingOpen={this.setShowBookingOpen}
                    open={this.state.showBookingOpen}
                    showEvent={this.state.showEvent}
                    loggedInUser={this.props.loggedInUser}
                    deleteEvent={this.deleteEvent}
                    approveEvent={this.approveEvent}
                    markEventPaid={this.markEventPaid}
                />
            </>
        )
    }
}

BookingsCalendar.propTypes = propTypes

export default BookingsCalendar