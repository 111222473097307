import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";

import logo from "../assets/img/neighbourhood_watch_logo.png"
import {Link} from "react-router-dom";
import standardFormData from "../components/standard_form_data";
import neighbourhoodWatchSticker1 from "../assets/img/neighbourhood_watch_sticker1.jpg"
import neighbourhoodWatchSticker2 from "../assets/img/neighbourhood_watch_sticker2.jpg"

const API_FETCH_NEIGHBOURHOOD_WATCH = "https://www.pinetreesra.co.uk/api/neighbourhoodWatch/fetch"

class NeighbourhoodWatch extends Component {
    constructor(props) {
        super(props);

        if (this.props.loggedIn) {
            this.renderBodyRow = this.renderBodyRow.bind(this);
            this.fetch = this.fetch.bind(this);
            this.fetch();
        }
    }

    fetch() {
        fetch(API_FETCH_NEIGHBOURHOOD_WATCH, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({error: data.error, coordList: data.coordList});
        }));
    }

    state = {
        coordList: []
    }

    renderBodyRow = ({road, person, mobile}, i) => ({
        key: `row-${i}`,
        cells: [road, person, mobile]
    })

    render() {
        return (
            <Container className={"full"}>
                <Header as='h1'>Pine Trees Residents Association</Header>
                <Header as='h2' textAlign="center">
                    <Icon name="shield alternate" color="red" size="large" style={{display: "inline-block"}}/>
                    Neighbourhood Watch

                </Header>
                <a href="https://www.ourwatch.org.uk/scheme/55957/pinetrees-estate" rel="noopener noreferrer" target="_blank">
                    <Image src={logo} alt="Neighbourhood Watch logo" floated={"left"}/>
                </a>
                <p>
                    Shortly after people started moving into the estate Neighbourhood Watch was started, and this recognises the community oriented nature of people living here and
                    realising the benefits of working together as a community to pro-actively communicate with each other and to communicate messages to and from the wider
                    Wycombe District Neighbourhood Watch Association, making Wycombe a safer place for us all.
                </p>
                <p>
                    We can all help keep our neighbourhood a safer place by considering the advice distributed from the coordinators, to welcome newcomers to the estate and
                    encourage them to work with us, and most important, look after each other.
                </p>
                <p>Neighbourhood Watch is a recognised national scheme and Pine Trees has its own
                    <a href="https://www.ourwatch.org.uk/scheme/55957/pinetrees-estate" rel="noopener noreferrer" target="_blank">group page</a>
                </p>

                <Header>
                    <Icon name="user secret" size="large" color="black"/>
                    Crime
                </Header>
                <p>
                    If you are unfortunately a victim of or subject to whiteness any crime it is advised that you <br/> <strong style={{color: "red"}}>DO contact the police to
                    report it</strong>. It would also be useful if you
                    could notify Neighbourhood Watch coordinators of it through the contact form so that the coordinators can see a more local view of any issues.
                </p>
                <p>
                    There are also a number of security products available from Neighbourhood Watch available
                    <a href="https://neighbourhoodwatchwycombe.org/security-products-for-sale/" rel="noopener noreferrer"
                       target="_blank">https://neighbourhoodwatchwycombe.org/security-products-for-sale/</a>
                </p>

                <Header>
                    <Icon name="user circle" size="large" color="yellow"/>
                    Coordinators
                </Header>
                <p>
                    We have a number of coordinators across the entire estate and we can be reached via the <Link to="/contact?topic=neighbourhood_watch">contact
                    form</Link> or on our community groups (WhatsApp or signal).
                </p>
                {this.props.loggedIn ?
                    <Table
                        celled
                        selectable
                        headerRow={["Road name", "Co-ordinator", "Mobile"]}
                        renderBodyRow={this.renderBodyRow}
                        tableData={this.state.coordList}
                        className="coordinators"
                    />
                    :
                    <p>
                        When you are logged in to the members area, instead of this message, you will be able to see who the coordinator is on/near your street.
                    </p>
                }
                <p>
                    <Image
                        className="image-zoom"
                        size="tiny"
                        alt="sticker 1"
                        src={neighbourhoodWatchSticker1}
                        floated="left"
                        circular
                        style={{transform: "rotate(-20deg)"}}
                    />
                    <Image
                        className="image-zoom"
                        size="tiny"
                        alt="sticker 2"
                        src={neighbourhoodWatchSticker2}
                        floated="right"
                        style={{transform: "rotate(10deg)"}}
                        rounded
                    />

                    There are Neighbourhood Watch stickers available if you wish to display these in your window, where the better the visibility of these across the estate the
                    more together we will appear as a neighbourhood. Please request these from the coordinators.
                </p>
                <p style={{clear: "both"}}>
                    The coordinators will communicate messages or alerts from <a href="http://www.thamesvalley.police.uk/" target="_blank" rel="noopener noreferrer">Thames Valley Police</a>,
                    Action Fraud, Trading Standards, Buckinghamshire Council, Get Safe Online, and the national body for <a href="http://www.tvnhwa.org.uk/" target="_blank" rel="noopener noreferrer">Neighbourhood Watch</a> proactively to the various groups
                </p>

                <Header>
                    <Icon name="external share" size="large" color="green"/>
                    Useful Articles and Links
                </Header>
                <p>
                    There are some useful articles regarding crime prevention that can be found on this page
                    <a href="https://neighbourhoodwatchwycombe.org/crime-prevention-2/" target="_blank"
                       rel="noopener noreferrer">https://neighbourhoodwatchwycombe.org/crime-prevention-2/</a> and
                    <a href="https://www.tvnhwa.org.uk/keeping_safe/" target="_blank" rel="noopener noreferrer">https://www.tvnhwa.org.uk/keeping_safe/</a>
                </p>
                <p>
                    Also, a number of useful links, including some local potential discounts available&nbsp;
                    <a href="https://neighbourhoodwatchwycombe.org/useful-links/" target="_blank" rel="noopener noreferrer">https://neighbourhoodwatchwycombe.org/useful-links/</a>
                </p>

                <Header>
                    <Icon name="video" size="large" color="orange"/>
                    CCTV
                </Header>
                <p>
                    If you do have CCTV on your property, please consider reviewing the guidance from the ICO that can be found
                    <a href="https://ico.org.uk/your-data-matters/domestic-cctv-systems-guidance-for-people-being-filmed/" target="_blank"
                       rel="noopener noreferrer">https://ico.org.uk/your-data-matters/domestic-cctv-systems-guidance-for-people-being-filmed/</a>
                </p>
            </Container>
        )
    }
}

export default NeighbourhoodWatch