import {Component} from "react";
import EventAdvert from "./event_advert";

class EventsMonth extends Component {
    render() {
        return (
            <div>
                {this.props.eventsInMonth.map((event) =>
                    <EventAdvert
                        key={"eventId_" + event.id}
                        event={event}
                        loggedIn={this.props.loggedIn}
                        loggedInUser={this.props.loggedInUser}
                        isAdmin={this.props.isAdmin}
                        isHighlighted={event.id === this.props.activeEvent}
                    />)}
            </div>
        );
    }
}

export default EventsMonth