import React from "react";
import Roads from "./road";
import beech from "../../assets/img/roads/beech.jpg"

const BeechStreet = () => (
    <Roads
        img={beech}
        roadName="Beech Street"
        namedAfter="Former street on the US Base"
        text={"Beech Street was an existing road on the former US base and has been retained to maintain the link."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default BeechStreet