import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Statistic from "semantic-ui-react/dist/commonjs/views/Statistic";
import Item from "semantic-ui-react/dist/commonjs/views/Item";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

import timPocock from "../assets/img/avatar/tim_pocock.jpg";
import steveGill from "../assets/img/avatar/steve_gill.jpg";
import louiseLord from "../assets/img/avatar/louise_lord.jpeg";
import chrisBracchi from "../assets/img/avatar/chris_bracchi.jpg";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import POC from "../components/poc";


class CommitteeVote extends Component {
    constructor(props) {
        super(props);
        this.updateVoteCount = this.updateVoteCount.bind(this);
        this.formSubmitted = this.formSubmitted.bind(this);
        console.log("C");
    }

    state = {
        submitted: false,
        yourVotes: 0,
        maxVotes: 5,
        candidates: [
            {
                name: "Steve Gill",
                image: steveGill,
                role: "Vice Chairman",
                extra: "Steve is a really nice chap",
                vote: false
            },
            {
                name: "G C",
                image: "https://semantic-ui.com/images/avatar/large/christian.jpg",
                role: "Treasurer",
                extra: "G C has fought relentlessly with TW around snagging on the estate.",
                vote: false
            },
            {
                name: "Louise Lord",
                image: louiseLord,
                role: "Secretary",
                extra: "Lovely",
                vote: false
            },
            {
                name: "Chris Bracchi",
                image: chrisBracchi,
                role: "Neighbourhood Watch",
                extra: "Proper good bloke",
                vote: false
            },
            {
                name: "Alina",
                image: "https://semantic-ui.com/images/avatar/large/helen.jpg",
                role: "Member",
                extra: "Just moved back",
                vote: false
            },
            {
                name: "Tim Pocock",
                image: timPocock,
                role: "Website",
                extra: "Tim has been a bit of a slacker really",
                vote: false
            },
        ]
    }

    updateVoteCount(state, index) {
        let actioned = true;

        let c = this.state.yourVotes;
        c = state ? c + 1 : c - 1;

        if (c < 0) {
            c = 0;
        }

        if (c > this.state.maxVotes) {
            c = this.state.maxVotes;
            actioned = false;
        }

        let candidateUpdated = this.state.candidates;
        if (actioned) {
            candidateUpdated[index].vote = state;
        }

        this.setState({yourVotes: c, candidates: candidateUpdated});
    }

    formSubmitted() {
        // Do something
        this.setState({submitted: true});
    }

    render() {
        return (
            <Container style={{padding: "0 10px"}}>
                <POC />


                <Header as="h1">
                    <Icon name={"check square outline"} color={"green"} size={"large"}/>
                    PTRA Committee Members Re-election
                </Header>

                <Segment>
                    <p>
                        <Icon name={"question circle outline"} size={"big"} color={"yellow"}/>
                        Each year the committee members need to be re-elected
                    </p>
                    <p>
                        <Icon name={"calendar alternate outline"} size={"big"} color={"brown"}/>
                        To achieve this we need to prepare a list of candidates and pick the top {this.state.maxVotes} for election proposal at the Annual General Meeting on the
                        20th February 2022 (TBC).
                    </p>
                    <p>
                        <Icon name={"home"} color={"orange"} size={"big"}/>
                        Each household has one vote to nominate the top 8+ candidates from the potential candidates in the running.
                    </p>
                    <p>
                        <Icon name={"user"} color={"green"} size={"big"}/>
                        The first submitted set of nominations per household will be used
                    </p>
                    <p>
                        <Icon name={"users"} color={"blue"} size={"big"}/>
                        The {this.state.maxVotes} candidates with the most nominations will be put forward for election at the AGM.
                    </p>
                    <p>
                        <Icon name={"clock"} color={"purple"} size={"big"}/>
                        Once elected they will serve the PTRA for the minimum term of one year.
                    </p>
                </Segment>

                {!this.state.submitted &&
                <>
                    <Header as="h2">Possible candidates</Header>
                    <div>
                        <Statistic label="Votes" value={this.state.yourVotes} color={this.state.yourVotes < this.state.maxVotes ? "orange" : "green"}/>
                        <Statistic label="Total" value={this.state.maxVotes} color={"green"}/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button disabled={this.state.yourVotes < this.state.maxVotes} color={this.state.yourVotes === this.state.maxVotes ? "green" : ""} content={"submit"}
                                onClick={this.formSubmitted}/>
                    </div>

                    <Item.Group>
                        {this.state.candidates.map((obj, k) => (
                            <Item key={k}>
                                <Icon name={obj.vote ? "check square outline" : "square outline"} size={"huge"} style={{cursor: "pointer"}} color={obj.vote ? "green" : ""}
                                      onClick={(e) => this.updateVoteCount(!obj.vote, k)}/>
                                <Item.Image size='tiny' src={obj.image}/>

                                <Item.Content>
                                    <Item.Header>{obj.name}</Item.Header>
                                    <Item.Meta>{obj.role}</Item.Meta>
                                    <Item.Extra>{obj.extra}</Item.Extra>
                                </Item.Content>
                            </Item>
                        ))
                        }
                    </Item.Group>
                </>
                }

                {this.state.submitted &&
                <>
                    <Header content={"Thank you for your vote"}/>
                    <p>Your vote has been registered as the following...</p>
                    <ul>
                        {this.state.candidates.filter((c) => c.vote).map((obj, k) => (
                            <li>{obj.name}</li>
                        ))
                        }
                    </ul>
                </>
                }

            </Container>
        );
    }
}

export default CommitteeVote