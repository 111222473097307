import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";

import img1 from "../assets/img/community_centre/IMG_1984.jpg"
import img2 from "../assets/img/community_centre/IMG_1985.jpg"
import img3 from "../assets/img/community_centre/IMG_1986.jpg"
import img4 from "../assets/img/community_centre/IMG_1987.jpg"
import img5 from "../assets/img/community_centre/IMG_1988.jpg"
import img6 from "../assets/img/community_centre/IMG_1989.jpg"
import img7 from "../assets/img/community_centre/IMG_1990.jpg"
import img8 from "../assets/img/community_centre/IMG_1991.jpg"
import img9 from "../assets/img/community_centre/IMG_1992.jpg"
import img10 from "../assets/img/community_centre/IMG_1993.jpg"
import img11 from "../assets/img/community_centre/IMG_1994.jpg"
import img12 from "../assets/img/community_centre/IMG_1995.jpg"
import img13 from "../assets/img/community_centre/IMG_1996.jpg"
import img14 from "../assets/img/community_centre/IMG_1998.jpg"
import img15 from "../assets/img/community_centre/IMG_1999.jpg"
import img16 from "../assets/img/community_centre/IMG_2001.jpg"
import img17 from "../assets/img/community_centre/20210817_120821.jpg"
import img18 from "../assets/img/community_centre/20210817_121653.jpg"
import img19 from "../assets/img/community_centre/20210817_121820.jpg"
import img20 from "../assets/img/community_centre/20210817_121823.jpg"
import img21 from "../assets/img/community_centre/20210817_121851.jpg"

import vidUpstairs from "../assets/img/community_centre/upstairs.mp4"
import vidDownstairs from "../assets/img/community_centre/downstairs.mp4"

class CommunityCentre extends Component {
    render() {
        return (
            <Container>
                <Container className={"full"}>
                    <Header as="h1" content="PineTrees Community Centre & Shop"/>
                    <Header as='h2' textAlign="center">
                        <Icon name="hand point right" color="teal" size="large"/>
                        Update as of 17th August 2021
                    </Header>
                    <Header content="Community Centre"/>
                    <p>Earlier today 3 PTRA committee members were invited to have a tour of the new PineTrees Community Centre... very impressive!</p>
                    <p>The building is over two floors and consists of ...</p>
                    <Header as="h4" content="Downstairs"/>
                    <ul>
                        <li>Entrance hall</li>
                        <li>Large hall</li>
                        <li>Small hall (can be opened or separated from the large hall by divider walling)</li>
                        <li>Kitchen area (no appliances yet but sink, cupboards etc.)</li>
                        <li>Male/Female/Disabled toilets</li>
                        <li>Lift and stairs to...</li>
                    </ul>
                    <Header as="h4" content="Upstairs"/>
                    <ul>
                        <li>Another small hall/space</li>
                        <li>An office sized room</li>
                        <li>Various small store rooms</li>
                    </ul>
                    <p>The building inside is nearly complete and we were given the main cause of delay being the shortage of building supplies to finish the outside.
                        (The blockwork to be completed once the purple hoarding comes down)</p>

                    <Header content="Shop"/>
                    <p>There is not much to report on the shop other than the shell is nearing completion (as are the apartments above)</p>
                    <p>The shop will be sold as a shell to allow the incumbent to fit out. Our information at this point is that...<br/>
                        <i style={{display: "inline-block", padding: "5px 25px", color: "green"}}>"the shop is being sold to an investor and the formalities are nearing
                            completion"</i><br/>
                        ... although this is not officially confirmed and as such there is still no more information
                        on who the retailer is likely to be.
                    </p>

                    <Header content="A few photos and walk through videos"/>
                </Container>

                <div className="ptra-carousel">
                    <Carousel>
                        <div><img src={img17} alt="Outside of Community Centre"/><p>Outside of Community Centre (side view towards Kennedy Avenue)</p></div>
                        <div><img src={img5} alt="Entrance hall"/><p>Entrance hall (looking towards roundabout)</p></div>
                        <div><img src={img6} alt="Entrance hall"/><p>Entrance hall (looking towards Kennedy Avenue)</p></div>
                        <div><img src={img3} alt="Kitchen"/><p>Kitchen (looking towards roundabout)</p></div>
                        <div><img src={img4} alt="Kitchen"/><p>Kitchen (looking towards the estate)</p></div>

                        <div><img src={img7} alt="Small hall"/><p>Small hall - note the stowed divider wall on left of photo (looking towards shop)</p></div>
                        <div><img src={img8} alt="Small hall"/><p>Small hall (looking towards the estate - large hall on right)</p></div>
                        <div><img src={img9} alt="Large Hall"/><p>Large Hall (looking towards Kennedy Avenue from the small hall)</p></div>
                        <div><img src={img10} alt="Large Hall"/><p>Large hall (looking towards the estate - entrance to small hall in the centre of the photo)</p></div>
                        <div><img src={img18} alt="Lift between floors"/><p>Lift between floors</p></div>
                        <div><img src={img11} alt="Top of the stairs area"/><p>Top of the stairs area (looking towards roundabout)</p></div>
                        <div><img src={img12} alt="Top of the stairs area"/><p>Top of the stairs area (looking towards the other rooms)</p></div>


                        <div><img src={img13} alt="Upstairs Office sized room "/><p>Upstairs Office sized room (looking towards the estate)</p></div>
                        <div><img src={img14} alt="Upstairs Office sized room "/><p>Upstairs Office sized room (looking into the rest of upstairs)</p></div>
                        <div><img src={img15} alt="Upstairs large room"/><p>Upstairs large room (looking into room)</p></div>
                        <div><img src={img16} alt="Upstairs large room"/><p>Upstairs large room (looking towards the doors)</p></div>
                        <div><img src={img19} alt="Air filtration system"/><p>Air filtration system (heats fresh air from outside to heat the building)</p></div>
                        <div><img src={img20} alt="Hot water tank"/><p>Hot water tank (in storage cupboard)</p></div>
                        <div><img src={img21} alt="Upstairs disabled toilet"/><p>Upstairs disabled toilet</p></div>
                        <div>
                            <video src={vidDownstairs} controls="true"/>
                            <p>Walk through downstairs</p></div>
                        <div>
                            <video src={vidUpstairs} controls="true"/>
                            <p>Walk through upstairs</p></div>
                        <div><img src={img2} alt="Outside the new shop"/><p>Outside the new shop - Loading bay (looking from the Community Centre towards Eaker Street)</p></div>
                        <div><img src={img1} alt="Inside the new shop"/><p>Inside the new shop</p></div>

                    </Carousel>
                </div>

                <Container text>
                    <Header content="What next?"/>
                    <p>There are many elements to consider... how will the building be managed? how are bookings made? what are the rules on bookings? who looks after the keys?
                        who does the cleaning? how much does it cost to hire a space? who pays for the replacement of broken items? who buys the initial furnishings
                        (tables/chairs/etc.)?</p>
                    <p>Unfortunately the PTRA don't have the answers to most of these questions yet, but we are, on behalf of the residents, asking these questions to Taylor
                        Wimpey and Broadlands. The current guess is that Broadlands will probably end up employing an external Company to manage the building.</p>
                    <p>We will post updates to this page (or view the news pages) as soon as we have anything definite</p>
                </Container>
            </Container>
        );
    }
}

export default CommunityCentre