import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

class UsefulTitle extends Component {
    render() {
        return (
            <div>
                {this.props.isAdmin && !this.props.preview ?
                    <div style={{marginBottom: "14px"}}>
                        <Input
                            name="useful_title"
                            defaultValue={this.props.usefulItem.useful_title}
                            style={{width: "90%"}}
                            onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                        />
                    </div>
                    :
                    <Header>
                        <Icon name="phone" color="blue" flipped="horizontally"/>
                        {this.props.usefulItem.useful_title}
                    </Header>
                }
            </div>
        )
    }
}

export default UsefulTitle