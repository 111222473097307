import React, {Component} from "react";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import Wysiwyg from "../../components/wysiwyg";
import * as PropTypes from "prop-types";

class FaqAnswer extends Component {
    render() {
        return (
            <div style={{padding: "10px"}}>
                <Label content="A" color="yellow" circular style={{marginRight: "10px", float: "left"}}/>
                <Wysiwyg
                    editorContent={this.props.faqItem.answer}
                    field="answer"
                    saveField={this.props.saveField}
                    readOnly={!this.props.admin}
                    preview={this.props.preview}
                    showHidePreview={this.props.showHidePreview}
                />
                <div style={{color: "yellowgreen", margin:"20px 0 0 0"}}>Last updated: {this.props.faqItem.updated_dt}</div>
            </div>
        );
    }
}

FaqAnswer.propTypes = {
    faqItem: PropTypes.any,
    saveField: PropTypes.func,
    admin: PropTypes.any,
    preview: PropTypes.bool,
    showHidePreview: PropTypes.func
};

export default FaqAnswer