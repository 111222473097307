import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";



class MembersSectionHeader extends Component {
    render() {
        return (<>
                <Container text style={{textAlign: "center", marginBottom: "10px"}}>
                    <Header as="h1" id="members-section-h1">
                        Pine Trees Residents Association
                    </Header>
                    <Header as="h2" id="members-section-h2">
                        <Icon name="users" color="orange" size="small"/>
                        Members Section
                    </Header>
                </Container>
                {/*<div className={"wide-banner"} style={{width:"970px", height:"90px", background:"#999999", margin:"0 auto 20px auto", borderRadius:"5px", padding: "10px", textAlign:"center"}}>*/}
                {/*    Sample Advert: to advertise here contact <a href={"mailto:committee@pintetreesra.co.uk"}>committee@pintetreesra.co.uk</a><br/>970px x 90px*/}
                {/*</div>*/}
                {/*<div className={"mobile-banner"} style={{width:"320px", height:"50px", background:"#999999", margin:"20px auto", borderRadius:"5px", padding: "1px", textAlign:"center"}}>Sample Advert Mobile<br/>320px x 50px</div>*/}
            </>
        );
    }
}

export default MembersSectionHeader