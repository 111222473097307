import React, {Component} from "react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import "react-datepicker/dist/react-datepicker.css";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import EventAdminBar from "./event_admin_bar";
import EventAdvertDisplay from "./event_advert_display";
import EventAdvertEdit from "./event_advert_edit";


class EventAdvert extends Component {
    constructor(props) {
        super(props);
        this.flipEdit = this.flipEdit.bind(this);
        this.setSuccess = this.setSuccess.bind(this);
    }

    state = {
        edit: false,
        successMessage: false,
    }

    flipEdit() {
        this.setState({edit: !this.state.edit});
    }

    setSuccess(result) {
        this.setState({successMessage: result});
        setTimeout(function () {
            this.setState({successMessage: false});
        }.bind(this), 2000);
    }

    render() {
        return (
            <Segment
                inverted={this.props.event.event_colour.length > 0}
                color={this.props.event.event_colour.length > 0 ? this.props.event.event_colour : "grey"}
            >
                {this.props.isHighlighted &&
                <Label color={this.props.event.event_colour === "red" ? "black" : "red"} corner={"right"} icon={"heart"}/>
                }
                {this.state.edit ?
                    <EventAdvertEdit
                        event={this.props.event}
                        successMessage={this.state.successMessage}
                        setSuccess={this.setSuccess}
                        loggedIn={this.props.loggedIn}
                        loggedInUser={this.props.loggedInUser}
                        isAdmin={this.props.isAdmin}
                    />
                    :
                    <EventAdvertDisplay event={this.props.event}/>
                }

                {this.props.isAdmin &&
                <EventAdminBar
                    event={this.props.event}
                    edit={this.state.edit}
                    successMessage={this.state.successMessage}
                    setSuccess={this.setSuccess}
                    flipEdit={this.flipEdit}
                    loggedIn={this.props.loggedIn}
                    loggedInUser={this.props.loggedInUser}
                    isAdmin={this.props.isAdmin}
                />
                }
            </Segment>
        );
    }
}

export default EventAdvert