import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";

const frontDoors = {
    arizonaway: 5,
    beechstreet: 16,
    californiaway: 6,
    deyoungway: 1,
    doolittleavenue: 52,
    eakerstreet: 21,
    eisenhowerlowerclose: 10,
    kellyroad: 40,
    kennedyavenue: 71,
    kiltyplace: 10,
    leighplace: 24,
    mansfieldclose: 11,
    robertsonplace: 25,
    threlkeldclose: 9,
    trinitycircle: 88,
    wernetteway: 5
}

class AdminMemberRoadCounts extends Component {
    possible(street) {
        street = street.replaceAll(" ", "").toLowerCase();
        if (frontDoors[street] !== undefined) {
            return frontDoors[street];
        }
        return "?";
    }

    sum(key) {
        return this.props.roadCounts.reduce((a, b) => a + (b[key] || 0), 0);
    }

    render() {
        return (
            <Segment>
                <Header>Representation: Votes by road</Header>
                <Table celled>
                    <Table.Header>
                        <Table.Row key="header">
                            <Table.HeaderCell>Street</Table.HeaderCell>
                            <Table.HeaderCell>Vote Counts</Table.HeaderCell>
                            <Table.HeaderCell>Front Doors</Table.HeaderCell>
                            <Table.HeaderCell>Coverage %</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.roadCounts.map((obj, i) =>
                            <Table.Row key={i}>
                                <Table.Cell>{obj.street}</Table.Cell>
                                <Table.Cell>{obj.votes}</Table.Cell>
                                <Table.Cell>{this.possible(obj.street)}</Table.Cell>
                                <Table.Cell>{(((obj.votes / this.possible(obj.street))*100) || 0).toFixed(0)} %</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
                <p className="small-grey-text">Total single house votes: {this.sum("votes")}</p>
            </Segment>
        );
    }
}

export default AdminMemberRoadCounts