import React, {Component} from "react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

class ToDoStateLabel extends Component {
    state = {
        Pending: {icon: "clock", color: "orange", text: "Pending"},
        In_Progress: {icon: "paper plane", color: "blue", text: "In Progress"},
        Wont_Action: {icon: "x", color: "red", text: "Wont action"},
        Complete: {icon: "check", color: "green", text: "Completed"}
    }

    render() {
        return (
            <Label as="span" ribbon="right" color={this.state[this.props.itemState].color} style={{position: "absolute", right: "0", top: "-10px"}}>
                <Icon name={this.state[this.props.itemState].icon}/>
                {this.state[this.props.itemState].text}
            </Label>
        );
    }
}

export default ToDoStateLabel