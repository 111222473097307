import React, {Component} from "react";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";

class UsefulSearch extends Component {
    render() {
        return (
            <Segment style={{background: "#e9f5e8"}} color={"green"} className="small-grey-text">
                <span style={{marginRight: "20px"}}>Search:</span>
                <Input placeholder="search words (ie. Dog groomers, Decking, Plumber, NHS, etc.)" icon={{name: "search"}} onChange={(e) => this.props.search(e.target.value)}
                       style={{width: "90%"}}/>
            </Segment>
        );
    }
}

export default UsefulSearch