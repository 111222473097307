import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Select from "semantic-ui-react/dist/commonjs/addons/Select";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";

import BookingDetails from "./booking_details";
import moment from "moment";
import bookingTermsPdf from "../../assets/img/community_centre/booking_terms.pdf"

class PendingBooking extends Component {
    constructor(props) {
        super(props);
        this.setValues = this.setValues.bind(this);
        this.updateDetails = this.updateDetails.bind(this);
    }

    state = {
        event_type: this.props.pendingEvent !== null ? this.props.pendingEvent.event_type : "",
        calculationText: "",
        details: "",
        terms: false
    }

    submit() {
        if (this.props.pendingEvent.event_type !== null && this.state.details.length > 4) {
            this.props.setPendingBookingOpen(false);
            this.props.formSubmit();
        }
    }

    setValues(e, target) {
        let eventTypeObject = this.props.eventTypes.filter(e => e.value === target.value)[0];
        this.setState({event_type: eventTypeObject});
        this.props.pendingEvent.event_type = eventTypeObject;

        let surcharge = parseFloat(this.props.pendingEvent.room.surcharge).toFixed(2);
        let hourly = parseFloat(this.props.pendingEvent.event_type.hourly).toFixed(2);
        let duration = moment(this.props.pendingEvent.end).diff(moment(this.props.pendingEvent.start), "minutes");
        if (duration % 60 !== 0) {
            duration = duration + (60 - (duration % 60));
        }
        this.props.pendingEvent.duration = parseInt(duration / 60);
        this.props.pendingEvent.cost = (parseFloat((this.props.pendingEvent.duration * hourly)) + parseFloat(surcharge)).toFixed(2);
        this.props.pendingEvent.hourly = hourly;
        this.props.pendingEvent.surcharge = surcharge;
    }

    updateDetails(e, target) {
        this.props.pendingEvent.details = target.value;
        this.setState({details: target.value})
    }

    closePopup(openState) {
        this.props.setPendingBookingOpen(openState)
    }

    render() {
        return (
            <Modal
                onClose={() => this.closePopup(false)}
                onOpen={() => this.closePopup(true)}
                open={this.props.open}
                closeIcon
            >
                {this.props.pendingEvent !== null &&
                <>
                    <Modal.Header>Booking request</Modal.Header>
                    <Modal.Content>
                        <p>Thank you for your enquiry. We need a few more details...</p>
                        <BookingDetails asDisplay={false} booking={this.props.pendingEvent} loggedInUser={this.props.loggedInUser}/>
                        <p><b>EVENT TYPE</b></p>
                        <Select options={this.props.eventTypes} placeholder="Event type" onChange={this.setValues.bind(this)}
                                error={this.state.event_type === null}
                                defaultValue={""}/>
                        <br/>
                        <br/>
                        <p><b>DETAILS</b></p>
                        <TextArea style={{border: "1px solid #dededf", borderRadius: "4px", padding: "11px 14px", width: "100%"}}
                                  placeholder="Tell us more... give a brief description of the event"
                                  onChange={this.updateDetails}/>
                        <br/>
                        <br/>
                        <p><b>TERMS &amp; CONDITIONS</b></p>
                        <Checkbox onChange={(e, t) => this.setState({terms: t.checked})}
                                  label={<label>I agree to the <a href={bookingTermsPdf} target="_blank" rel="noreferrer">terms and conditions of
                                      booking</a></label>}
                                  defaultChecked={this.state.terms}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        Complete ?
                        <Button content="Nope"
                                labelPosition="left"
                                icon="close"
                                onClick={() => this.props.clearPending()}
                                negative/>
                        <Button
                            content="Yep"
                            labelPosition='right'
                            icon='checkmark'
                            onClick={() => this.submit()}
                            disabled={this.state.event_type === null || this.state.details.length < 5 || !this.state.terms}
                            positive
                        />
                    </Modal.Actions>
                </>
                }
            </Modal>
        );
    }

}

export default PendingBooking