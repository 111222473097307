import React, {Component} from "react";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import {NavLink} from "react-router-dom";
import Logout from "../common/googlelogout";

class MenuFull extends Component {
    render() {
        return (
            <Menu style={{borderRadius: 0}} pointing stackable inverted className={"nav-full"}>
                {this.props.menuItems.left.map((item, i) => (
                    <Menu.Item key={"fm" + i} name={item.name} icon={item.icon} as={NavLink} to={item.to}/>
                ))}

                {this.props.loggedIn &&
                <Menu.Menu position="right">
                    {this.props.approved && this.props.menuItems.right.map((item, i) => (
                            (item.newFeature === undefined || (item.newFeature && this.props.showNewFeature)) && this.props.approved &&
                            <Menu.Item key={("fmr" + i)} name={item.name} icon={item.icon} as={NavLink} to={item.to}/>
                        )
                    )}
                    <Menu.Item className="logout">
                        <span style={{marginRight: "10px"}} title={"MemberId: " + this.props.loggedInUser.id}>Logged in as {this.props.loggedInUser.fname}</span>
                        <Logout handleLogin={this.props.handleLogin} loggedInUser={this.props.loggedInUser}/>
                    </Menu.Item>
                </Menu.Menu>
                }
            </Menu>
        );
    }
}

export default MenuFull