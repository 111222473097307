import React from "react";
import Roads from "./road";
import mansfield from "../../assets/img/roads/mansfield.jpeg"

const MansfieldTrinity = () => (
    <Roads
        img={mansfield}
        roadName="Mansfield Close and Trinity Circle"
        namedAfter="Former halls of residence (Mansfield & Trinity Halls) at the school"
        text={"Mansfield Close and Trinity Circle were named after the two former halls of residence (Mansfield & Trinity Halls) at the school which accommodated students " +
        "from around the world. Prior to the students moving in to the two three-story buildings, both had been barracks for up to 900 Cold War personnel who worked " +
        "round-the-clock on the base and in the underground bunker before being turned over for student halls in 1971. The buildings were the stuff of lore–with " +
        "some nearby High Wycombe residents wrongly believing they were really giant covers for missile silos and would slide down the hillside towards High Wycombe " +
        "so that missiles could launch in the event of WWIII!"}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default MansfieldTrinity