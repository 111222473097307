import React, {Component} from "react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import standardFormData from "../../../../components/standard_form_data";

const API_BOOKING_ROOM_UPDATE = "https://www.pinetreesra.co.uk/api/bookings/roomUpdate"

class RoomRow extends Component {
    state = {
        canEdit: this.props.loggedInUser.permissions.filter(p => p.permission === "room_admin").length > 0
    }

    update(e, target) {
        if (!this.state.canEdit) {
            return;
        }

        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("room_id", this.props.room.id);
        formData.append("field", target.name);
        formData.append("value", target.value);
        fetch(API_BOOKING_ROOM_UPDATE, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            if (!data.ok) {
                this.props.logErrors(data.errors);
            }
        })).catch(error => {
            this.props.logErrors([error.toString()]);
        });
    }

    render() {
        return (
            <Table.Row key={"room_" + this.props.room.id}>
                <Table.Cell>
                    <Input onChange={(e, t) => this.update(e, t)} name="room_text" defaultValue={this.props.room.text} disabled={!this.state.canEdit}/>
                </Table.Cell>
                <Table.Cell>
                    <Input onChange={(e, t) => this.update(e, t)} name="room_value" defaultValue={this.props.room.value} disabled={!this.state.canEdit}/>
                </Table.Cell>
                <Table.Cell>
                    <Icon name="pound sign"/>
                    <Input onChange={(e, t) => this.update(e, t)} name="room_surcharge" defaultValue={parseFloat(this.props.room.surcharge).toFixed(2)} style={{textAlign: "right"}}
                           disabled={!this.state.canEdit}/>
                </Table.Cell>
                <Table.Cell>
                    {this.props.room.dimensions}
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default RoomRow