import {Component} from "react";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

class CalendarKey extends Component {
    render() {
        return (
            <>
                {this.props.rooms.map((room) =>
                    <Label style={{backgroundColor: room.color, cursor: "pointer", border: (this.props.roomFilter === room ? "2px solid black" : "")}} content={room.text}
                           key={room.value}
                           onClick={(e) => this.props.updateFilter(room)}/>
                )}
                <Label style={{backgroundColor: "grey", cursor: "pointer", border: (this.props.roomFilter === null ? "2px solid black" : "")}} content="show all" key={"all"}
                       onClick={(e) => this.props.updateFilter(null)}/>
            </>
        );
    }
}

export default CalendarKey