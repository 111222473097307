import React, {Component} from "react";
import {GoogleLogout} from "react-google-login";
import axios from "axios";

const clientId = "495831438879-tfsehib8us2u5ji1oqh5umq9nkj7532l.apps.googleusercontent.com";
const API_PATH = 'https://www.pinetreesra.co.uk/api/login/logout';

export default class Logout extends Component {
    onSuccess = () => {
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: {'content-type': 'application/json'},
            data: this.props.loggedInUser
        });
        this.props.handleLogin(undefined);
    }

    onFailure = () => {
        if (!this.props.loggedInUser.google) {
            this.onSuccess();
        }
    }

    render() {
        return (
            <div>
                <GoogleLogout
                    clientId={clientId}
                    buttonText="Logout"
                    onLogoutSuccess={this.onSuccess}
                    onFailure={this.onFailure}
                />
            </div>
        )
    }
}