import React from "react";
import Roads from "./road";
import edward_kennedy from "../../assets/img/roads/kennedy.jpeg"

const KennedyAvenue = () => (
    <Roads
        img={edward_kennedy}
        roadName="Kennedy Avenue"
        namedAfter="Captain Edward Coverley Kennedy"
        text={"Once the main 'spine' road for the base, was not named after US President John F. Kennedy but was named for Royal Navy Captain Edward Coverley Kennedy, the " +
        "father of the late broadcaster Ludovic Kennedy. Kennedy Sr. was a WWI veteran who had been recalled at the age of 60 to captain a converted P&O steamship " +
        "HMS Rawalpindi. The Armed Merchant Cruiser was sunk on 23 November 1939 after an encounter with two German cruisers while patrolling the southeast of Iceland. " +
        "Kennedy opted to signal his position and fight on rather than surrender to the German battlecruiser Scharnhorst in the one-sided sea battle which saw 275 of the 312 " +
        "crew of HMW Rawalpindi killed. Captain Kennedy was posthumously 'mentioned in dispatches'."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default KennedyAvenue