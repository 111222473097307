import React, {Component} from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import standardFormData from "../../../../components/standard_form_data";
import FormErrors from "../../../login/form_errors";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {TableBody} from "semantic-ui-react";
import BookingRow from "./booking_row";

const API_BOOKING_ADMIN_FETCH_BOOKING = "https://www.pinetreesra.co.uk/api/bookings/bookingAdmin";

class BookingActions extends Component {
    constructor(props) {
        super(props);
        this.logErrors = this.logErrors.bind(this);
        this.fetchBookings = this.fetchBookings.bind(this);
        this.fetchBookings();
    }

    state = {
        bookings: [],
        errors: [],
        filter: null
    }

    logErrors(errorsArr) {
        this.setState({errors: errorsArr});
    }

    fetchBookings() {
        console.log("in fetch")
        fetch(API_BOOKING_ADMIN_FETCH_BOOKING, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({bookings: data.bookings})
        })).catch(error => {
            this.setState({errors: ["Issue with the API"]});
        });
    }

    render() {
        return (
            <Container style={{padding: "10px"}}>
                <Header content={"Bookings"}/>
                <FormErrors errors={this.state.errors}/>
                <Table>
                    <Table.Header key={"header_bookings"}>
                        <Table.Row>
                            <Table.HeaderCell>id</Table.HeaderCell>
                            <Table.HeaderCell>booker name</Table.HeaderCell>
                            <Table.HeaderCell>booker email</Table.HeaderCell>
                            <Table.HeaderCell>start</Table.HeaderCell>
                            <Table.HeaderCell>end</Table.HeaderCell>
                            <Table.HeaderCell>room</Table.HeaderCell>
                            <Table.HeaderCell>event type</Table.HeaderCell>
                            <Table.HeaderCell>cost</Table.HeaderCell>
                            <Table.HeaderCell>status</Table.HeaderCell>
                            <Table.HeaderCell>by</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <TableBody>
                        {this.state.bookings.map((booking) =>
                            <BookingRow
                                key={"booking_" + booking.id}
                                booking={booking}
                                errors={this.state.errors}
                                loggedInUser={this.props.loggedInUser}
                                logErrors={this.logErrors}
                                fetchBookings={this.fetchBookings}
                            />
                        )}
                    </TableBody>
                </Table>
            </Container>
        );
    }
}

export default BookingActions