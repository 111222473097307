import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import BookingsCalendar from "../layouts/bookings/calendar";

class Bookings extends Component {
    render() {
        return (
            <Container style={{padding: "0 10px"}}>
                <Header as="h1">Pine Trees Community Centre</Header>
                <Message icon={{name: "warning sign", size: "large"}} color="yellow"
                         content="This page is for members only. Please do not share information found here outside of the estate."/>
                <Header as="h2">Bookings</Header>

                <BookingsCalendar loggedInUser={this.props.loggedInUser}/>
            </Container>
        );
    }
}

export default Bookings