import { Component } from "react"
import Container from "semantic-ui-react/dist/commonjs/elements/Container";

export default class PtraUpdate202206 extends Component {
    render() {
        return (
            <Container style={{padding: "0 10px"}}>
                <br style={{clear: "both"}}/>
            </Container>
        );
    }
}