import React, {Component} from "react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FaqAdminBar from "./faq_admin_bar";
import FaqTopic from "./faq_topic";
import FaqQuestion from "./faq_question";
import standardFormData from "../../components/standard_form_data";
import FaqAnswer from "./faq_answer";

const API_UPDATE_FAQ = "https://www.pinetreesra.co.uk/api/faq/updateFaq"


class FaqSegment extends Component {
    constructor(props) {
        super(props);
        this.showHidePreview = this.showHidePreview.bind(this);
    }

    saveField = (e, inputData) => {
        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("field", inputData.name);
        formData.append("value", inputData.value);
        formData.append("faqId", this.props.faqItem.id);
        fetch(API_UPDATE_FAQ, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            this.props.faqItem[inputData.name] = inputData.value;
            this.setState({successMessage: true});
            setTimeout(function () {
                this.setState({successMessage: false});
            }.bind(this), 2000);
        }));
    }

    state = {
        successMessage: false,
        preview: (this.props.faqItem.active_state !== "pending")
    }

    showHidePreview() {
        this.setState({preview: !this.state.preview});
    }

    render() {
        return (
            <>
                <FaqTopic isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} faqItem={this.props.faqItem}
                          previousTopic={this.props.previousTopic}/>
                <Segment style={{marginLeft: "20px"}}>
                    <FaqQuestion isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} faqItem={this.props.faqItem}/>
                    <FaqAnswer faqItem={this.props.faqItem} saveField={this.saveField} admin={this.props.isAdmin} preview={this.state.preview}
                               showHidePreview={this.showHidePreview}/>
                    <FaqAdminBar isAdmin={this.props.isAdmin} preview={this.state.preview} saveField={this.saveField} faqItem={this.props.faqItem}
                                 showHidePreview={this.showHidePreview} successMessage={this.state.successMessage}/>
                </Segment>
            </>
        )
    }
}

export default FaqSegment