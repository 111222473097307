import React, {Component} from "react";

class NewsDate extends Component {
    render() {
        return (
            <div className="small-grey-text" style={{marginTop: "20px", color: "purple"}}>
                {this.props.date}
            </div>
        )
    }
}
export default NewsDate