import React, {Component} from "react";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";

class ToDoFilter extends Component {
    render() {
        return (
            <Segment style={{margin: "20px 10px", background: "#f5f0e1"}} color={"yellow"} className="small-grey-text">
                <Grid columns={this.props.filter.length + 2} stackable>
                    <Grid.Column key={"label"}>
                        Filter:
                    </Grid.Column>
                    <Grid.Column key={"all"}>
                        <Checkbox label="All" value="all" onClick={(e, v) => this.props.toggleAll(e, v)} checked={this.props.filterAll} key="all"/>
                    </Grid.Column>
                    {this.props.filter.map((option, k) =>
                        <Grid.Column key={k}>
                            <Checkbox label={{children: option.text}} key={option.key} checked={this.props.filter[k].filterChecked}
                                      onClick={(e, v) => this.props.filterChange(e, v, k)}/>
                        </Grid.Column>
                    )}
                </Grid>
            </Segment>
        );
    }
}

export default ToDoFilter