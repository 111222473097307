import { Component } from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {NavLink} from "react-router-dom";
import { Segment } from "semantic-ui-react";

export default class Recruiting extends Component {
    render() {
        return (
            <Segment style={{margin: "40px", background:"#f9e2e2"}}>
                <Header as='h2' textAlign="center">
                    <Icon name="pointing right" color="red" size="massive"/>
                    Call to action - We are recruiting
                </Header>
                

                <p>If you would like to get more actively involved in your local community then consider joining the PTRA! 
                    We are currently recruiting for new members to join us! If you have the time and/or a skill or connection that you 
                    think would benefit us then please get in touch via the contact form to find out more.
                    </p> 

                <p>Please note this is a voluntary role but you will need to attend meetings and take on some actions. 
                    Committee meetings are in an evening but there are some day meetings if you are available. Actions could be things like research, 
                    drafting an email, pulling together some points into a spreadsheet, proof reading etc.
                    </p>

                <p style={{textAlign: "center"}}>
                    <Button as={NavLink} to="/contact" color="blue">
                        <Icon name="user" size="large"/>
                        Contact Us
                    </Button>
                </p>
            </Segment>
        )
    }
}