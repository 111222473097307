import standardFormData from "./standard_form_data";

const API_GENERIC_SAVE = "https://www.pinetreesra.co.uk/api/generic/save";

function ajaxSave(loggedInUser, table, key, field, value) {
    return new Promise((passedInSuccess, passedInError) => {
        let formData = standardFormData(loggedInUser.id, loggedInUser.token);
        formData.append("field", field);
        formData.append("value", value);
        formData.append("key", key);
        formData.append("table", table);

        fetch(API_GENERIC_SAVE, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            passedInSuccess(data);
        })).catch(error => {
            passedInError({ok: false, error: [error]});
        });
    })
}

export default ajaxSave