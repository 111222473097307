import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import BookingDetails from "./booking_details";

class BookingRequestReceived extends Component {
    render() {
        return (
            <Modal
                closeIcon
                open={this.props.message.length > 0}
                onClose={this.props.clearMessage}
            >
                <Header>Thanks, booking instruction received</Header>
                <Message color={this.props.errors.length > 0 ? "red" : "green"} style={{margin: "30px"}}>
                    <Icon name={this.props.errors.length > 0 ? "question circle outline" : "checkmark"} size={"large"}/>
                    {this.props.message}
                </Message>

                {this.props.rowAdded !== null &&
                <Segment style={{margin: "30px"}}>
                    <BookingDetails booking={this.props.rowAdded} asDisplay={true} loggedInUser={this.props.loggedInUser}/>
                </Segment>
                }

                {this.props.errors.length > 0 &&
                <Message color="red" style={{margin: "30px"}}>
                    {this.props.errors.map((err, i) =>
                        <div key={i}>{err}</div>
                    )}
                </Message>
                }
            </Modal>
        );
    }
}

export default BookingRequestReceived