import React from "react";
import Roads from "./road";
import utah from "../../assets/img/roads/utah.jpg"

const UtahWay = () => (
    <Roads
        img={utah}
        roadName="Utah Way"
        namedAfter="USS Utah (BB-31)"
        text={"Utah Way was named after a US Navy battleship lost on Dec. 7 1941 during the Japanese attack on Pearl Harbor which effectively brought the US into WWII. " +
        "58 men were lost on the USS Utah. This road name was a road in the former housing areas of the base."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default UtahWay