import React from "react";
import Roads from "./road";
import kelly from "../../assets/img/roads/kelly.png"

const KellyRoad = () => (
    <Roads
        img={kelly}
        roadName="Kelly Road"
        namedAfter="Martha Gail Kelly (teacher)"
        text={"Kelly graduated from the University of California Los Angeles (UCLA), and came to France in 1951 and then a few years later, moved to England. At LCHS she " +
        "taught English and Art from 1955-1986 as well as coached Volleyball and Athletics. She was instrumental in helping the school implement " +
        "Title IX—a US public law enacted in 1972 that offered equal rights to women in accessing education and sports programmes that had US federal funding. " +
        "Kelly, who still resides in London, was married to the late American artist, art restorer and author Francis Robert Kelly. " +
        "Kelly passed away in London on her 92nd Birthday in April 2018."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default KellyRoad