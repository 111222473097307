import React, {Component} from "react";
import Tab from "semantic-ui-react/dist/commonjs/modules/Tab";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import standardFormData from "../components/standard_form_data";
import EventsMonth from "../layouts/events/events_month";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import EventAdvert from "../layouts/events/event_advert";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Link} from "react-router-dom";
import {queryString} from "../components/query_string";
import POC from "../components/poc";

const API_ADD_EVENT = "https://www.pinetreesra.co.uk/api/events/add";
const API_FETCH_EVENTS = "https://www.pinetreesra.co.uk/api/events/fetchEvents";

class Events extends Component {
    constructor(props) {
        super(props);
        this.fetchEvents = this.fetchEvents.bind(this);
        this.convertRender = this.convertRender.bind(this);
        this.navToTab = this.navToTab.bind(this);
        this.fetchEvents();
    }

    state = {
        eventsList: [],
        activeIndex: 0,
        activeEvent: 0
    }

    fetchEvents() {
        fetch(API_FETCH_EVENTS, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({error: data.error, eventsList: data.eventsList});
            this.navToTab();
        }));
    }

    addNew() {
        fetch(API_ADD_EVENT, {
            method: 'POST',
            body: standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token),
        }).then(response => response.json().then(data => {
            this.setState({eventsList: [], activeEvent: data.id});
            this.fetchEvents();
        })).catch(error => console.log(error.message));
    }

    convertRender() {
        return this.state.eventsList.map((months) => {
            return {
                menuItem: months.menuItem,
                render: () =>
                    <Tab.Pane>
                        <EventsMonth eventsInMonth={months.events} loggedIn={this.props.loggedIn} loggedInUser={this.props.loggedInUser} isAdmin={this.props.isAdmin}
                                     activeEvent={this.state.activeEvent}/>
                    </Tab.Pane>
            }
        })
    }

    navToTab() {
        let tab = 0;
        let id = queryString("id");

        if (id !== null && this.state.eventsList.length > 0) {
            tab = this.state.eventsList.findIndex(function (obj) {
                return obj.events.filter((row) => row.id === id).length > 0;
            });
            this.setState({activeIndex: tab, activeEvent: id});
        }
    }

    render() {
        return (
            <Container style={{padding: "0 10px"}}>
                <POC />
                <Header as="h1">Events</Header>

                {this.props.isAdmin &&
                <Segment style={{background: "#e9f5e8"}} color={"green"} className="small-grey-text">
                    <span style={{marginRight: "20px"}}>Admin:</span>
                    <Button primary content={"add new"} icon={{name: "plus"}} onClick={(e) => this.addNew(e)}/>
                </Segment>
                }

                {(this.state.eventsList.length > 1 || (this.state.eventsList.length === 1 && this.state.eventsList[0].events.length > 1)) &&
                <Tab menu={{fluid: true, vertical: true, tabular: true}} panes={this.convertRender()} activeIndex={this.state.activeIndex}
                     onTabChange={(e) => this.setState({activeIndex: e.target.activeIndex})}/>
                }

                {(this.state.eventsList.length === 1 && this.state.eventsList[0].events.length === 1) &&
                <EventAdvert event={this.state.eventsList[0].events[0]} loggedIn={this.props.loggedIn} loggedInUser={this.props.loggedInUser} isAdmin={this.props.isAdmin}/>
                }

                {this.state.eventsList.length === 0 &&
                <Segment>
                    <Header content="No upcoming events"/>
                    <p>The are no upcoming events at the moment. We are always looking for ideas and people to assist in running events. If you have an idea or want to get
                        involved please email the committee using our <Link to="/contact?topic=general" topic="general">contact form</Link></p>
                </Segment>
                }

            </Container>
        );
    }

}

export default Events