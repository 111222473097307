import React from "react";
import Roads from "./road";
import jimmy_doolittle from "../../assets/img/roads/doolittle.jpeg"

const DoolittleAvenue = () => (
    <Roads
        img={jimmy_doolittle}
        roadName="Doolittle Avenue"
        namedAfter="Jimmy Doolittle"
        text={"From January 1944 to September 1945, he held his largest command, the Eighth Air Force (8 AF) in England as a lieutenant general, " +
        "his promotion date being March 13, 1944 and the highest rank ever held by an active reserve officer in modern times. " +
        "Doolittle was well known to American airmen as the famous \"Tokyo Raider\" and former air racer. His directive was simple: 'Win the air war and isolate the battlefield'."}
        refSource="https://en.wikipedia.org/wiki/Jimmy_Doolittle"

    />
)
export default DoolittleAvenue