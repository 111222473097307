import React, {Component} from "react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import standardFormData from "../../../../components/standard_form_data";
import BookingStatus from "./booking_status";

const API_BOOKING_UPDATE = "https://www.pinetreesra.co.uk/api/bookings/bookingUpdate"

class BookingRow extends Component {
    constructor(props) {
        super(props);
        this.update = this.update.bind(this);
    }

    state = {
        canEdit: this.props.loggedInUser.permissions.filter(p => p.permission === "booking_admin").length > 0
    }

    update(e, target) {
        if (!this.state.canEdit) {
            return;
        }

        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("booking_id", this.props.booking.id);
        formData.append("field", target.name);
        formData.append("value", target.value);
        fetch(API_BOOKING_UPDATE, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            if (data.ok) {
                this.props.fetchBookings();
            } else {
                this.props.logErrors(data.errors);
            }
        })).catch(error => {
            this.props.logErrors([error.toString()]);
        });
    }

    render() {
        return (
            <Table.Row key={"booking_" + this.props.booking.id}>
                <Table.Cell>{this.props.booking.id}</Table.Cell>
                <Table.Cell>{this.props.booking.booker_name}</Table.Cell>
                <Table.Cell>{this.props.booking.booker_email}</Table.Cell>
                <Table.Cell>{this.props.booking.start}</Table.Cell>
                <Table.Cell>{this.props.booking.end}</Table.Cell>
                <Table.Cell>{this.props.booking.room.text}<br/>Surcharge: £{this.props.booking.room.surcharge}</Table.Cell>
                <Table.Cell>{this.props.booking.event_type.text}<br/>Hourly: £{this.props.booking.event_type.hourly}</Table.Cell>
                <Table.Cell>{this.props.booking.cost}</Table.Cell>
                <Table.Cell><BookingStatus update={this.update} booking={this.props.booking} logErrors={this.props.logErrors}/></Table.Cell>
                <Table.Cell>{this.props.booking.by}</Table.Cell>
            </Table.Row>
        );
    }
}

export default BookingRow