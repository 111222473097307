import React, {Component} from "react";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

class FaqQuestion extends Component {
    render() {
        return (
            <>
                {this.props.isAdmin && !this.props.preview ?
                    <div className="small-grey-text" style={{marginBottom: "5px"}}>
                        <Label content="Q" color="green" circular/>
                        <Input
                            name="question"
                            defaultValue={this.props.faqItem.question}
                            style={{width: "90%"}}
                            onChange={(e, {name, value}) => this.props.saveField(e, {name, value})}
                            placeholder="question"
                        />
                    </div>
                    :
                    <div>
                        <Label content="Q" color="green" circular style={{marginRight: "10px"}}/>
                        <span style={{fontSize: "130%", fontWeight: "bold"}}>{this.props.faqItem.question}</span>
                    </div>
                }
            </>
        )
    }
}

export default FaqQuestion