import React, {Component} from "react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import standardFormData from "../../../../components/standard_form_data";

const API_BOOKING_EVENT_TYPE_UPDATE = "https://www.pinetreesra.co.uk/api/bookings/eventTypeUpdate"

class EventTypeRow extends Component {
    state = {
        canEdit: this.props.loggedInUser.permissions.filter(p => p.permission === "event_admin").length > 0
    }

    update(e, target) {
        if (!this.state.canEdit) {
            return;
        }

        let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
        formData.append("event_type_id", this.props.eventType.id);
        formData.append("field", target.name);
        formData.append("value", target.value);
        fetch(API_BOOKING_EVENT_TYPE_UPDATE, {
            method: 'POST',
            body: formData,
        }).then(response => response.json().then(data => {
            if (!data.ok) {
                this.props.logErrors(data.errors);
            }
        })).catch(error => {
            this.props.logErrors([error.toString()]);
        });
    }

    render() {
        return (
            <Table.Row key={this.props.eventType.value + "_" + this.props.eventType.id}>
                <Table.Cell>
                    <Input onChange={(e, t) => this.update(e, t)} name="event_text" defaultValue={this.props.eventType.text} disabled={!this.state.canEdit}/>
                </Table.Cell>
                <Table.Cell>
                    <Input onChange={(e, t) => this.update(e, t)} name="event_value" defaultValue={this.props.eventType.value} disabled={!this.state.canEdit}/>
                </Table.Cell>
                <Table.Cell>
                    <Icon name="pound sign"/>
                    <Input onChange={(e, t) => this.update(e, t)} name="event_hourly" defaultValue={parseFloat(this.props.eventType.hourly).toFixed(2)} style={{textAlign: "right"}}
                           disabled={!this.state.canEdit}/>
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default EventTypeRow