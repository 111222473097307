import React, {Component} from 'react'
import {Label, Search} from 'semantic-ui-react'

const resultRenderer = ({title, additional}) => <><i>{additional || ""}</i><Label content={title}/></>

class SAUT extends Component {
    state = {
        isLoading: false,
        results: [],
        source: this.props.results,
        value: ""
    }

    handleResultSelect = (e, {result}) => {
        this.setState({value: result.title})
        this.props.handleUpdate(this.props.field, result.title)
    }

    handleSearchChange = (e, {value}) => {
        this.setState({isLoading: true, value})

        setTimeout(() => {
            const re = new RegExp(this.state.value, 'i')
            let filtered = this.state.source.filter((r) => re.test(r.title));
            if (filtered.length === 0) {
                filtered.push({title: this.state.value, additional: "create new "})
            }

            this.setState({
                isLoading: false,
                results: filtered,
            })
        }, 300)
    }

    render() {
        return (
            <Search
                loading={this.state.isLoading}
                resultRenderer={resultRenderer}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                results={this.state.results}
                value={this.state.value}
            />
        )
    }
}

export default SAUT