import React from "react";
import Roads from "./road";
import chesley_robertson from "../../assets/img/roads/robertson.jpeg"

const RobertsonPlace = () => (
    <Roads
        img={chesley_robertson}
        roadName="Robertson Place"
        namedAfter="Gordon Robertson"
        text={"Iowa-born guidance counsellor, who taught World Regions and Geography from 1962 to 1986 and met his wife, Lois, an elementary school principal, " +
        "in 1964 while working in the school system. Robertson had arrived at High Wycombe from Chico State College in California via Ramstein AFB in Germany, " +
        "a US base in France, and another at Sculthorpe in Norfolk, England. For many years, he sponsored the students creating the school’s annual yearbook " +
        "The Londoner. Robertson died aged 86 in 2012 in Minneapolis, Minnesota."}
        refSource="https://www.facebook.com/316437601778668/posts/it-is-with-much-excitement-that-i-share-with-you-along-with-my-fellow-alums-tama/1221170847972001"

    />
)
export default RobertsonPlace