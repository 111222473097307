import { Component } from "react";
import { Segment } from "semantic-ui-react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import pineTreesGroupSignalQR from "../assets/img/PT_Group_Signal_QA.png";
import pineTreesRASignal from "../assets/img/PTRA_Official_Group_Signal_QA.png";

import pineTreesBSWhatsapp from "../assets/img/PT_BuySell_Whatsapp.jpg";
import Recruiting from "./recruiting";

export default class Welcome extends Component {
    
    render() {
        let isRecruiting = true;
        return (
            <Container className={"full"}>
                <Header as='h1'>Pine Trees Residents Association</Header>
                <Header as='h2' textAlign="center">
                    <Icon name="handshake right" color="green" size="large"/>
                    Welcome to the Pine Trees Community! 
                </Header>
                <p>Whether you have newly moved to the area or have been here a while, it's great to have you as part of our community. We hope you are settling in well and enjoying all that Pine Trees and the surrounding area has to offer. </p>

                <p>We, the Pine Trees Residents Association (PTRA), are a group of residents working together to help us have one voice 
                    to communicate and build a relationship with Broadlands, our local Council, neighbouring areas etc. 
                    We are volunteers doing the best we can for our community in our spare time. 
                    Whilst we don't have all the answers (unfortunately!), we hope to help make Pine Trees the 
                    best place to live it can be and to raise issues where we are able to.
                    {isRecruiting && <Recruiting />}
                    </p>

                <Header content="Useful community forums" />
                <p>To help our community interact, there are the following groups that may be of interest. Please note the PTRA do not oversee all these groups but thought it would be useful to share this information to help residents to connect.</p>

                <Segment color="orange">
                    <a href="/members"><Icon name="users" size="big" color="orange"/> Create a profile</a> via the members tab and this will give you access to estate news updates, useful local contacts and more. You can also use the contact form to connect with the PTRA committee. Register
                    </Segment>
                <Segment color="blue">
                    <Image src={pineTreesGroupSignalQR} floated={"right"} size={"tiny"}/>
                    <b>"The Pine Trees Group"</b> on Signal. Download the <a href="https://signal.org/en/" rel='noreferrer nofollow' target="_blank">Signal app</a> and then you can join the group of residents (currently over 300 members!)
                    <br/>
                    All kinds of questions are asked and information shared regarding our estate and local area.
                    <br/><br/>
                </Segment>
                <Segment color="blue">
                    <Image src={pineTreesRASignal} floated={"left"} size={"tiny"}/>
                    <b>"Pine Trees RA (Official Business)"</b> on Signal. Download the <a href="https://signal.org/en/" rel='noreferrer nofollow' target="_blank">Signal app</a> and then you can join this group of residents. 
                    <br/>
                    Here the Pine Trees Residents Association will share updates, gather feedback and respond to queries raised regarding the developments' link up with Taylor Wimpey, Broadlands, the Council etc.
                </Segment>
                <Segment color="green">
                    <Icon name="whatsapp" color="green" size="huge"/>
                    <Image src={pineTreesBSWhatsapp} floated={"right"} size={"tiny"}/>
                    "Pine Trees Buy and Sell" on <b style={{color:"green"}}>WhatsApp</b>. Here all types of items are sold, given away or requested.
                    <br/>
                    <br/>
                </Segment>
                <Segment color="green">
                <Icon name="whatsapp" color="green" size="huge"/>
                    "Pine Trees Parents" on <b style={{color:"green"}}>WhatsApp</b>. This is a group of parents with children of varying ages on the estate; some of whom are at the estate pre-school and primary school and some are at other local nurseries and schools.
                </Segment>
                <Segment>
                    <Icon name="facebook" color="blue" size="huge"/>
                    <a href="https://www.facebook.com/groups/212909996283551/?ref=share" rel='noreferrer nofollow' target="_blank">"Pine Trees at Daws Hill - residents page"</a> on Facebook.
                    Various questions asked, information shared and items given away/sold
                </Segment>

                <br/>
                <p style={{textAlign:"center"}}><b>We hope you enjoy being part of the Pine Trees community; I'm sure we will see you around!</b></p>
            </Container>
        )
    }
}