import React, {Component} from "react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Item from "semantic-ui-react/dist/commonjs/views/Item";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

class StandardLinks extends Component {
    render() {
        return (
            <Container className={"full"}>
                <Header color={"green"}>What we have so far...</Header>

                <Item.Group link divided>
                    <Item as="a" href="/usefulInfo" style={{textDecoration: "none"}}>
                        <Item.Image style={{width: "80px"}}>
                            <Button icon color={"blue"} aria-label="Useful contacts">
                                <Icon name="phone" size="huge" inverted/>
                            </Button>
                        </Item.Image>
                        <Item.Content>
                            <Item.Header>Useful</Item.Header>
                            <Item.Meta>Contact numbers for trade services or local facilities</Item.Meta>
                            <Item.Description>
                                Here we list the useful contact numbers for things like Schools, Local Attractions, Utility Services, Trade Services etc.
                            </Item.Description>
                            <Item.Extra>This information is provided with the best intent and comes with no specific recommendations intended. Anyone logged in is free
                                to add additional contact numbers and these will be reviewed and approved by the PTRA committee.</Item.Extra>
                        </Item.Content>
                    </Item>

                    <Item as="a" href="/news" style={{textDecoration: "none"}}>
                        <Item.Image style={{width: "80px"}}>
                            <Button icon color={"pink"} aria-label="News articles">
                                <Icon name="newspaper outline" size="huge" inverted/>
                            </Button>
                        </Item.Image>
                        <Item.Content>
                            <Item.Header>News</Item.Header>
                            <Item.Meta>Updates from the PTRA committee</Item.Meta>
                            <Item.Description>
                                This is where we provide the detailed updates of any significant discussions, meetings, etc.
                            </Item.Description>
                        </Item.Content>
                    </Item>

                    <Item as="a" href="/communityCentre" style={{textDecoration: "none"}}>
                        <Item.Image style={{width: "80px"}}>
                            <Button icon color="green" aria-label="Community Centre page">
                                <Icon name="building outline" size="huge" inverted/>
                            </Button>
                        </Item.Image>
                        <Item.Content>
                            <Item.Header>Community Centre</Item.Header>
                            <Item.Meta>Update and photos regarding the Community Centre (and Shop)</Item.Meta>
                            <Item.Description>
                                This is a page dedicated to the Community Centre updates and contains photos and video walk-throughs of the visit the PTRA were invited to
                                attend
                            </Item.Description>
                        </Item.Content>
                    </Item>

                    <Item as="a" href="/faq" style={{textDecoration: "none"}}>
                        <Item.Image style={{width: "80px"}}>
                            <Button icon color="purple" aria-label="Frequently Asked Questions">
                                <Icon name="question circle outline" size="huge" inverted/>
                            </Button>
                        </Item.Image>
                        <Item.Content>
                            <Item.Header>FAQs</Item.Header>
                            <Item.Meta>Frequently Asked Questions</Item.Meta>
                            <Item.Description>
                                As a committee we often see the same questions being asked repeatedly on the chat groups. This page is to try and answer some common questions.
                                It can be updated easily so check back periodically if you don't find what you are looking for.
                            </Item.Description>
                        </Item.Content>
                    </Item>

                    {this.props.showNewFeature &&
                    <Item as="a" href="/committeeVote" style={{textDecoration: "none"}}>
                        <Item.Image style={{width: "80px"}}>
                            <Button icon color="yellow" aria-label="Committee Re-elections">
                                <Icon name="question circle outline" size="huge" inverted/>
                            </Button>
                        </Item.Image>
                        <Item.Content>
                            <Item.Header>Committee Re-elections</Item.Header>
                            <Item.Meta>Residents voting page</Item.Meta>
                            <Item.Description>
                                The committee members need re-electing each year
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    }
                </Item.Group>
            </Container>
        );
    }
}

export default StandardLinks