import React, {Component} from "react";
import {GoogleLogin} from "react-google-login";
import axios from "axios";
import {refreshTokenSetup} from "./googlerefreshtoken";

const clientId = "495831438879-tfsehib8us2u5ji1oqh5umq9nkj7532l.apps.googleusercontent.com";
const API_PATH = 'https://www.pinetreesra.co.uk/api/login';

export default class Login extends Component {
    onSuccess = (res) => {
        refreshTokenSetup(res);

        let url = API_PATH;
        if (this.props.asSignup !== undefined) {
            url += "/signup";
        }

        axios({
            method: 'post',
            url: url,
            headers: {'content-type': 'application/json'},
            data: res.profileObj
        })
            .then(result => {
                this.props.handleLogin(result.data);
            })
            .catch(error => this.setState({error: error.message}));
    }

    onFailure = (res) => {
        console.log("[Login Failed] res: ", res);
    }

    render() {
        return (
            <GoogleLogin
                clientId={clientId}
                render={this.props.render || ""}
                buttonText="Login with google"
                onSuccess={this.onSuccess}
                onFailure={this.onFailure}
                cookiePolicy={"single_host_origin"}
                style={{marginTop: '100px'}}
                // isSignedIn={true}
                className="login_with_google"
            />
        )
    }
}