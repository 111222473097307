import React, {Component} from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import standardFormData from "./standard_form_data";

const API_VOTE = "https://www.pinetreesra.co.uk/api/voting/vote"
const API_VOTE_FETCH = "https://www.pinetreesra.co.uk/api/voting/fetch"

class Voting extends Component {
    constructor(props) {
        super(props);
        this.checkVotingItem = this.checkVotingItem.bind(this);
        this.checkVotingItem();
        this.displayContent = this.displayContent.bind(this);
    }

    state = {
        votingItem: this.props.votingItem !== undefined ? this.props.votingItem : {votes: {votesUp: 0, votesDown: 0, thisUser: 0}, id: -1},
        thanksMessage: "",
        votedValue: 0,
    }

    checkVotingItem() {
        if (this.props.votingItem === undefined) {
            let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
            formData.append("vote_item_id", "-1");
            formData.append("vote_type", this.props.votingType);
            fetch(API_VOTE_FETCH, {
                method: 'POST',
                body: formData,
            }).then(response => response.json().then(data => {
                this.setState({error: data.error, votingItem: data});
            }));
        }
    }

    vote = (event, voteValue) => {
        if (this.props.allowedToVote) {
            let formData = standardFormData(this.props.loggedInUser.id, this.props.loggedInUser.token);
            formData.append("vote_type", this.props.votingType);
            formData.append("vote_value", voteValue);
            formData.append("vote_item_id", this.state.votingItem.id);
            fetch(API_VOTE, {
                method: 'POST',
                body: formData,
            }).then(response => response.json().then(data => {
                if (data.ok) {
                    this.setState({thanksMessage: "Thanks for your vote", votingItem: data, voteValue: voteValue});
                } else {
                    this.setState({thanksMessage: "Unable to cast your vote as there was an issue"});
                }
            }));
        } else {
            this.setState({thanksMessage: "Sorry you are unable to vote. Could be because it is your post?"});
        }

        setTimeout(function () {
            this.setState({thanksMessage: ""});
        }.bind(this), 2000);
    }

    displayContent(type) {
        let ret = "-";
        if (type === "up") {
            if (this.props.showNumbers === undefined || this.props.showNumbers) {
                ret = this.state.votingItem.votes.votesUp;
            } else {
                if (this.state.votingItem.votes.thisUser > 0) {
                    ret = "✓";
                }
            }
        } else {
            if (this.props.showNumbers === undefined || this.props.showNumbers) {
                ret = this.state.votingItem.votes.votesDown;
            } else {
                if (this.state.votingItem.votes.thisUser < 0) {
                    ret = "x";
                }
            }
        }
        return ret;
    }

    render() {
        return (
            <>
                <Button
                    content={this.props.buttons !== undefined ? this.props.buttons.down : "Not important"}
                    icon='thumbs down'
                    label={{as: 'a', content: this.displayContent("down")}}
                    labelPosition='right'
                    color="brown"
                    size="mini"
                    onClick={(e) => this.vote(e, -1)}
                />
                <Button
                    content={this.props.buttons !== undefined ? this.props.buttons.up : "Important"}
                    icon='thumbs up'
                    label={{as: 'a', pointing: 'right', content: this.displayContent("up")}}
                    labelPosition='left'
                    color="green"
                    size="mini"
                    onClick={(e) => this.vote(e, 1)}
                />

                {this.state.thanksMessage.length > 0 &&
                <span className={"small-grey-text"}>&nbsp;&nbsp;{this.state.thanksMessage}</span>
                }
            </>
        )
    }
}

export default Voting