import React, {Component} from "react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form"
import Input from "semantic-ui-react/dist/commonjs/elements/Input"

export default class MyInput extends Component {
    render() {
        return (
            <Form.Field
                control={Input}
                label={this.props.fieldName}
                error={this.props.error !== undefined ? this.props.error : ""}
                content={this.props.fieldValue}
                placeholder={this.props.fieldName}
                name={this.props.fieldName}
                onBlur={this.props.handleUpdate}
            />
        )
    }
}